import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DashboardIcon from '@material-ui/icons/Dashboard';
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import DashBoardDaily from "./DashBoardDaily";
import DashBoardTimeAttend from "./DashBoardTimeAttend";
import useAttendanceAPI from "../../hook/useAttendanceAPI";
import moment from "moment";
import DashBoardWeeklyChart from "./DashBoardWeeklyChart";
import DashBoardLeaveEmployee from "./DashBoardLeaveEmployee";
import { Backdrop, Button, CircularProgress } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginTop: "3%",
      marginLeft: "2%",
      marginBottom: "2%",
    },
    root: {
      padding: theme.spacing(3),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
  })

);


function DashBoardPage() {
  const classes = useStyles();

  const [weeklyEmployees, getWeeklyEmployees, getAttendances, attendances, loading, , , getEmployeesLeave, employeesLeave, , getEmployeeIsActive, employeesIsActive] = useAttendanceAPI();
  const [refresh, setRefresh] = useState<Date>(new Date())

  useEffect(() => {
    getWeeklyEmployees(moment(new Date()).format('YYYY-MM-DD'))
    const timer = setTimeout(() => {
      setRefresh(new Date())
    }, 60000)
    return () => clearTimeout(timer);
  }, [refresh, getWeeklyEmployees])

  useEffect(() => {
    getEmployeeIsActive()
  }, [refresh, getEmployeeIsActive])

  useEffect(() => {
    getEmployeesLeave(moment(new Date()).format('YYYY-MM-DD'))
  }, [refresh, getEmployeesLeave])


  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.title}>
          <Grid container spacing={1}>
            <Grid item>
              <DashboardIcon fontSize="large" />
            </Grid>
            <Grid item xs zeroMinWidth style={{ margin: "auto" }}>
              <Typography variant="h4" noWrap>
                DASHBOARD
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Typography>{moment(new Date()).format('DD MMMM yyyy')}</Typography>
          </Grid>
          <Grid item>
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Typography>last update: {moment(refresh).calendar()}</Typography>
              </Grid>
              <Grid item>
                {loading ? <>
                  <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </> : <>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => { setRefresh(new Date()); }}
                  >
                    <RefreshIcon />
                    Refresh
                  </Button>
                </>}
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <DashBoardDaily weeklyEmployees={weeklyEmployees} employees={employeesIsActive} attendances={attendances} leaves={employeesLeave} />
        </Grid>

        <Grid container>
          <DashBoardWeeklyChart weeklyEmployees={weeklyEmployees} loading={loading} />
        </Grid>

        <Grid container item xs zeroMinWidth style={{ margin: "auto" }}>
          <DashBoardTimeAttend attendances={attendances} loading={loading} getAttendances={getAttendances} refresh={refresh} />
        </Grid>

        <Grid container item xs zeroMinWidth style={{ margin: "auto", marginTop: "40px" }}>
          <DashBoardLeaveEmployee employeesLeave={employeesLeave} loading={loading} getEmployeesLeave={getEmployeesLeave} refresh={refresh} />
        </Grid>


      </div>
    </>
  );
}

export default DashBoardPage;
