import HttpRequest from "../helper/httpRequest";
import ExportWorkHours from "../models/ExportWorkHours";
import LeaveReport from "../models/LeaveReport";
import StatisticReport from "../models/StatisticReport";
import TeamInfo from "../models/TeamInfo";
import TimeAttendanceReport from "../models/TimeAttendanceReport";

const API_URL = process.env.REACT_APP_API_URL;

const httpRequest = new HttpRequest(`${API_URL}`);


const reportApi = {

  //Team
  getTeams: () => {
    return httpRequest.get<TeamInfo[]>(API_URL + "api/Team")
  },

  //Statistic
  getStatistics: (_startDate: any, _endDate: any) => {
    return httpRequest.get<StatisticReport>(`${API_URL}api/Report/StatisticReport`, {
      params: {
        startDate: _startDate,
        endDate: _endDate
      }
    })
  },

  //Work Hours
  getWorkHoursDetail: (startdate: any, endDate: any, empId: number, projId: number) => {
    return httpRequest.get<ExportWorkHours[]>(`${API_URL}api/Report/WorkHourExport`, {
      params: {
        startDate: startdate,
        endDate: endDate,
        empId: empId,
        projectId: projId
      }
    })
  },

  //Leave
  getLeaveEmployeesReport: (date1: any, date2: any, team: any, emp: any) => {
    return httpRequest.get<LeaveReport[]>(`${API_URL}api/Report/LeaveReport`, {
      params: {
        startDate: date1,
        endDate: date2,
        empId: team,
        teamId: emp
      }
    })
  },

  //Time Attendance
  getTimeAttendancesReportInfo: (startdate: any, endDate: any, empId: number, projId: number) => {
    return httpRequest.get<TimeAttendanceReport[]>(`${API_URL}api/Report/TimeAttReport`, {
      params: {
        startDate: startdate,
        endDate: endDate,
        empId: empId,
        teamId: projId
      }
    })
  },



};

export default reportApi;
