import { Box, Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import TeamInfo from "../../../models/TeamInfo";
import FilterBar from "../FilterBar";
import WorkHoursTable from "./WorkHoursTable";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import ExportExcel from "./ExportExcel";
import React from "react";
import ExportWorkHours from "../../../models/ExportWorkHours";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";

interface IWorkHours {
  loading: boolean;
  getTeams: any;
  teams: TeamInfo[];
  getWorkHoursDetail: any;
  workHoursDetail: ExportWorkHours[];
  icon: any;
  loadingWorkHoursDetail: any;
}

function WorkHours({
  loading,
  getTeams,
  teams,
  getWorkHoursDetail,
  workHoursDetail,
  icon,
  loadingWorkHoursDetail,
}: IWorkHours) {
  const DEFAULT_STARTDATE_WORKHOURS = new Date();
  const DEFAULT_ENDDATE_WORKHOURS = new Date();
  const [startDateWH, setStartDateWH] = useState<Date>(
    DEFAULT_STARTDATE_WORKHOURS
  );
  const [endDateWH, setEndDateWH] = useState<Date>(DEFAULT_ENDDATE_WORKHOURS);
  const [teamWH, setTeamWH] = useState<number>(0);
  const [empWH, setEmpWH] = useState<number>(0);

  useEffect(() => {
    getWorkHoursDetail(
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DDT23:59:59"),
      0,
      0
    );
  }, [getWorkHoursDetail]);

  const [pageW, setPageW] = React.useState(0);
  // const [pageWH, setPageWH] = React.useState(0);

  const exportAll = () => {
    getWorkHoursDetail(
      moment(startDateWH).format("YYYY-MM-DD"),
      moment(endDateWH).format("YYYY-MM-DDT23:59:59"),
      empWH,
      teamWH
    );
  };

  const calHours = (timeInput: number) => {
    let time = timeInput.toFixed(2)
    let h = Number(String(time).split(".")[0])
    let m = (String(time).split(".")[1])

    if (timeInput > 0) {
      let num = Number(m) > 60 ? (h + 1) + Number(m) % 60 / 100 : (h) + Number(m) % 60 / 100;
      return num.toFixed(2)
    }
    else {
      let num = Number(m) > 60 ? (h - 1) * -1 + Number(m) % 60 / 100 : (h) * -1 + Number(m) % 60 / 100;
      return num.toFixed(2);
    }
  };

  const calOverTime = (sd: number, wh: number) => {
    let sdH = sd
    let sdM = 60
    let whH = Number(String(wh).split(".")[0])
    let whM = String(wh).split(".")[1] !== undefined ? Number(String(wh).split(".")[1].length === 1 ? String(wh).split(".")[1] + 0 : String(wh).split(".")[1]) : 0

    let h = 0;
    let m = 0
    let res = 0

    // overtime
    if (whH >= sdH) {
      h = (whH - sdH) >= 0 ? (whH - sdH) : (whH - sdH) - 1
      m = whM - 0
      res = h + (m % 60) / 100
    }
    // undertime
    else {
      // h =  whM >= 30 ? (whH - sdH) + 1: (whH - sdH);
      h = wh > 0 && whM > 0 ? (whH - sdH) + 1 : (whH - sdH);
      m = whM - sdM
      res = whH < sdH || h < 0 ? ((h * (-1)) + (((m * -1 % 60) / 100))) * -1 : (h) + (((m * -1 % 60) / 100))
    }
    return res.toFixed(2)
  }


  return (
    <>
      <FilterBar
        getTeams={getTeams}
        teams={teams}
        setStartDate={setStartDateWH}
        startDate={startDateWH}
        setEndDate={setEndDateWH}
        endDate={endDateWH}
        setTeam={setTeamWH}
        team={teamWH}
        setEmp={setEmpWH}
        emp={empWH}
        action={exportAll}
        title="WORK HOURS"
        show={true}
        setPage={setPageW}
        icon={icon}
        searchIcon={<SearchIcon />}
        checkDate={true}
      />

      <Box display="flex" p={1}>
        <Grid container alignItems="center" spacing={3}>
          <Box p={1} flexGrow={1}>
            {!loadingWorkHoursDetail && (
              <ExportExcel
                workHoursDetails={workHoursDetail}
                startDate={startDateWH}
                endDate={endDateWH}
                calHours={calHours}
                calOverTime={calOverTime}
              />
            )}
          </Box>
          <Box p={1}>
            <Grid container direction="row">
              <Grid item>
                <Typography>
                  <FiberManualRecordIcon
                    fontSize="small"
                    style={{ color: "#6EC1B2", marginRight: "5px" }}
                  />
                  WORK TIME
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  <FiberManualRecordIcon
                    fontSize="small"
                    style={{ color: "#FFAB00", marginRight: "5px" }}
                  />
                  LEAVE
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Box>
      {!loadingWorkHoursDetail ? (
        <WorkHoursTable
          setPage={setPageW}
          page={pageW}
          workHoursDetail={workHoursDetail}
          calHours={calHours}
          calOverTime={calOverTime}
        />
      ) : (
        <Grid container justify="center" alignItems="center">
          <CircularProgress></CircularProgress>
        </Grid>
      )}
    </>
  );
}

export default WorkHours;
