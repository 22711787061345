import { useCallback, useState } from "react";
import announceApi from "../api/AnnounceAPI";
import Announce from "../models/Announce";

const useAnnounceApi = () => {
  const [announce, setAnnounce] = useState<Announce[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const post_announce = useCallback(
    async (newAnn: Announce) => {
      setLoading(true);
      const formData = new FormData();

      formData.append("fileImg", newAnn.fileImg);
      formData.append("announceId", "0");
      formData.append("img", newAnn.img);
      formData.append("msg", newAnn.msg);
      formData.append("isTextmarquee", JSON.stringify(newAnn.isTextmarquee));

      try {
        const res = await announceApi.postAnnounces(formData);
        setAnnounce((announce) => [...announce, res.data]);
        setSuccess(true);
      } catch (err) {}
      setLoading(false);
    },
    [setAnnounce, setLoading, setSuccess]
  );

  const get_announce = useCallback(async () => {
    setLoading(true);
    try {
      const result = await announceApi.getAnnounce();
      setAnnounce(result.data);
    } catch (err) {
      window.alert(err + "\nSomething with wrong.");
    }
    setLoading(false);
  }, [setAnnounce, setLoading]);

  const put_announce = useCallback(
    async (editAnn: Announce) => {
      setLoading(true);
      try {
        const formData = new FormData();

        formData.append("fileImg", editAnn.fileImg);
        formData.append("announceId", JSON.stringify(editAnn.announceId));
        formData.append("img", editAnn.img);
        formData.append("msg", editAnn.msg);
        formData.append("isTextmarquee", JSON.stringify(editAnn.isTextmarquee));

        const result = await announceApi.putAnnounce(formData);
        let newResult: Announce[] = announce.map((e) => {
          if (e.announceId === result.data.announceId) {
            let obj = e;
            obj.img = result.data.img;
            obj.isTextmarquee = result.data.isTextmarquee;
            obj.msg = result.data.msg;
            return obj;
          } else {
            return e;
          }
        });
        setAnnounce(newResult);
        setSuccess(true);
      } catch (err) {
        window.alert(err + "\nSomething with wrong.");
      }
      setLoading(false);
    },
    [setAnnounce, setLoading, setSuccess, announce]
  );

  const delete_announce = useCallback(
    async (annId: Number) => {
      setLoading(true);
      try {
        const result = await announceApi.deleteAnnounce(annId);
        //setState เพื่อ refresh
        setAnnounce((ann) =>
          ann.filter((a) => a.announceId !== result.data.announceId)
        );
        setSuccess(true);
      } catch (err) {
        window.alert(err + "\nSomething with wrong.");
      }
      setLoading(false);
    },
    [setAnnounce, setLoading, setSuccess]
  );

  return [
    announce,
    post_announce,
    get_announce,
    put_announce,
    delete_announce,
    loading,
    success,
  ] as const;
};
export default useAnnounceApi;
