import {
    TableRow,
    TableCell,
    Button,
    IconButton,
    Collapse,
    Box,
    Typography,
    Table,
    TableHead,
    TableBody,
    Snackbar,

} from "@material-ui/core";
import EmailNotification from "../../models/EmailNotification";
import NotificationsIcon from '@material-ui/icons/Notifications';
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { green } from '@material-ui/core/colors';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import Alert from "@material-ui/lab/Alert";
interface INotificationRows {
    index: number
    notify: EmailNotification
    insertEmailLog: any
    successAll: boolean
    sendEmailNotify: any
    checkFails: EmailNotification[]
    changePage: boolean;
    setChangePage: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
        },
        wrapper: {
            margin: theme.spacing(1),
            position: 'relative',
        },
        buttonSuccess: {
            backgroundColor: green[500],
            '&:hover': {
                backgroundColor: green[700],
            },
        },
        fabProgress: {
            color: green[500],
            position: 'absolute',
            top: -6,
            left: -6,
            zIndex: 1,
        },
        buttonProgress: {
            color: green[500],
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -12,
            marginLeft: -12,
        },
        table: {
            width: "100%",
        },
    }),
);

function NotificationRows({
    index,
    notify,
    successAll,
    sendEmailNotify,
    checkFails,
    changePage,
    setChangePage }: INotificationRows) { //selectemailNotifyLog

    const classes = useStyles();

    const [warning, setWarning] = useState<boolean>(false);

    const warningClose = () => {
        setWarning(false);
        setCheckEmp(false)
    };

    const [openEmp, setOpenEmp] = useState(false);

    const [loadingEmp, setLoadingEmp] = useState(false);

    const [successEmp, setSuccessEmp] = useState(false);
    const timer = useRef<number>();

    const buttonClassname = clsx({
        [classes.buttonSuccess]: successEmp === false ? notify.notifyLog.length >= 1 ? true : false : successEmp,
    });

    useEffect(() => {
        if (successAll === true) {
            setLoadingEmp(true)
        } else {
            setLoadingEmp(false)
        }
        return () => {
            clearTimeout(timer.current);
        }
    }, [successAll, checkFails]);

    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);


    const displayConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const [checkEmp, setCheckEmp] = useState(false)


    const keepLogEmp = () => {
        if (!loadingEmp) {
            setSuccessEmp(false);
            setLoadingEmp(true);
            timer.current = window.setTimeout(async () => {
                const data_send: any = [];
                let EmailLog = {
                    employeeId: notify.employeeId,
                    sendDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                    absenceDate: moment(notify.date).format("YYYY-MM-DDTHH:mm"),
                    firstName: notify.firstName,
                    lastName: notify.lastName,
                    senedStatus: null,
                    email: notify.email,
                    date: notify.date
                };
                data_send.push(EmailLog)
                await sendEmailNotify(data_send)
                setSuccessEmp(true);
                setLoadingEmp(false);
                await setCheckEmp(true)
            });
        }

    };

    useEffect(() => {
        if (checkFails.filter(e => e.employeeId === notify.employeeId && e.date === notify.date).length >= 1 && checkEmp) {
            setWarning(true);
        }
    }, [checkFails, setWarning, checkEmp, notify.employeeId, notify.date])

    useEffect(() => {
        setChangePage(false)
        setOpenEmp(false)
    }, [setChangePage, setOpenEmp, changePage])


    return (
        <>
            <TableRow>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpenEmp(!openEmp)}>
                        {openEmp ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>

                <TableCell component="th" scope="row" align="center">{index}</TableCell>

                <TableCell component="th" scope="row" align="center">
                    {notify.projectList.map((pro, key) => {
                        if (notify.projectList.length !== key + 1) {
                            return pro.projectName + " , ";
                        }
                        return pro.projectName;
                    })}
                </TableCell>

                <TableCell component="th" scope="row" align="center">{notify.nickName}</TableCell>
                <TableCell component="th" scope="row" align="center">{notify.firstName} {notify.lastName}</TableCell>
                <TableCell component="th" scope="row" align="center">{moment(notify.date).format("DD/MM/YYYY")}</TableCell>

                <TableCell align="center">

                    <div className={classes.wrapper}>
                        <Button
                            variant="contained"
                            color="secondary"
                            className={buttonClassname}
                            disabled={loadingEmp}
                            onClick={displayConfirmDialog}
                            startIcon={<NotificationsIcon />}
                        >
                            SEND
                        </Button>
                        {loadingEmp && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </TableCell>

            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={openEmp} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <Typography variant="h6" gutterBottom component="div">
                                History
                            </Typography>
                            {notify.notifyLog.length !== 0 ? (
                                <Table size="small" aria-label="purchases">
                                    <colgroup>
                                        <col style={{ width: '10%' }} />
                                        <col style={{ width: '15%' }} />
                                        <col style={{ width: '25%' }} />
                                        <col style={{ width: '20%' }} />
                                        <col style={{ width: '20%' }} />
                                        <col style={{ width: '10%' }} />
                                    </colgroup>
                                    <TableHead style={{ backgroundColor: "#E3E3E3" }}>
                                        <TableRow>
                                            <TableCell style={{ textAlign: "center" }}>#</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>NickName</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>Date</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>Absence Date</TableCell>
                                            <TableCell style={{ textAlign: "center" }}>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{ backgroundColor: "#F5F5F5" }}>
                                        {notify.notifyLog.map((m, key) => {
                                            return (
                                                <>
                                                    <TableRow >
                                                        <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                            {key + 1}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                            {notify.nickName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                            {notify.firstName} {notify.lastName}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                            {moment(m.sendDate).format("DD/MM/YYYY HH:mm ")}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: "center" }}>
                                                            {moment(m.absenceDate).format("DD/MM/YYYY")}
                                                        </TableCell>
                                                        <TableCell component="th" scope="row" style={{ textAlign: "center", color: m.senedStatus === true ? "green" : "red" }}>
                                                            {m.senedStatus === true ? "PASS" : "FAILED"}
                                                        </TableCell>
                                                    </TableRow>
                                                </>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Typography style={{ backgroundColor: "#F5F5F5" }}>
                                    NO DATA
                                </Typography>
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>

            <Snackbar
                open={warning}
                autoHideDuration={6000}
                onClose={warningClose}
            >
                <Alert onClose={warningClose} severity="warning">
                    Something with wrong
                </Alert>
            </Snackbar>

            <ConFirmDialog
                title="Send Email"
                description="Do you want to send email ?"
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                actionDialog={keepLogEmp} />
        </>
    )
}

export default NotificationRows


