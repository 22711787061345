import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogContentText,
} from "@material-ui/core";

interface IDialog {
  title: string,
  description: string,
  open: boolean,
  setOpen: any,
  actionDialog: any
}

function ConFirmDialog({ title, description, open, setOpen, actionDialog }: IDialog) {

  const ConfirmDialog = () => {
    actionDialog()
    setOpen(false)
  }

  const CloseDialog = () => {
    setOpen(false)
  }

  return (
    <>

      <Dialog
        open={open}
        onClose={CloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={CloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={ConfirmDialog} variant="contained" color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

    </>
  )
}


export default ConFirmDialog;