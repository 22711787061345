import React, { useState } from "react";
import "./App.css";
import NavBar from "./component/NavBar/NavBar";
import SideBar from "./component/SideBar/SideBar";
import DashBoardPage from "./component/DashBoardPage/DashBoardPage";
import Notification from "./component/Notification/Notification";
import Report from "./component/Report/Report";
import { Switch } from "react-router";
import Announce from "./component/Announce/Announce";
import clsx from "clsx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import LoginPage from "./component/LoginPage/LoginPage";
import ManageTimeAttendance from "./component/ManageTimeAttendance/ManageTimeAttendance";
import ManageTimeAttendanceWFH from "./component/ManageTimeAttendanceWFH/ManageTimeAttendanceWFH";
import ManageEmployee from "./component/ManageEmployee/ManageEmployee";
import { Route } from "react-router-dom";
import Page404 from "./component/PageRespon/Page404";
import Page500 from "./component/PageRespon/Page500";
import EmailTemplate from "./component/EditFormEmail/EmailTemplate";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexGrow: 1
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: "none",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(1, 1, 1, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(3),
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    large: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      margin: "15px 5px 15px 5px",
    },
    big: {
      width: theme.spacing(14),
      height: theme.spacing(14),
      marginLeft: "auto;",
      marginRight: "auto;",
    },
    colorAppBar: {
      // change ColorAppBar
      background: "#2D2E33",
    },
    bg: {
      backgroundColor: "#F6F6F4",
      minHeight: "100vh",
    },
  })
);

function App() {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);

  const handleDrawerOpen = () => {
    const token = localStorage.getItem("token");
    if (token !== null) {
      setOpen(true);
    } else if (token === null) {
      setOpen(false);
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };


  return (
    <>

      <div className={classes.root}>
        <NavBar
          open={open}
          setOpen={setOpen}
          classes={classes}
          handleDrawerOpen={handleDrawerOpen}
        />
        <SideBar
          open={open}
          setOpen={setOpen}
          classes={classes}
          handleDrawerClose={handleDrawerClose}
        />

        <main
          className={clsx(
            classes.content,
            {
              [classes.contentShift]: open,
            },
            classes.bg
          )}
        >
          <div className={classes.drawerHeader} />


          <Switch>
            <Route path="/login" component={LoginPage} />

            <Route
              path="/"
              exact
              component={DashBoardPage}

            />
            <Route
              path="/announce"
              component={Announce}

            />
            <Route
              path="/report"
              component={Report}

            />
            <Route
              path="/manage"
              component={ManageTimeAttendance}

            />
            <Route
              path="/manageworkfromhome"
              component={ManageTimeAttendanceWFH}

            />

            <Route
              path="/notification"
              component={Notification}

            />

            <Route
              path="/formemail"
              component={EmailTemplate}

            />

            <Route
              path="/manageEmp"
              component={ManageEmployee}

            />
            <Route
              path="/error404"
              component={() => <Page404 setOpen={setOpen} />}
            // meta={{ auth: false }}
            />
            <Route
              path="/error500"
              component={() => <Page500 setOpen={setOpen} />}
            // meta={{ auth: false }}
            />
          </Switch>

        </main>
      </div>
    </>
  );
}

export default App;
