import HttpRequest from "../helper/httpRequest";

const API_URL = process.env.REACT_APP_API_URL;

const httpRequest = new HttpRequest(`${API_URL}`);

export interface Auth {
  email: string;
  password: string;
}

const authApi = {
  getToken: (auth: Auth) => {
    return httpRequest.post<string>(API_URL + "api/Users/Login", auth);
  },
};

export default authApi;
