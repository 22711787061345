import { useCallback, useState } from "react"
import attendApi from "../api/AttendanceAPI"
import Attendance from '../models/Attendance'
import AttendanceForm from "../models/AttendanceForm"
import WeeklyEmployee from "../models/WeeklyEmployee"
import EmployeesLeave from "../models/EmployeeLeave"
import EmployeeIsActive from "../models/EmployeeIsActive"
import EmployeeAbsences from "../models/EmployeeAbsences"
import EmployeeIsActiveTeam from "../models/EmployeeIsActiveTeam"

const useAttendanceAPI = () => {
    const [weeklyEmployees, setWeeklyEmployees] = useState<WeeklyEmployee[]>([])
    const [attendances, setAttendances] = useState<Attendance[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [attendance, setAttendance] = useState<Attendance>()
    const [employeesLeave, setEmployeesLeave] = useState<EmployeesLeave[]>()
    const [employeesIsActive, setemployeesIsActive] = useState<EmployeeIsActive[]>([])
    const [employeeIsActive, setemployeeIsActive] = useState<EmployeeIsActive>()
    const [successed, setSuccessed] = useState<boolean>(false)
    const [employeeAbsences, setEmployeeAbsences] = useState<EmployeeAbsences[]>([])
    const [employeesIsActiveTeam, setemployeesIsActiveTeam] = useState<EmployeeIsActiveTeam[]>([])
    const [employeesIsActiveAdmin,setemployeesIsActiveAdmin] = useState<EmployeeIsActive[]>([])

    const getAttendances = useCallback(
        async (date1: any, date2: any) => {
            setLoading(true)
            const attends = await attendApi.getAttend(date1, date2);
            setAttendances(attends.data)
            setLoading(false)
        }, [setAttendances, setLoading])

    const getWeeklyEmployees = useCallback(
        async (date: any) => {
            const statistic = await attendApi.getWeeklyEmployee(date)
            setWeeklyEmployees(statistic.data)
        }, [setWeeklyEmployees]
    )

    const addAttendance = useCallback(
        async (att: AttendanceForm) => {
            try {
                setLoading(true)
                const newAtt = await attendApi.checkinAttendances(att)
                if (newAtt) {
                    //Need to refresh data after adding
                    return newAtt;
                }
    
                setLoading(false);
            }
            catch (err: any) {
                if (err && err.response && err.response.status === 409) {
                    return err.response;
                }
                else {
                    console.log(`Something went wrong. ${err}`);
                    return false;
                }
            }

        }, [setLoading]
    )

    const getEmployeesLeave = useCallback(
        async (date: any) => {
            setLoading(true)
            const leave = await attendApi.getEmployeeLeave(date)
            setEmployeesLeave(leave.data)
            setLoading(false)
        }, [setEmployeesLeave, setLoading]
    )

    const editAttendanceAdmin = useCallback(async (count: number, checkin: any, checkout: any, note: string, isIncludeBreak: boolean, worklog: string) => {
        try {
            setLoading(true)
            const editAtt = await attendApi.editAttendanceAdmin(count, checkin, checkout, note, isIncludeBreak, worklog)
            setAttendance(editAtt.data)
            let newAttends: Attendance[] = attendances.map((e) => {
                if (e.count === editAtt.data.count) {
                    let obj = e
                    obj.checkIn = editAtt.data.checkIn
                    obj.checkOut = editAtt.data.checkOut
                    obj.note = editAtt.data.note
                    obj.hours = editAtt.data.hours
                    return obj
                }
                else {
                    return e
                }
            })
            setAttendances(newAttends)
            setLoading(false)
        } catch (err) {
            window.alert('Something with wrong')
        }
    }, [setAttendance, setLoading, setAttendances, attendances])

    const getEmployeeIsActive = useCallback(
        async () => {
            setLoading(true)
            const empActive = await attendApi.getEmployeeIsActive()
            setemployeesIsActive(empActive.data)
            setLoading(false)
        }, [setemployeesIsActive, setLoading]
    )

    const getEmployeeIsActiveAdmin = useCallback(
        async () => {
            setLoading(true)
            const empActiveAdmin = await attendApi.getEmployeeIsActiveAdmin()
            setemployeesIsActiveAdmin(empActiveAdmin.data)
            setLoading(false)
        }, [setemployeesIsActiveAdmin, setLoading]
    )

    const getEmployeeIsActiveTeam = useCallback(
        async () => {
            setLoading(true)
            const empActiveTeam = await attendApi.getEmployeeIsActiveTeam()
            setemployeesIsActiveTeam(empActiveTeam.data)
            setLoading(false)
        }, [setemployeesIsActiveTeam, setLoading]
    )

    const editEmployeeAttendID = useCallback(
        async (empId: number, newAttID: string) => {
            try {
                const emp = await attendApi.editEmployeeAttendID(empId, newAttID)
                setemployeeIsActive(emp.data)
                if (emp.data !== undefined) {
                    setSuccessed(true)
                }
            }
            catch (err) {
                window.alert('Something with wrong')
            }


        }, [setemployeeIsActive, setSuccessed]
    )

    const getEmployeeAbsences = useCallback(
        async (date1: any, date2: any) => {
            setLoading(true)
            const empAbs = await attendApi.getEmployeeAbsences(date1, date2)

            setEmployeeAbsences(empAbs.data)
            setLoading(false)
        }, [setEmployeeAbsences, setLoading]
    )

    return [
        weeklyEmployees,
        getWeeklyEmployees,
        getAttendances,
        attendances,
        loading,
        attendance,
        addAttendance,
        getEmployeesLeave,
        employeesLeave,
        editAttendanceAdmin,
        getEmployeeIsActive,
        employeesIsActive,
        editEmployeeAttendID,
        employeeIsActive,
        successed,
        setSuccessed,
        getEmployeeAbsences,
        employeeAbsences,
        setAttendances,
        getEmployeeIsActiveTeam,
        employeesIsActiveTeam,
        getEmployeeIsActiveAdmin,
        employeesIsActiveAdmin
    ] as const

}

export default useAttendanceAPI
