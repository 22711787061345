import HttpRequest from "../helper/httpRequest";
import Announce from "../models/Announce";

const API_URL = process.env.REACT_APP_API_URL;

const httpRequest = new HttpRequest(`${API_URL}`);

const announceApi = {
  postAnnounces: (newAnn: FormData) => {
    //return axiosInstance.post<Announce>(`${API_URL}Announce`, { ...newAnn })
    httpRequest.setHeader({
      key: "Content-Type",
      value: "multipart/form-data",
    });
    return httpRequest.post<Announce>("api/Announce", newAnn);
  },

  getAnnounce: () => {
    return httpRequest.get<Announce[]>(`${API_URL}api/Announce`);
  },

  putAnnounce: (editAnn: FormData) => {
    httpRequest.setHeader({
      key: "Content-Type",
      value: "multipart/form-data",
    });
    //return axiosInstance.put<Announce>(`${API_URL}api/Announce`, { ...editAnn });
    return httpRequest.put<Announce>("api/Announce", editAnn);

  },

  deleteAnnounce: (annId: Number) => {
    return httpRequest.delete<Announce>(`${API_URL}api/Announce`, {
      params: {
        AnnounceId: annId,
      },
    });
  },
};
export default announceApi;
