import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import moment from "moment";
import { useState } from "react";
import XLSX from "xlsx";
import LinearProgress from "@material-ui/core/LinearProgress";
import InvalidModal from "./InvalidModal";
import React from "react";
import AlertSuccessWFH from "./AlertSuccessWFH";
import ImportButton from "./ImportButton";
import ChooseButton from "./ChooseButton";

interface IExcelImport {
  insertExcel: any;
  loadingImportWFH: boolean;
  wfhSuccess: boolean;
  setWfhSuccess: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    btnSelectFile: {
      display: "none",
    },
  })
);

function ExcelImport({
  insertExcel,
  loadingImportWFH,
  wfhSuccess,
  setWfhSuccess,
}: IExcelImport) {
  const classes = useStyles();
  const DEFAULT_FILENAME = "SELECT .CSV FILE";
  const [fileName, setFileName] = useState<string>(DEFAULT_FILENAME);

  const [data, setData] = useState<any>();
  const [invalid_data, setInvalid] = useState<any>([]);

  const insertData = () => {
    insertExcel(data);
    data === undefined && window.alert("Please choose .CSV file before.");
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const importExcel = (e: any) => {
    interface ITable {
      id: number;
      startTime: Date;
      completiorTime: Date;
      email: string;
      name: string;
      date: string;
      hoursText: string;
      project: string;
      inOutDetail: string;
      hours: number;
      checkIn: string;
      checkOut: string;
      projectName: string;
    }

    const file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = (event) => {
      // Parse data
      const bstr = event.target?.result;
      const workBook = XLSX.read(bstr, { type: "binary", cellDates: true });

      // Get first sheet
      const workSheetName = workBook.SheetNames[0];
      const workSheet = workBook.Sheets[workSheetName]; //address

      //function format checkIn & check out
      const formatTime = (time: any) => {
        time = time.replace(/\s/g, "");
        time = time.replace(":", ".");
        time = time.replace(",", ".");
        time = time.toLowerCase();

        if (time.includes("am")) {
          time = time.split("am");
          time = Number(time[0]);
        } else if (time.includes("pm")) {
          time = time.split("pm");
          time = Number(time[0]);
          time = time + 12;
        }
        return Number(time);
      };

      //function add days
      const addDays = (date: Date, days: number): Date => {
        date.setDate(date.getDate() + days);
        return date;
      };

      // Convert to array
      const header = [
        "id",
        "startTime",
        "completiorTime",
        "email",
        "name",
        "date",
        "hoursText",
        "project",
        "inOutDetail",
        "hours",
        "checkIn",
        "checkOut",
        "projectName",
      ];

      const fileData = XLSX.utils.sheet_to_json<ITable>(workSheet, {
        raw: false,
        // dateNF: "dd/mm/yyyy",
        header: header,
        // defval: "",
        //blankrows: true
      });

      // Remove header
      fileData.splice(0, 1);

      //invalid array
      let data_invalid: any = [];

      // Data
      fileData.forEach((d: any) => {
        //delete column not use
        delete d.startTime;
        delete d.completiorTime;
        delete d.name;
        delete d.project;

        // format date
        if (d && d.date) {
          if (d.date.includes("/")) {
            d.date = moment(d.date, "DD/MM/YYYY").format("YYYY-MM-DD");
          } else if (d.date.includes("-")) {
            d.date = moment(d.date, "DD-MM-YYYY").format("YYYY-MM-DD");
          }
        } else {
          d.date = null;
        }

        // checkIn checkOut (HH.mm)
        if (d.checkIn !== undefined) {
          d.checkIn = formatTime(d.checkIn);
        } else {
          d.checkIn = null;
        }

        if (d.checkOut !== undefined) {
          d.checkOut = formatTime(d.checkOut);
        } else {
          d.checkOut = null;
        }

        // checkIn checkOut (datetime)
        if ((d.checkIn && d.checkOut) !== null) {
          let date = d.date.split("-");
          if (d.checkIn < d.checkOut) {
            d.checkIn = d.checkIn.toFixed(2);
            d.checkOut = d.checkOut.toFixed(2);

            let checkIn = d.checkIn.split(".");
            let checkOut = d.checkOut.split(".");

            //format month-1
            d.checkIn = moment(
              new Date(
                date[0],
                Number(date[1]) - 1,
                date[2],
                checkIn[0],
                checkIn[1]
              )
            ).format("YYYY-MM-DDTHH:mm");
            d.checkOut = moment(
              new Date(
                date[0],
                Number(date[1]) - 1,
                date[2],
                checkOut[0],
                checkOut[1]
              )
            ).format("YYYY-MM-DDTHH:mm");
          } else {
            //work across the day
            d.checkIn = d.checkIn.toFixed(2);
            d.checkOut = d.checkOut.toFixed(2);

            let checkIn = d.checkIn.split(".");
            let checkOut = d.checkOut.split(".");

            let date_add = new Date(date[0], Number(date[1]) - 1, date[2]);
            date_add = addDays(date_add, 1);
            let date_add_str: any = moment(new Date(date_add))
              .format("YYYY-MM-DD")
              .split("-");

            //format month-1
            d.checkIn = moment(
              new Date(
                date[0],
                Number(date[1]) - 1,
                date[2],
                checkIn[0],
                checkIn[1]
              )
            ).format("YYYY-MM-DDTHH:mm");
            d.checkOut = moment(
              new Date(
                date_add_str[0],
                Number(date_add_str[1]) - 1,
                date_add_str[2],
                checkOut[0],
                checkOut[1]
              )
            ).format("YYYY-MM-DDTHH:mm");
          }
        } else if (d.checkIn !== null && d.checkOut === null) {
          let date = d.date.split("-");

          d.checkIn = d.checkIn.toFixed(2);
          let checkIn = d.checkIn.split(".");
          d.checkIn = moment(
            new Date(
              date[0],
              Number(date[1]) - 1,
              date[2],
              checkIn[0],
              checkIn[1]
            )
          ).format("YYYY-MM-DDTHH:mm");
          d.checkOut = null;
        } else if (d.checkOut !== null && d.checkIn === null) {
          let date = d.date.split("-");

          d.checkOut = d.checkOut.toFixed(2);
          let checkOut = d.checkOut.split(".");
          d.checkOut = moment(
            new Date(
              date[0],
              Number(date[1]) - 1,
              date[2],
              checkOut[0],
              checkOut[1]
            )
          ).format("YYYY-MM-DDTHH:mm");
          d.checkIn = null;
        } else {
          d.checkIn = null;
          d.checkOut = null;
        }

        //convert type hours
        if (d.hours !== undefined) {
          d.hours = Number(d.hours);
        } else {
          d.hours = null;
        }

        //data is undefined
        d.email === undefined && (d.email = null);
        d.hoursText === undefined && (d.hoursText = null);
        d.id === undefined ? (d.id = null) : (d.id = Number(d.id));
        d.inOutDetail === undefined && (d.inOutDetail = null);
        d.projectName === undefined && (d.projectName = null);

        //delete column date
        delete d.date;

        //push invalid data
        if (d.checkIn === "Invalid date" || d.checkOut === "Invalid date") {
          data_invalid.push(d);
        }
        if (d.hours === isNaN) {
          data_invalid.push(d);
        }
      });

      setData(fileData);
      setInvalid(data_invalid);

      //return data_invalid
      if (data_invalid.length > 0) {
        handleOpen();
      }
    };

    reader.readAsBinaryString(file);
  };

  return (
    <>
      {loadingImportWFH && <LinearProgress />}

      <br />

      <Grid>
        <Grid container justify="center">
          <input
            type="file"
            accept=".csv"
            id="contained-button-file"
            key={fileName}
            onChange={(e: any) => {
              setFileName(e.currentTarget.files[0].name);
              importExcel(e);
            }}
            className={classes.btnSelectFile}
          />
          <label htmlFor="contained-button-file">
            {!loadingImportWFH ? (
              <ChooseButton disableButton={false} />
            ) : (
              <ChooseButton disableButton={true} />
            )}{" "}
            {fileName}
          </label>
        </Grid>
        <br />

        <Grid container justify="center">
          {!loadingImportWFH &&
          data !== undefined &&
          invalid_data.length === 0 ? (
            <ImportButton
              loadingImportWFH={loadingImportWFH}
              insertData={insertData}
              disableButton={false}
            />
          ) : (
            <ImportButton
              loadingImportWFH={loadingImportWFH}
              insertData={insertData}
              disableButton={true}
            />
          )}
        </Grid>
      </Grid>

      {wfhSuccess && (
        <>
          <AlertSuccessWFH
            msg="Import WFH file completed."
            open={wfhSuccess}
            setOpen={setWfhSuccess}
            setFileName={setFileName}
            DEFAULT_FILENAME={DEFAULT_FILENAME}
            setData={setData}
          />
        </>
      )}

      <InvalidModal
        open={open}
        handleClose={handleClose}
        invalid_data={invalid_data}
      />
    </>
  );
}

export default ExcelImport;
