import { Backdrop, Button, Container, Modal, Typography } from "@material-ui/core";
import LeaveReport from "../../../models/LeaveReport";
import { useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import StatDetailLeaveRow from "./StatDetailLeaveRow";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: "80%",
            height: "80%"
        },
    }),
);

interface ICardLeave {
    height: number
    className: any
    title: string
    leave: LeaveReport[] | undefined
}

function CardLeave({ leave, title, className, height }: ICardLeave) {
    const classes = useStyles()
    const [showTable, setShowTable] = useState<boolean>(false)

    const handleClose = () => {
        setShowTable(false);
    };
    return (
        <>
            <Button fullWidth={true} variant="contained" className={className} style={{ height, textTransform: 'none' }} onClick={() => { setShowTable(true) }}>
                <Typography align="center">
                    <h1>{title}</h1>
                    <h1>{leave?.reduce((e, key) => e + key.totalHoursLeave, 0)} hr.</h1>
                </Typography>
            </Button>
            <Modal
                open={showTable}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Container className={classes.paper}>
                    <Typography align="left" >
                        <h1>{title}</h1>
                    </Typography>
                    <StatDetailLeaveRow leave={leave} />
                </Container>
            </Modal>
        </>
    )
}

export default CardLeave