import moment from "moment"
import { useCallback, useState } from "react"
import emailNotifyApi from "../api/EmailNotify"
import EmailConfig from "../models/EmailConfig"
import EmailNotification from "../models/EmailNotification"

const useEmailNotify = () => {
    const [emailConfig, setEmailConfig] = useState<EmailConfig[]>([])
    const [emailNotify, setEmailNotify] = useState<EmailNotification[]>([])
    const [loadingAll, setloadingAll] = useState<boolean>(false)
    const [checkFails, setCheckFails] = useState<EmailNotification[]>([])
    const [, setEditEmailConfig] = useState<EmailConfig>()
    const [, setEditLoadingEmailConfig] = useState<boolean>(false)



    const getEmailConfig = useCallback(
        async () => {
            try{
                var getEmailFormConfig = await emailNotifyApi.getEmailConfig()
                setEmailConfig(getEmailFormConfig.data);
            }catch{

            }
        },
        [setEmailConfig]
    );


    const putEmailConfig = useCallback(
        async (editConfig: EmailConfig) => {
            setEditLoadingEmailConfig(true)
            var getEmailFormConfig = await emailNotifyApi.editEmailConfig(editConfig)
            setEditEmailConfig(getEmailFormConfig.data);
            setEditLoadingEmailConfig(false)
        },
        [setEditEmailConfig]
    );

    const getEmailNotify = useCallback(
        async (startdate: any, endDate: any, empId: number, projId: number, filter: boolean) => {
            setloadingAll(true)
            var getEmailNotifyData

            if (filter === true) {
                getEmailNotifyData = await emailNotifyApi.getEmailNotifyFilter(
                    empId,
                    projId,
                    startdate,
                    endDate,
                );
            } else {
                getEmailNotifyData = await emailNotifyApi.getEmailNotify(
                    empId,
                    projId,
                    startdate,
                    endDate,
                );
            }

            //setEmailNotify(getEmailNotifyData.data.filter(e => e.email != "" && e.employeeId > 6 && e.employeeId < 9)); // TEST DATA FILTER EMAIL NOTT NULL
            //setEmailNotify(getEmailNotifyData.data.filter(e => e.email !== "")); // TEST DATA FILTER EMAIL NOTT NULL
            setEmailNotify(getEmailNotifyData.data);
            setloadingAll(false)
        },
        [setEmailNotify]
    );

    const insertEmailLog = useCallback(async (emailLog) => {
        try {
            const insertEmailLog = await emailNotifyApi.insertEmailNotifyLog(emailLog)
            insertEmailLog.data.forEach((m) => {
                emailNotify.forEach((e) => {
                    if (e.employeeId === m.employeeId && e.date === m.absenceDate) {
                        e.notifyLog.push(m)
                    }
                })
            })
        } catch (err) {
            window.alert('Something with wrong')
        }
    }, [emailNotify])

    const sendEmailNotify = useCallback(async (sendEmail: EmailNotification[]) => {
        try {


            const filterEmp = sendEmail.filter((v, i, a) => a.findIndex(t => (t.employeeId === v.employeeId)) === i)
            const dataEmp = filterEmp.map(e => e.employeeId)

            let checkData: any[] = []

            for (const data of dataEmp) {
                let send_email: any = []

                // close send email 
                sendEmail.forEach(e => {
                    if (e.employeeId === data) {
                        send_email.push(e)
                    }
                })

                const check = await emailNotifyApi.sendEmailNotifyLog(send_email)
                checkData.push(check.data)
                const log_send: any = [];
                emailNotify.forEach((e) => {
                    check.data.map((m: { date: any; employeeId: number; sendedEmail: any }) => {
                        if (m.employeeId === e.employeeId && m.date === e.date) {
                            log_send.push({
                                employeeId: e.employeeId,
                                sendDate: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                                absenceDate: moment(e.date).format("YYYY-MM-DDTHH:mm"),
                                firstName: e.firstName,
                                lastName: e.lastName,
                                senedStatus: m.sendedEmail  // m.sendedEmail 
                            });
                        }
                        return log_send
                    })
                })
                await insertEmailLog(log_send)
            }
            const checkFail: EmailNotification[] = []
            checkData.forEach(element => {
                element.forEach((e: EmailNotification) => {
                    //e.sendedEmail = false
                    if (e.sendedEmail === false) { // check fails
                        checkFail.push(e)
                    }
                });
            });
            setCheckFails(checkFail) // set fails
        } catch (err) {
            window.alert('Something with wrong')
        }
    }, [emailNotify, insertEmailLog, setCheckFails])


    return [
        insertEmailLog,
        getEmailNotify,
        emailNotify,
        sendEmailNotify,
        checkFails,
        setCheckFails,
        loadingAll,
        getEmailConfig,
        emailConfig,
        putEmailConfig,
    ] as const 
}

export default useEmailNotify
