/* eslint-disable no-sparse-arrays */

import moment from "moment";
import { WorkSheet } from "xlsx";
import React from "react";
import clsx from "clsx";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Fab from "@material-ui/core/Fab";
import CheckIcon from "@material-ui/icons/Check";
import SaveIcon from "@material-ui/icons/Save";
import ExportWorkHours from "../../../models/ExportWorkHours";
import { saveAs } from "file-saver";
import Excel from "exceljs";
import { unescape } from 'html-escaper';
import { sanitizeNoHtmlTagContent } from "../../../helper/stringHelper";

interface IExportExcel {
  workHoursDetails: ExportWorkHours[];
  startDate: any;
  endDate: any;
  calHours: any;
  calOverTime: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
    },
    wrapper: {
      margin: theme.spacing(1),
      position: "relative",
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    fabProgress: {
      color: green[500],
      position: "absolute",
      top: -6,
      left: -6,
      zIndex: 1,
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
  })
);

// function CalculationHourStandard(hour: number) {
//   return Number((Math.floor(hour) + ((Number((hour - Math.floor(hour)).toFixed(2)) * 100) / 60)).toFixed(2));
// }

function GetHourMinString(hour: number) {
  return Number(hour.toFixed(2)).toLocaleString("en", {useGrouping: false, minimumFractionDigits: 2}).replace('.', ':');
}

function ExportExcel({
  startDate,
  endDate,
  workHoursDetails,
  calHours,
  calOverTime,
}: IExportExcel) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const timer = React.useRef<number>();
  const [totalWorkDay] = React.useState(workHoursDetails !== null && workHoursDetails !== undefined && workHoursDetails.length > 0 && workHoursDetails[0].totalWorkday !== undefined ? "(" + workHoursDetails[0].totalWorkday.toString() + ")" : "(1)");
  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  React.useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  // return Address
  const columns = [
    { header: "No", key: "No" },
    { header: "Team", key: "Team" },
    { header: "Name", key: "Name" },
    { header: "NickName", key: "NickName" },
    { header: "WFH/WFO " + totalWorkDay, key: "WFH_WFO" },
    { header: "Date", key: "Date" },
    { header: "Check-In", key: "CheckIn" },
    { header: "Check-Out", key: "CheckOut" },
    { header: "Note", key: "Note" },
    { header: "HoursText", key: "HoursText" },
    { header: "Time work detail", key: "TimeWorkDetail" },
    { header: "Standard Hours", key: "StandardH" },
    // { header: "Work hours", key: "WorkH" },
    { header: "Work hours  (HH:mm)", key: "WorkHourMin" },
    { header: "Leave hours", key: "LeaveH" },
    { header: "Leave Detail", key: "LeaveD" },
    // { header: "Total Work hour", key: "TotalWH" },
    { header: "Total Work hour (HH:mm)", key: "TotalWorkHourMin" },
    // { header: "OverTime (Hour)", key: "OverTime" },
    { header: "OverTime (HH:mm)", key: "OverTimeMin" }
  ];

  const getCellAddress = (
    workSheet: WorkSheet,
    row: number,
    column: number
  ) => {
    let address = "";
    workSheet.getRow(row).eachCell((e: any, index_cell: any) => {
      if (index_cell === column) {
        address = e.address;
      }
    });
    return address;
  };

  const sanitizeNewLine = (value: string) => {
    return value.replaceAll(/\\n/g, "\r\n");
  }

  ////////// EXCEL ////////////
  const handleButtonClick = async () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(async () => {
        const datas = workHoursDetails;
        const workSheetName = `${moment(startDate).format("LL")} - ${moment(
          endDate
        ).format("LL")}`;

        const workBookName = "Time Report " + workSheetName;

        const workbook = new Excel.Workbook();
        const workSheet = workbook.addWorksheet(workSheetName);

        workSheet.columns = columns;
        // create header style
        workSheet.getRow(1).font = { bold: true };
        for (var c = 1; c <= columns.length; c++) {
          workSheet.getCell(1, c).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "70AD47" },
          };
        }

        // loop col of all row
        workSheet.columns.forEach((col, i) => {
          col.alignment = { horizontal: "center", vertical: "middle" };
          col.width = col.header !== undefined ? col.header.length + 10 : 0;
        });

        // loop add row (data)
        let row: number = 0;
        datas.forEach((data, index) => {
          // create team name
          let teams: string = "";
          data.projectList.forEach((p, i) => {
            if (p.isSelectedProject) {
              teams.length > 0
                ? (teams += ", " + p.projectName)
                : (teams += p.projectName);
            }
          });

          let summary_standard: number = 0;
          // let summary_workH: number = 0;
          // let summary_totalWH: number = 0;
          // let summary_overTime: number = 0;
          let wfh_only_date: string = '2023-08-17';

          if (data.workHistory.length + 1 > 1) {
            data.workHistory.forEach((info, i) => {
              if (new Date(info.date) >= new Date(wfh_only_date)) {
                if (!info.isWorkFromHome){
                  // Work from office
                  info.statusHours = 0;
                }
                else {
                  // Work from home
                  // Todo: if enter multiple projects?
                  if (info.standardHours <= 0) {
                    let numOverTime = Number(calOverTime(
                      8,
                      Number(calHours(info.workHours))
                    ));
                    info.statusHours = numOverTime;
                  }
                }
              }

              // create row object of work history
              let rowObj = {
                No: String(index + 1),
                Team: teams,
                Name: data.firstName + " " + data.lastName,
                NickName: data.nickName,
                Date: moment(info.date).format("LL"),
                CheckIn:
                  info.checkIn != null
                    ? moment(info.checkIn).format("HH:mm")
                    : "--.--",
                CheckOut:
                  info.checkOut != null
                    ? moment(info.checkOut).format("HH:mm")
                    : "--.--",
                TimeWorkDetail: sanitizeNoHtmlTagContent(unescape(sanitizeNewLine((info && info.inOutDetail) ? info.inOutDetail : ""))),
                Note: info.note,
                HoursText: info.hoursText,
                StandardH: Number(info.standardHours.toFixed(2)),
                // WorkH: CalculationHourStandard(info.workHours),
                WorkHourMin: GetHourMinString(info.workHours),
                LeaveH: Number(info.leaveHours.toFixed(2)),
                LeaveD: info.leaveDetail,
                // TotalWH: CalculationHourStandard(info.totalHours),
                TotalWorkHourMin: GetHourMinString(info.totalHours),
                WFH_WFO: data.wfH_WFO,
                // OverTime: CalculationHourStandard(info.statusHours),
                OverTimeMin: GetHourMinString(info.statusHours),
                ProjectId: info.projectId
              };
              // console.log("statusHours: " + info.statusHours);

              let currentDate = new Date(info.date);
              summary_standard += rowObj.StandardH;
              if (rowObj.ProjectId > 0 && info.isWorkFromHome && data.projectList.some(c => c.projectId === rowObj.ProjectId && !c.isMainProject) &&  currentDate.getDate() >= new Date(wfh_only_date).getDate()) {
                info.statusHours = info.totalHours;
                rowObj.OverTimeMin = GetHourMinString(info.statusHours);
                // console.log("statusHours2: " + info.statusHours);
              }
              workSheet.addRow(rowObj);
              row += 1;

              //style other project
              if (rowObj.ProjectId > 0) {
                if (data.projectList.some(c => c.projectId === rowObj.ProjectId && !c.isMainProject)) {
                  let color = data.projectList.find(c => c.projectId === rowObj.ProjectId)?.projectColor;
                  if (color !== undefined && color !== null && color?.length > 0) {
                    workSheet.getRow(row + 1).fill = {
                      type: "pattern",
                      pattern: "solid",
                      fgColor: { argb: color },
                    };
                  }
                }
              }

              //style overtime
              info.statusHours < 0
                ? (workSheet.getCell(row + 1, 17).font = {
                  color: { argb: "C00000" },
                })
                : info.statusHours > 0
                  ? (workSheet.getCell(row + 1, 17).font = {
                    color: { argb: "00B0F0" },
                  })
                  : (workSheet.getCell(row + 1, 17).font = {
                    color: { argb: "000000" },
                  });


              //style leave
              if (rowObj.LeaveH > 0) {
                workSheet.getCell(row + 1, 14).fill = {
                  type: "pattern",
                  pattern: "solid",
                  fgColor: { argb: "FFFF00" },
                };
              }

              // final workHistory add summary row
              if (i === data.workHistory.length - 1) {
                rowObj.No = "summary";
                rowObj.Team = "";
                rowObj.Name = "";
                rowObj.NickName = "";
                rowObj.Date = "";
                rowObj.CheckIn = "--";
                rowObj.CheckOut = "--";
                rowObj.Note = "";
                rowObj.HoursText = "";
                rowObj.TimeWorkDetail = "";
                rowObj.StandardH = summary_standard;
                // rowObj.WorkH = summary_workH;
                rowObj.WorkHourMin = GetHourMinString(data.workHours);
                rowObj.LeaveH = data.leaveHours;
                rowObj.LeaveD = "";
                // rowObj.TotalWH = summary_totalWH;
                rowObj.TotalWorkHourMin = GetHourMinString(data.totalWorks);
                rowObj.WFH_WFO = data.wfH_WFO;
                // rowObj.OverTime = CalculationHourStandard(Number(calOverTime(
                //   summary_standard,
                //   Number(calHours(data.totalWorks)))));
                let numOverTime = Number(calOverTime(
                  summary_standard,
                  Number(calHours(data.totalWorks))
                ));
                rowObj.OverTimeMin = GetHourMinString(numOverTime);

                workSheet.addRow(rowObj);
                row += 1;

                workSheet.getRow(row + 1).font = { bold: true };
                for (var c = 1; c <= columns.length; c++) {
                  workSheet.getCell(row + 1, c).fill = {
                    type: "pattern",
                    pattern: "solid",
                    fgColor: { argb: "FFE4C2" },
                  };
                }
                //style overtime
                numOverTime < 0
                  ? (workSheet.getCell(row + 1, 17).font = {
                    color: { argb: "FF0000" },
                    bold: true,
                  })
                  : numOverTime > 0
                    ? (workSheet.getCell(row + 1, 17).font = {
                      color: { argb: "0070C0" },
                      bold: true,
                    })
                    : (workSheet.getCell(row + 1, 17).font = {
                      color: { argb: "000000" },
                      bold: true,
                    });
                //style leave
                if (rowObj.LeaveH > 0) {
                  workSheet.getCell(row + 1, 14).font = {
                    color: { argb: "E26B0A" },
                    bold: true,
                  };
                }

                // merge summary cell
                workSheet.mergeCells(
                  `${getCellAddress(workSheet, row + 1, 1)}:${getCellAddress(
                    workSheet,
                    row + 1,
                    10
                  )}`
                );

                // merge no team name nickname col cell
                workSheet.mergeCells(
                  `${getCellAddress(
                    workSheet,
                    row + 1 - data.workHistory.length,
                    1
                  )}:${getCellAddress(workSheet, row, 1)}`
                );
                workSheet.mergeCells(
                  `${getCellAddress(
                    workSheet,
                    row + 1 - data.workHistory.length,
                    2
                  )}:${getCellAddress(workSheet, row, 2)}`
                );
                workSheet.mergeCells(
                  `${getCellAddress(
                    workSheet,
                    row + 1 - data.workHistory.length,
                    3
                  )}:${getCellAddress(workSheet, row, 3)}`
                );
                workSheet.mergeCells(
                  `${getCellAddress(
                    workSheet,
                    row + 1 - data.workHistory.length,
                    4
                  )}:${getCellAddress(workSheet, row, 4)}`
                );
                workSheet.mergeCells(
                  `${getCellAddress(
                    workSheet,
                    row + 1 - data.workHistory.length,
                    5
                  )}:${getCellAddress(workSheet, row, 5)}`
                );

                // add header table
                if (index < datas.length - 1) {
                  let headerRow = {
                    No: "No",
                    Team: "Team",
                    Name: "Name",
                    NickName: "NickName",
                    Date: "Date",
                    CheckIn: "Check-In",
                    CheckOut: "Check-Out",
                    Note: "Note",
                    HoursText: "HoursText",
                    TimeWorkDetail: "Time work detail",
                    StandardH: "Standard Hours",
                    // WorkH: "Work hours",
                    WorkHourMin: "Work hours (HH:mm)",
                    LeaveH: "Leave hours",
                    LeaveD: "Leave Detail",
                    // TotalWH: "Total Work hour",
                    TotalWorkHourMin: "Total Work hour (HH:mm)",
                    WFH_WFO: "WFH/WFO (" + datas[0].totalWorkday.toString() + ")",
                    // OverTime: "OverTime (Hour)",
                    OverTimeMin: "OverTime (HH:mm)"
                  };
                  workSheet.addRow(headerRow);
                  row += 1;
                  workSheet.getRow(row + 1).font = { bold: true };
                  for (let c = 1; c <= columns.length; c++) {
                    workSheet.getCell(row + 1, c).fill = {
                      type: "pattern",
                      pattern: "solid",
                      fgColor: { argb: "70AD47" },
                    };
                  }
                }
              }
            });
          }

          workSheet.eachRow({ includeEmpty: true }, function (row) {
            row.eachCell({ includeEmpty: true }, function (cell) {
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            });
          });
        });

        const buf = await workbook.xlsx.writeBuffer();
        saveAs(new Blob([buf]), `${workBookName}.xlsx`);

        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  return (
    <>
      <div className={classes.root}>
        <div className={classes.wrapper}>
          <Fab
            aria-label="save"
            color="primary"
            className={buttonClassname}
            onClick={handleButtonClick}
          >
            {success ? <CheckIcon /> : <SaveIcon />}
          </Fab>
          {loading && (
            <CircularProgress size={68} className={classes.fabProgress} />
          )}
        </div>
      </div>
    </>
  );
}
export default ExportExcel;
