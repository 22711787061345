import moment from "moment";
import { useCallback, useState } from "react";
import reportApi from "../api/ReportAPI";
import BarChart from "../models/Barchart";
import ExportWorkHours from "../models/ExportWorkHours";
import LeaveReport from "../models/LeaveReport";
import PieChartRatio from "../models/PieChartRatio";
import StatisticReport from "../models/StatisticReport";
import TeamInfo from "../models/TeamInfo";
import TimeAttendanceReport from "../models/TimeAttendanceReport";

const useReportAPI = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingStatistic, setLoadingStatistic] = useState<boolean>(false);
  const [loadingLeaves, setLoadingLeaves] = useState<boolean>(false);
  const [loadingTimeAttendance, setLoadingTimeAttendance] =
    useState<boolean>(false);
  const [loadingWorkHoursDetail, setLoadingWorkHoursDetail] =
    useState<boolean>(false);


  const [teams, setTeams] = useState<TeamInfo[]>([]);
  const [leaves, setLeaves] = useState<LeaveReport[]>([]);

  const [statisticReport, setStatisticReport] = useState<StatisticReport>();
  const [ratioEmpWorks, setRatioEmpWorks] = useState<PieChartRatio[]>([]);
  const [datasBarchart, setdatasBarchart] = useState<BarChart[]>([]);
  const [timeAtts, setTimeAtts] = useState<TimeAttendanceReport[]>([]);
  const [workHoursDetail, setWorkHoursDetail] = useState<ExportWorkHours[]>([]);


  //Team
  const getTeams = useCallback(async () => {
    setLoading(true);
    const teamInfo = await reportApi.getTeams();
    setTeams(teamInfo.data);
    setLoading(false);
  }, [setTeams, setLoading]);

  //Statistic
  const getStatistics = useCallback(
    async (_startDate: any, _endDate: any) => {
      setLoadingStatistic(true);
      try {
        const statisticReport = await reportApi.getStatistics(
          _startDate,
          _endDate
        );
        //const statisticReport = { data: mock_data };
        setStatisticReport(statisticReport.data);
        if (statisticReport.data) {
          const timeInMapTable = new Map<string, number>();
          const timeOutMapTable = new Map<string, number>();

          // mapping BarChart
          statisticReport.data.periods.forEach((period) => {
            const { periodTimeIn, periodTimeOut } = period;
            const curTimeInDate = periodTimeIn;
            const curTimeOutDate = periodTimeOut;

            if (timeInMapTable.has(curTimeInDate)) {
              timeInMapTable.set(
                curTimeInDate,
                (timeInMapTable.get(curTimeInDate) ?? 0) + 1
              );
            } else {
              timeInMapTable.set(curTimeInDate, 1);
            }

            if (timeOutMapTable.has(curTimeOutDate)) {
              timeOutMapTable.set(
                curTimeOutDate,
                (timeOutMapTable.get(curTimeOutDate) ?? 0) + 1
              );
            } else {
              timeOutMapTable.set(curTimeOutDate, 1);
            }
          });


          let datas: BarChart[] = [];
          timeInMapTable.forEach((value, key) => {
            if (timeOutMapTable.has(key)) {
              datas.push({
                empTimeInCount: value,
                empTimeOutCount: timeOutMapTable.get(key),
                period: moment(key).format("HH:mm"),
              });
            } else {
              datas.push({
                empTimeInCount: value,
                empTimeOutCount: 0,
                period: moment(key).format("HH:mm"),
              });
            }
          });
          timeOutMapTable.forEach((value, key) => {
            if (timeInMapTable.has(key)) {
              //datas.push({ empTimeInCount: timeInMapTable.get(key), empTimeOutCount: value ,period: moment(key).format('HH:mm') });
              datas.map((e) => {
                if (e.period === key) {
                  let d = {
                    empTimeInCount: timeInMapTable.get(key),
                    empTimeOutCount: value,
                    period: moment(key).format("HH:mm"),
                  };
                  return d;
                } else {
                  return e;
                }
              });
            } else {
              datas.push({
                empTimeInCount: 0,
                empTimeOutCount: value,
                period: moment(key).format("HH:mm"),
              });
            }
          });

          // sort be period date
          let date = moment(new Date()).format("YYYY/MM/DD");
          datas.sort(function (a, b) {
            return (
              Date.parse(
                date + " " + a.period.slice(0, -2) + " " + a.period.slice(-2)
              ) -
              Date.parse(
                date + " " + b.period.slice(0, -2) + " " + b.period.slice(-2)
              )
            );
          });

          // datas.sort((a, b) => {
          //   let hh_a = a.period.split(":")[0]
          //   let hh_b = b.period.split(":")[0]
          //   if (hh_a < hh_b) {
          //     return -1;
          //   }
          //   else if (hh_a > hh_b) {
          //     return 1;
          //   }
          // })

          setdatasBarchart(datas);

          // mapping PieChart
          let underTime: PieChartRatio = {
            label: "UnderTime",
            workHour: statisticReport.data.totalUnderTime,
          };

          let overTime: PieChartRatio = {
            label: "OverTime",
            workHour: statisticReport.data.totalOverTime,
          };
          setRatioEmpWorks([underTime, overTime]);

        }
      } catch (err) {
      }
      setLoadingStatistic(false);
    },
    [
      setLoadingStatistic,
      setStatisticReport,
      setRatioEmpWorks,
      setdatasBarchart,
    ]
  );

  //Work Hours
  const getWorkHoursDetail = useCallback(
    async (startdate: any, endDate: any, empId: number, projId: number) => {
      setLoadingWorkHoursDetail(true);
      const workHoursDetail = await reportApi.getWorkHoursDetail(
        startdate,
        endDate,
        empId,
        projId
      );
      setWorkHoursDetail(workHoursDetail.data);
      setLoadingWorkHoursDetail(false);
    },
    [setLoadingWorkHoursDetail, setWorkHoursDetail]
  );

  //Leave
  const getLeaves = useCallback(
    async (date1: any, date2: any, team: number, emp: number) => {
      setLoadingLeaves(true);
      const leaveInfo = await reportApi.getLeaveEmployeesReport(
        date1,
        date2,
        team,
        emp
      );
      if (!leaveInfo.data) {
        setLeaves([]);
      } else {
        setLeaves(leaveInfo.data);
      }
      setLeaves(leaveInfo.data);
      setLoadingLeaves(false);
    },
    [setLeaves, setLoadingLeaves]
  );

  //Time Attendance
  const getTimeAttendances = useCallback(
    async (startdate: any, endDate: any, empId: number, projId: number) => {
      setLoadingTimeAttendance(true);
      const timeAttInfo = await reportApi.getTimeAttendancesReportInfo(
        startdate,
        endDate,
        empId,
        projId
      );
      setTimeAtts(timeAttInfo.data);
      setLoadingTimeAttendance(false);
    },
    [setTimeAtts, setLoadingTimeAttendance]
  );



  return [
    loading,
    loadingStatistic,
    loadingLeaves,
    loadingTimeAttendance,
    getTeams,
    teams,
    getLeaves,
    leaves,
    getStatistics,
    statisticReport,
    ratioEmpWorks,
    datasBarchart,
    timeAtts,
    getTimeAttendances,
    getWorkHoursDetail,
    workHoursDetail,
    loadingWorkHoursDetail,

  ] as const;
};
export default useReportAPI;
