import { useCallback, useState } from "react"
import attendApi from "../api/AttendanceAPI"
import EmployeeIsActive from "../models/EmployeeIsActive"
import wfhAttendApi from "../api/WFHAttendanceAPI"
import AttendanceWFH from "../models/AttendanceWFH"

const useWFHAttendanceAPI = () => {

    const [wfhAttendances, setWFHAttendances] = useState<AttendanceWFH[]>([])
    const [wfhEditAttendance, setWFHEditAttendance] = useState<AttendanceWFH>()
    const [loading, setLoading] = useState<boolean>(false)
    const [employeesIsActive, setemployeesIsActive] = useState<EmployeeIsActive[]>([])

    const [loadingImportWFH, setLoadingImportWFH] =
        useState<boolean>(false);
    const [wfhSuccess, setWfhSuccess] = useState<boolean>(false);

    const getWFHAttendances = useCallback(
        async (date1: any, date2: any) => {
            setLoading(true)
            const attends = await wfhAttendApi.getAttendanceWFH(date1, date2);
            setWFHAttendances(attends.data)
            setLoading(false)
        }, [setWFHAttendances, setLoading])

    const getEmployeeIsActive = useCallback(
        async () => {
            setLoading(true)
            const empActive = await attendApi.getEmployeeIsActive()
            setemployeesIsActive(empActive.data)
            setLoading(false)
        }, [setemployeesIsActive, setLoading]
    )

    const editWFHAttendance = useCallback(
        async (count: number, checkin: any, checkout: any, hours: string, hoursText: string, inoutDetails: string) => {
            try {
                setLoading(true);
                const editAtt = await wfhAttendApi.editAttendanceWFH(count, checkin, checkout, hours, hoursText, inoutDetails);
                setWFHEditAttendance(editAtt.data);
                let newAttends: AttendanceWFH[] = wfhAttendances.map((e) => {
                    if (e.wfh_Id === editAtt.data.wfh_Id) {
                        let obj = e
                        obj.checkIn = editAtt.data.checkIn
                        obj.checkOut = editAtt.data.checkOut
                        obj.hours = editAtt.data.hours
                        obj.hoursText = editAtt.data.hoursText
                        obj.inOutDetail = editAtt.data.inOutDetail
                        return obj;
                    }
                    else {
                        return e;
                    }
                });
                setWFHAttendances(newAttends)
                setLoading(false)
            } catch (err) {
                window.alert('Something with wrong')
            }
        }, [setWFHEditAttendance, setLoading, setWFHAttendances, wfhAttendances]
    );


    //Import Excel
    const insertExcel = useCallback(
        async (data: AttendanceWFH[]) => {
            setLoadingImportWFH(true);
            try {
                await wfhAttendApi.insertExcel(data);
                setWfhSuccess(true);
            } catch (err) {
                window.alert(err + "\nSomething with wrong.");
            }
            setLoadingImportWFH(false);
        },
        [setLoadingImportWFH, setWfhSuccess]
    );

    const insertEmpWFH = useCallback(
        async (data: AttendanceWFH) => {
            setLoadingImportWFH(true);
            try {
                let res = await wfhAttendApi.insertEmp(data);
                if (res) {
                    //Need to refresh data after adding
                    setWfhSuccess(true);
                    setLoadingImportWFH(false);

                    return res;
                }
            }
            catch (err: any) {
                if (err && err.response && err.response.status === 409) {
                    setLoadingImportWFH(false);
                    return err.response;
                }
                else {
                    console.log(`Something went wrong. ${err}`);
                    setLoadingImportWFH(false);
                    return false;
                }
            }
        }, [setLoadingImportWFH, setWfhSuccess]
    );

    const enableEditModeAndResendEmail = useCallback(async (wfhId: number, empId: number, workDate: any) => {
        try {
            setLoading(true);
            await wfhAttendApi.enableEditModeAndResendEmail(wfhId, empId, workDate);
            setLoading(false)
        }
        catch (err) {
            console.log(err);
        }

    }, [setLoading]);

    return [
        getWFHAttendances,
        wfhAttendances,
        loading,
        getEmployeeIsActive,
        employeesIsActive,
        editWFHAttendance,
        wfhEditAttendance,
        insertExcel,
        loadingImportWFH,
        wfhSuccess,
        setWfhSuccess,
        insertEmpWFH,
        enableEditModeAndResendEmail
    ] as const

}

export default useWFHAttendanceAPI
