import WeeklyEmployee from "../models/WeeklyEmployee"
import Attendance from "../models/Attendance"
import AttendanceForm from "../models/AttendanceForm"
import EmployeeLeave from "../models/EmployeeLeave"
import EmployeeIsActive from "../models/EmployeeIsActive"
import EmployeeAbsences from "../models/EmployeeAbsences"
import EmployeeIsActiveTeam from "../models/EmployeeIsActiveTeam"
import HttpRequest from "../helper/httpRequest"

const API_URL = process.env.REACT_APP_API_URL

const httpRequest = new HttpRequest(`${API_URL}`);


const attendApi = {
    getAttend: (date1: any, date2: any) => {
        return httpRequest.get<Attendance[]>(API_URL + "api/Attendance/" + date1 + "/" + date2)
    },
    getWeeklyEmployee: (date: any) => {
        return httpRequest.get<WeeklyEmployee[]>(API_URL + "api/Employee?d=" + date)
    },
    checkinAttendances: (formAtt: AttendanceForm) => {
        return httpRequest.post<Attendance>(`${API_URL}api/Attendance`, formAtt)
    },
    getEmployeeLeave: (date: any) => {
        return httpRequest.get<EmployeeLeave[]>(API_URL + "api/Employee/leave?d=" + date)
    },
    editAttendanceAdmin: (count: number, checkin: any, checkout: any, note: string, isIncludeBreak: boolean, worklog: string) => {
        return httpRequest.put<Attendance>(`${API_URL}api/Attendance/EditAttendance`, null, {
            params: {
                count: count,
                checkin: checkin,
                checkout: checkout,
                note: note,
                isIncludeBreak: isIncludeBreak,
                inOutDetail: worklog
            }
        })
    },
    getEmployeeIsActive: () => {
        return httpRequest.get<EmployeeIsActive[]>(API_URL + "api/Employee/EmployeeIsActive")
    },

    getEmployeeIsActiveAdmin: () => {
        return httpRequest.get<EmployeeIsActive[]>(API_URL + "api/Employee/EmployeeIsActiveIncludeAdmin")
    },

    getEmployeeIsActiveTeam: () => {
        return httpRequest.get<EmployeeIsActiveTeam[]>(API_URL + "api/Employee/EmployeeIsActiveTeam")
    },

    editEmployeeAttendID: (employeeId: number, newAttId: string) => {
        return httpRequest.put<EmployeeIsActive>(`${API_URL}api/Employee/EmployeeAttendID`, null, {
            params: {
                empId: employeeId,
                newAttendID: newAttId
            }
        })
    },
    getEmployeeAbsences: (date1: any, date2: any) => {
        return httpRequest.get<EmployeeAbsences[]>(`${API_URL}api/Attendance/EmployeeAbsence`, {
            params: {
                startDate: date1,
                endDate: date2
            }
        })
    }

}

export default attendApi
