import DateFnsUtils from "@date-io/date-fns";
import { Backdrop, Button, createStyles, Fade, Grid, makeStyles, TextField, Theme } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import { KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import moment from "moment";
import { useState } from "react";
import AttendanceForm from "../../models/AttendanceForm";
import EmployeeAbsences from "../../models/EmployeeAbsences";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import AlertWarning from "../SnackBarAlert/AlertWarning";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../ManageTimeAttendance/rte.css';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		modal: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
		paper: {
			backgroundColor: theme.palette.background.paper,
			border: "2px solid #000",
			boxShadow: theme.shadows[5],
			padding: theme.spacing(2, 4, 3),
		},
		container: {
			display: "flex",
			flexWrap: "wrap",
		},
		textField: {
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			width: 200,
		},
		button: {
			margin: theme.spacing(1),
		},
		form: {
			paddingBottom: '10%'
		},
		gridPadding: {
			paddingTop: 10,
			paddingBottom: 10
		}
	})
);

interface AddTimeAttendanceProps {
	emp: EmployeeAbsences;
	open: boolean;
	setOpen: any;
	checkIn: any;
	refreshData: any;
}

function AddTimeAttendance({ emp, open, setOpen, checkIn, refreshData }: AddTimeAttendanceProps) {

	const classes = useStyles()
	const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
	const [note, setNote] = useState<string>("");
	const [worklog, setWorkLog] = useState<string>("");
	const [timeIn, setTimeIn] = useState<Date | null>(null);
	const [timeOut, setTimeOut] = useState<Date | null>(null);
	const [warningTime, setWarningTime] = useState<boolean>(false);
	const [warningFillTimeIn, setWarningFillTimeIn] = useState<boolean>(false);
	const [isIncludeBreak, setIsIncludeBreak] = useState<boolean>(true);

	const handleClose = () => {
		setOpen(false);
	};

	const displayConfirmDialog = () => {
		setOpenConfirmDialog(true);
	};

	const selectTimeIn = (date: Date | null) => {
		setTimeIn(date);
	};

	const selectTimeOut = (date: Date | null) => {
		setTimeOut(date);
	};

	const convertDate_2_Datetime = (
		date: Date | null,
		time: Date | null
	): string | null => {
		if (date === null || time === null) {
			return null;
		} else {
			return (
				date.getFullYear() +
				"-" +
				leftpad(date.getMonth() + 1, 2) +
				"-" +
				leftpad(date.getDate(), 2) +
				"T" +
				leftpad(time.getHours(), 2) +
				":" +
				leftpad(time.getMinutes(), 2) +
				":" +
				leftpad(time.getSeconds(), 2)
			);
		}
	};

	const leftpad = (val: any, resultLength = 2, leftpadChar = "0"): string => {
		return (String(leftpadChar).repeat(resultLength) + String(val)).slice(
			String(val).length
		);
	};

	const addAttendance = async () => {
		let formAtt: AttendanceForm = {
			attendId: emp.attendId,
			note: note,
			checkIn: convertDate_2_Datetime(new Date(emp.date), timeIn),
			checkOut: convertDate_2_Datetime(new Date(emp.date), timeOut),
			isIncludeBreak: isIncludeBreak,
			inOutDetail: worklog
		};

		if ((timeOut !== null && timeIn !== null) || (timeOut === null)) {
			var response: any;
			if (timeOut && timeIn) {
				if (Number(moment(timeIn).format("HH.mm")) > Number(moment(timeOut).format("HH.mm"))) {
					setWarningTime(true);
				}
				else {
					response = await checkIn(formAtt);
					if (response && response.status === 200) {
						handleClose();
						refreshData();
					}
				}
			}
			else {
				response = await checkIn(formAtt);
				if (response && response.status === 200) {
					handleClose();
					refreshData();
				}
			}

		}
		else {
			setWarningFillTimeIn(true);
		}
	};

	return (
		<Dialog
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			maxWidth={"md"}
			className={classes.modal}
			open={open}
			onClose={handleClose}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
		>
			<Fade in={open}>
				<div className={classes.paper}>
					<h2 id="transition-modal-title">{emp?.nickName}</h2>
					<form className={classes.container} noValidate>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Grid
								container
								justify="space-around"
								style={{ background: "white" }}
							>
								<KeyboardTimePicker
									margin="normal"
									id="time-picker"
									label="Time In"
									value={timeIn}
									onChange={selectTimeIn}
									KeyboardButtonProps={{
										"aria-label": "change time",
									}}
									required
									ampm={false}
								/>
								<KeyboardTimePicker
									margin="normal"
									id="time-picker"
									label="Time Out"
									value={timeOut}
									onChange={selectTimeOut}
									KeyboardButtonProps={{
										"aria-label": "change time",
									}}
									ampm={false}
								/>
							</Grid>
						</MuiPickersUtilsProvider>
						<Grid item xs={12} className={classes.gridPadding}>
							<FormControlLabel control={
								<Switch checked={isIncludeBreak} color="primary" onChange={(e) => {
									setIsIncludeBreak(e.target.checked);
								}} />
							} label="Include a lunch break time" />
						</Grid>
						<Grid item xs={12} className={classes.gridPadding}>
							<TextField
								fullWidth={true}
								label="Note"
								variant="filled"
								style={{ background: "white" }}
								type="text"
								value={note}
								multiline
								inputProps={{ maxLength: 100 }}
								onChange={(e) => {
									setNote(e.target.value);
								}}
							/>
						</Grid>
						<Grid item xs={12} className={classes.gridPadding}>
							{/* <TextField
								fullWidth={true}
								label="Work details"
								variant="filled"
								style={{ background: "white" }}
								type="text"
								value={worklog}
								multiline
								onChange={(e) => {
									setWorkLog(e.target.value);
								}}
							/> */}
							<div className="custom-rte custom-rte-edit">
								<CKEditor
									editor={ClassicEditor}
									config={{
										toolbar: ['bold', 'italic', 'link'],
										placeholder: "Worklog"
									}}
									data={worklog}
									onChange={(event: any, editor: any) => {
										console.log(event);
										console.log(editor);
										const data = editor.getData();
										setWorkLog(data);
									}}
								/>
							</div>
						</Grid>
						<Button
							fullWidth={true}
							variant="contained"
							type="button"
							style={{ background: "#FAC330" }}
							onClick={displayConfirmDialog}
						>
							Submit
						</Button>

						<AlertWarning
							msg="check in time should be more than check out time !"
							open={warningTime}
							setOpen={setWarningTime}
						/>

						<AlertWarning
							msg="fill check in time!"
							open={warningFillTimeIn}
							setOpen={setWarningFillTimeIn}
						/>

						<ConFirmDialog
							title="Save TimeAttendance"
							description="Do you want to save timeattendance ?"
							open={openConfirmDialog}
							setOpen={setOpenConfirmDialog}
							actionDialog={addAttendance} />

					</form>
				</div>
			</Fade>
		</Dialog>
	)
}

export default AddTimeAttendance
