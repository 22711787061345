import React, { useEffect, useState } from "react";
import {
    IconButton,
    Dialog,
    DialogContent,
    DialogTitle,
    InputLabel,
    TextField,
    Container,
    Grid,
} from "@material-ui/core";

import CloseIcon from '@material-ui/icons/Close';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditorConfig } from "../../../helper/ckEditorConfig";
import { unescape } from 'html-escaper';
import "../../ManageTimeAttendanceWFH/rte.css";

import workHistoryDetails from "../../../models/WorkHistoryDetails";
import ProjectList from "../../../models/ProjectList";


interface IDialog {
    open: boolean,
    projects: ProjectList[];
    selectedWork?: workHistoryDetails;
    handleCloseWorkHistoryDetailsDialog: any;
}

export default function WorkHistoryDetailsDialog({ open, projects, selectedWork, handleCloseWorkHistoryDetailsDialog }: IDialog) {
    const [projectName, setProjectName] = useState("");
    const [workDetails, setWorkDetails] = useState("");

    useEffect(() => {
        if (projects && selectedWork) {
            var currentProject = projects.filter((x: any) => x.projectId === selectedWork.projectId);
            if (currentProject && currentProject.length > 0) {
                setProjectName(currentProject[0].projectName);
            }
            else {
                //Work From Office
                setProjectName(projects[0].projectName);
            }
        }

        //Sanitize "\n" in work details content with new line
        if (selectedWork && selectedWork.isWorkFromHome) {
            var workDetailsSanitized = selectedWork.inOutDetail.replaceAll(/\\n/g, "\r\n");
            setWorkDetails(unescape(workDetailsSanitized));
        }
        else {
            setWorkDetails(`
                Work From Office
                ${selectedWork?.inOutDetail}
            `);
        }
    }, [projects, selectedWork]);

    return (
        <Dialog maxWidth={"md"} fullWidth={true} open={open} onClose={handleCloseWorkHistoryDetailsDialog}>
            <DialogTitle>
                Work History Details
                <IconButton
                    aria-label="close"
                    style={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                    }}
                    onClick={handleCloseWorkHistoryDetailsDialog}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Container maxWidth="lg" style={{ marginTop: 20, marginBottom: 20 }}>
                    <div>
                        <Grid container spacing={2}>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <InputLabel>Project</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    value={projectName}
                                    size="small"
                                    variant="outlined"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <InputLabel>Details</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <div className="custom-rte custom-rte-edit work-history">
                                    <CKEditor
                                        editor={ClassicEditor}
                                        config={CKEditorConfig}
                                        data={(workDetails ? workDetails : "")}
                                        disabled={true}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <InputLabel>Date</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid
                                        container
                                        style={{ background: "white" }}
                                    >
                                        <KeyboardDatePicker
                                            disabled={true}
                                            format={"dd/MM/yyyy"}
                                            value={selectedWork ? selectedWork.date : ""}
                                            onChange={() => { }}
                                            required />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <InputLabel>Check In</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid
                                        container
                                        style={{ background: "white" }}
                                    >
                                        <TextField
                                            margin="normal"
                                            type="datetime-local"
                                            disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={selectedWork ? selectedWork.checkIn : ""}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <InputLabel>Check Out</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Grid
                                        container
                                        style={{ background: "white" }}
                                    >
                                        <TextField
                                            margin="normal"
                                            type="datetime-local"
                                            disabled={true}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={selectedWork ? selectedWork.checkOut : ""}
                                        />
                                    </Grid>
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Grid item xs={2} style={{ display: "flex", alignItems: "center" }}>
                                <InputLabel>Work Hours</InputLabel>
                            </Grid>
                            <Grid item xs={10}>
                                <TextField
                                    value={selectedWork ? selectedWork.workHours : ""}
                                    size="small"
                                    variant="outlined"
                                    disabled={true}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </DialogContent>
        </Dialog>
    );
}
