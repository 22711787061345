import axios from "axios";
import { useCallback, useState } from "react";
import authApi, { Auth } from "../api/AuthAPI";
import KeepLoginMachineLog from "../models/KeepLoginMachineLog";
import moment from "moment";
import useKeepLoginMachineLog from "./useKeepLoginMachineLog";
import platform from "platform";

const useAuthAPI = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [notFound, setNotFound] = useState<boolean>(false);

  const [insertKeepLoginMachineLog] = useKeepLoginMachineLog();

  const getToken = useCallback(
    async (auth: Auth) => {
      // device name
      let deviceName = "DeviceName";
      let ipRes = "";
      // load ip address from the API
      try {
        const ip = await axios.get("https://api.ipify.org?format=json");
        ipRes = ip.data.ip;
      } catch (error) {
        ipRes = "Get IP Addiess Error";
      }

      // device os
      let deviceOs: any = platform.description;

      // access time
      const accessTime = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");

      // web access
      const webAccess = "mycos-time-attendance-admin";

      let log: KeepLoginMachineLog = {
        DeviceName: deviceName,
        DeviceIp: ipRes,
        DeviceOs: deviceOs,
        AccessTime: accessTime,
        WebAccess: webAccess,
      };

      setLoading(true);

      try {
        const token = await authApi.getToken(auth);
        localStorage.setItem("token", token.data);
        insertKeepLoginMachineLog(log);

        if (token.data === "") {
          setNotFound(true);
        }
      } catch (err) {
        setError(true);
      }
      setLoading(false);
    },
    [setLoading, setError, setNotFound, insertKeepLoginMachineLog] // localStorage.setItem
  );

  return [loading, error, notFound, getToken] as const;
};

export default useAuthAPI;
