import HttpRequest from "../helper/httpRequest";
import KeepLoginMachineLog from "../models/KeepLoginMachineLog";

const API_URL = process.env.REACT_APP_API_URL
const httpRequest = new HttpRequest(`${API_URL}`);

const KeepLoginMachineLogApi = {

    postKeepLoginMachineLog: (loginLog: KeepLoginMachineLog) => {
       return httpRequest.post<KeepLoginMachineLog>(`${API_URL}api/KeepLoginMachineLog`, loginLog)
    }

}

export default KeepLoginMachineLogApi