import { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";

import useAuthApi from "../../hook/useAuthAPI";
import { useHistory } from "react-router-dom";
import { Auth } from "../../api/AuthAPI";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function LoginPage() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, error, notFound, getToken] = useAuthApi();
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [attendId, setAttendId] = useState<string>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const CloseErrorDialog = () => {
    setOpenDialog(false);
  };

  const onLogin = async (e: any) => {
    e.preventDefault();

    if (attendId !== "") {
      const user: Auth = {
        email: "",
        password: attendId ?? "",
      }
      await getToken(user);

      if (!loading) {
        // 204 No content
        if (notFound || error) {
          setOpenDialog(true);
        }

        if (
          localStorage.getItem("token") !== "" &&
          localStorage.getItem("token") !== null
        ) {
          history.push("/");
        }
      }
    } else {
      setIsWarning(true);
    }
  };

  return (
    <>
      <Grid container justify="center" alignItems="center">
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>

            <Typography component="h1" variant="h5">
              Sign in
            </Typography>

            <form className={classes.form} noValidate>
              {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            /> */}
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={(e: any) => {
                  setAttendId(e.target.value);
                }}
              />
              {isWarning && (
                <Alert severity="warning">Please , Enter Attend ID</Alert>
              )}
              {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={onLogin}
              >
                Sign In
              </Button>

              {/* <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid> */}

              <Dialog
                open={openDialog}
                onClose={CloseErrorDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  Not Found User
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Please, Enter Attend ID again !
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={CloseErrorDialog} color="primary">
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </form>

            {loading && <>Loading...</>}
          </div>
        </Container>
      </Grid>
    </>
  );
}
export default LoginPage;
