import { Backdrop, Button, createStyles, Fade, Grid, makeStyles, Modal, TextField, Theme, Typography } from "@material-ui/core";
import { useState } from "react";
import EmployeeIsActive from "../../models/EmployeeIsActive";
import CasinoIcon from '@material-ui/icons/Casino';
import SaveIcon from "@material-ui/icons/Save";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import AlertWarning from "../SnackBarAlert/AlertWarning";
import { useEffect } from "react";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        container: {
            display: "flex",
            flexWrap: "wrap",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        button: {
            margin: theme.spacing(1),
        },
        form: {
            paddingBottom: '10%'
        }
    })
);

interface EditAttendIdProps {
    open: boolean
    setOpen: any
    emp?: EmployeeIsActive
    editEmployeeAttendId: any
    employees: EmployeeIsActive[]
}

function EditAttendId({ open, setOpen, emp, editEmployeeAttendId, employees }: EditAttendIdProps) {
    const classes = useStyles()
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

    const inputRef = React.useRef<any>(null);

    const handleClose = () => {
        setOpen(false);
    };

    const displayConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const [attId, setAttId] = useState<string | undefined>(emp?.attendId)
    const [lengthAlert, setLengthAlert] = useState<boolean>(false)
    const [duplicatedId, setDuplicatedId] = useState<boolean>(false)

    const editAttendId = () => {
        if (attId?.length !== 4) {
            openLengthAlert()
        } else if (!employees.find(e => e.attendId === attId) || attId === emp?.attendId) {
            editEmployeeAttendId(emp?.employeeId, attId)
            setOpen(false)
        } else {
            openDuplicatedIdAlert()
        }

    }

    const openLengthAlert = () => {
        setLengthAlert(true)
    }

    const openDuplicatedIdAlert = () => {
        setDuplicatedId(true)
    }

    useEffect(() => {
        setAttId(emp?.attendId !== null || undefined ? emp?.attendId : "")
    }, [emp])


    const randomId = () => {
        const num = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
        var newId = ""
        for (let i = 0; i < 4; i++) {
            newId = newId + num[Math.floor(Math.random() * num.length)];
        }
        if (!employees.find(e => e.attendId === newId)) {
            setAttId(newId);
        } else {
            randomId();
        }
    }
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    <h2 id="transition-modal-title">{emp?.nickName}</h2>
                    <form className={classes.container} noValidate>
                        <Grid container direction="row" justify="space-evenly" alignItems="center" className={classes.form}>
                            <Grid item >
                                <TextField
                                    required
                                    inputProps={{ minLength: 4, maxLength: 4, inputMode: 'numeric' }}
                                    id="outlined-helperText"
                                    label="Attend ID"
                                    value={attId}
                                    onChange={(e) => {
                                        setAttId(e.target.value);
                                    }}
                                    variant="outlined"
                                    fullWidth
                                    ref={inputRef}
                                />
                            </Grid>
                            <Grid item>
                                <Button style={{ background: "#25272E" }}
                                    onClick={randomId}
                                >
                                    <CasinoIcon style={{ color: "#FAC330" }} />
                                    <Typography variant="subtitle1" style={{ color: "white" }} >
                                        Random
                                    </Typography>
                                </Button>
                            </Grid>

                        </Grid>
                        <br />
                        <br />

                        <Button
                            style={{ margin: "auto" }}
                            type="button"
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<SaveIcon />}
                            onClick={displayConfirmDialog}
                        >
                            Save
                        </Button>

                        <AlertWarning msg="Attend ID must be 4 characters." open={lengthAlert} setOpen={setLengthAlert} />
                        <AlertWarning msg="This attend ID is duplicated. " open={duplicatedId} setOpen={setDuplicatedId} />

                        <ConFirmDialog title="Change Attend ID" description="Do you want to change Attend ID ?" open={openConfirmDialog} setOpen={setOpenConfirmDialog} actionDialog={editAttendId} />

                    </form>
                </div>
            </Fade>
        </Modal>
    )
}

export default EditAttendId