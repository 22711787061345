import {
  CircularProgress,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
  withStyles,
} from "@material-ui/core";
import React from "react";
import TimeAttendanceReport from "../../../models/TimeAttendanceReport";
import TimeAttendancesRow from "./TimeAttendancesRow";

interface ITimeAttendancesTable {
  loading: boolean;
  timeAtts: TimeAttendanceReport[];
  teamTA: number;
  page: number
  setPage: any
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    root1: {
      width: "93%",
      margin: "auto",
    },
    table: {
      minWidth: 650,
    },
    thead: {
      background: "#25272E",
    },
  })
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "white",
    textAlign: "center",
  },
}))(TableCell);

function TimeAttendancesTable({
  loading,
  timeAtts,
  teamTA,
  page,
  setPage
}: ITimeAttendancesTable) {
  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  return (
    <>
      {loading ? (
        <>
          <Grid container
            justify="center"
            alignItems="center">
            <CircularProgress></CircularProgress>
          </Grid>
        </>
      ) : (
        <Paper>
          <TableContainer>
            <Table className={classes.table} aria-label="simple table">
              <colgroup>
                <col style={{ width: "1%" }} />
                <col style={{ width: "4%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "10%" }} />
              </colgroup>
              <TableHead>
                <TableRow className={classes.thead}>
                  <StyledTableCell align="center">#</StyledTableCell>
                  <StyledTableCell align="center">Date</StyledTableCell>
                  <StyledTableCell align="center">Team</StyledTableCell>
                  <StyledTableCell align="center">Name</StyledTableCell>
                  <StyledTableCell align="center">Check In</StyledTableCell>
                  <StyledTableCell align="center">Check Out</StyledTableCell>
                  <StyledTableCell align="center">Note</StyledTableCell>
                  <StyledTableCell align="center">Summary Hours</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {timeAtts
                  .map((timeAtt, key) => {
                    return <TimeAttendancesRow timeAtt={timeAtt} no={key} />;
                  })
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={timeAtts.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      )}
    </>
  );
}
export default TimeAttendancesTable;
