import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { useEffect, useRef, useState } from "react";
import useReportAPI from "../../hook/useReportAPI";
import NotificationRows from "./NotificationRows";
import useEmailNotify from "../../hook/useEmailNotify";
import clsx from "clsx";
import { green } from "@material-ui/core/colors";
import DateFnsUtils from "@date-io/date-fns";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import useAttendanceAPI from "../../hook/useAttendanceAPI";
import TeamInfo from "../../models/TeamInfo";
import moment from "moment";
import EmployeeIsActiveTeam from "../../models/EmployeeIsActiveTeam";
import EmailNotification from "../../models/EmailNotification";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    root1: {
      width: "93%",
      margin: "auto",
    },
    table: {
      minWidth: 650,
    },
    updateButton: {
      color: "#ffc107",
      borderColor: "#ffc107",
    },
    addAnnBtn: {
      textAlign: "center",
      marginBottom: "25px",
    },
    title: {
      marginTop: "3%",
      marginLeft: "2%",
      marginBottom: "2%",
    },
    img: {
      objectFit: "contain",
      height: "250px",
      maxWidth: "100%",
    },
    button: {
      margin: theme.spacing(1),
    },
    affected: {
      textAlign: "right",
      margin: theme.spacing(1),
      position: "relative",
    },
    send: {
      background: "white",
    },
    notsend: {
      background: "#E3E3E3",
    },
    buttonSuccess: {
      backgroundColor: green[500],
      "&:hover": {
        backgroundColor: green[700],
      },
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "50%",
      height: "80%",
      overflow: "auto",
    },
  })
);

function Notification() {
  const classes = useStyles();

  const [, , , , getTeams, teams, , , , , , , , , , , , , , ,] = useReportAPI();

  const [
    insertEmailLog,
    getEmailNotify,
    emailNotify,
    sendEmailNotify,
    checkFails,
    setCheckFails,
    loadingAll,
  ] = useEmailNotify();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);

  const [openModel, setOpenModel] = useState(false);

  const handleClose = () => {
    setOpenModel(false);
    setCheckFails([]);
    setCheck(false);
  };

  useEffect(() => {
    getEmailNotify(
      moment(new Date()).format("YYYY-MM-DDT00:00:00"),
      moment(new Date()).format("YYYY-MM-DDT23:59:00"),
      0,
      0,
      false
    );
    // change
    //getEmailNotifyLog(0, moment(new Date()).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"))
  }, [getEmailNotify]); //getEmailNotifyLog

  const timer = useRef<number>();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const [openConfirmDialogFails, setOpenConfirmDialogFails] =
    useState<boolean>(false);

  const displayConfirmDialogFails = () => {
    setOpenConfirmDialogFails(true);
  };

  const displayConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const DEFAULT_STARTDATE_WORKHOURS = new Date();
  const DEFAULT_ENDDATE_WORKHOURS = new Date();
  const [startDate, setStartDate] = useState<Date>(DEFAULT_STARTDATE_WORKHOURS);
  const [endDate, setEndDate] = useState<Date>(DEFAULT_ENDDATE_WORKHOURS);

  const [team, setTeam] = useState<number>(0);
  const [emp, setEmp] = useState<number>(0);

  const [page, setPage] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setChangePage(true);
  };

  const [checked, setChecked] = useState(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const [check, setCheck] = useState(false);

  const keepLog = (insert: EmailNotification[]) => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(async () => {
        await sendEmailNotify(insert);
        setSuccess(true);
        setLoading(false);
        await setCheck(true);
      });
    }
  };

  useEffect(() => {
    if (check && checkFails.length >= 1) {
      setOpenModel(true);
    }
    if (checkFails.length < 1) {
      setOpenModel(false);
      setCheck(false);
    }
  }, [setOpenModel, check, checkFails]);

  const selectStartDateTime = (date: any) => {
    setStartDate(new Date(date));
  };
  const selectEndDateTime = (date: any) => {
    setEndDate(new Date(date));
  };

  const a = useRef<number>(0);

  var empIsActiveTeam: EmployeeIsActiveTeam[] = [];

  const [empTeam, setempTeam] = useState<TeamInfo[]>([]);

  const [
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    getEmployeeIsActiveTeam,
    employeesIsActiveTeam,
  ] = useAttendanceAPI();

  useEffect(() => {
    // getWorkHours(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DDT23:59:59"), 0, 0)
    getTeams();
    getEmployeeIsActiveTeam();
  }, [getTeams, getEmployeeIsActiveTeam]);

  const [changePage, setChangePage] = useState(false);

  const onClick = () => {
    getEmailNotify(
      moment(startDate).format("YYYY-MM-DDT00:00:00"),
      moment(endDate).format("YYYY-MM-DDT23:59:00"),
      emp,
      team,
      checked
    );
    setPage(0);
    //getEmailNotifyLog(emp, moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"))
  };
  return (
    <>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={openModel}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModel}>
          <div className={classes.paper}>
            <Grid container direction="row" justify="space-between">
              <h2 id="transition-modal-title">Email Failed</h2>
              <div className={classes.affected}>
                <Button
                  style={{ color: "white" }}
                  variant="contained"
                  color="secondary"
                  className={buttonClassname}
                  disabled={loading}
                  onClick={displayConfirmDialogFails}
                  startIcon={<NotificationsIcon />}
                >
                  Notification All
                </Button>
                {loading && (
                  <Backdrop className={classes.backdrop} open={loading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
              </div>
            </Grid>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <colgroup>
                  <col style={{ width: "10%" }} />
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "30%" }} />
                  <col style={{ width: "30%" }} />
                </colgroup>
                <TableHead
                  style={{ backgroundColor: "#E3E3E3", textAlign: "center" }}
                >
                  <TableRow>
                    <TableCell style={{ textAlign: "center" }}>#</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Name</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Send Date
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Absence Date
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody style={{ backgroundColor: "#F5F5F5" }}>
                  {checkFails?.map((e, key) => {
                    return (
                      <TableRow>
                        <TableCell component="th" scope="row" align="center">
                          {key + 1}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {e.nickName}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {e.firstName} {e.lastName}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {moment(e.date).format("DD/MM/YYYY")}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Fade>
      </Modal>
      <div className={classes.root}>
        <Grid className={classes.title}>
          <Grid container spacing={1}>
            <Grid item>
              <NotificationsIcon fontSize="large" />
            </Grid>
            <Grid item xs zeroMinWidth style={{ margin: "auto" }}>
              <Typography variant="h4" noWrap>
                NOTIFICATION
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid style={{ marginLeft: "5%" }}>
          <Grid
            container
            spacing={3}
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
          >
            <Grid item xs={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="dd/MM/yyyy"
                    value={startDate ? startDate : null}
                    onChange={selectStartDateTime}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    required
                    maxDate={new Date()}
                    maxDateMessage="Date should not be after maximal date"
                    fullWidth={true}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>

            <Grid item xs={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="End Date"
                    format="dd/MM/yyyy"
                    value={endDate ? endDate : null}
                    onChange={selectEndDateTime}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                    required
                    minDate={startDate ? startDate : null}
                    maxDate={new Date()}
                    maxDateMessage="Date should not be after maximal date"
                    fullWidth={true}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                id="combo-box-demo"
                options={employeesIsActiveTeam}
                getOptionLabel={(option) => option.nickName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employee Name"
                    variant="outlined"
                    value={emp}
                    style={{ background: "white" }}
                  />
                )}
                renderOption={(option) => (
                  <>
                    {option.nickName} {option.firstName}
                  </>
                )}
                onChange={(e, val) => {
                  let team: TeamInfo[] = [];
                  team.push();
                  setempTeam(team);
                  if (val !== null) {
                    setEmp(val.employeeId);
                    a.current = val.employeeId;
                    empIsActiveTeam = employeesIsActiveTeam.filter(
                      (e) => e.employeeId === val.employeeId
                    );
                    empIsActiveTeam.forEach((val, index: number) => {
                      val.projectList.forEach((element) => {
                        team.push({
                          projectId: element.projectId,
                          projectName: element.projectName,
                        });
                        setempTeam(team);
                      });
                    });
                  } else {
                    setEmp(0);
                    a.current = 0;
                  }
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                id="combo-box-demo"
                options={emp === 0 ? teams : empTeam}
                getOptionLabel={(option) => option.projectName}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Name"
                    variant="outlined"
                    value={team}
                    style={{ background: "white" }}
                  />
                )}
                renderOption={(option) => <>{option.projectName}</>}
                onChange={(e, val) => {
                  if (val !== null) {
                    setTeam(val.projectId);
                  } else {
                    setTeam(0);
                  }
                }}
              />
            </Grid>
            <Grid direction="row" justify="center" alignItems="center">
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleChange}
                          name="checkFail"
                        />
                      }
                      label="Check Fails"
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <Button fullWidth={true} variant="contained" onClick={onClick}>
                <SearchIcon />
              </Button>
            </Grid>
          </Grid>
          <div className={classes.affected}>
            <Button
              variant="contained"
              color="secondary"
              className={buttonClassname}
              disabled={loading}
              onClick={displayConfirmDialog}
              startIcon={<NotificationsIcon />}
            >
              Notification All
            </Button>
            {loading && (
              <Backdrop className={classes.backdrop} open={loading}>
                <CircularProgress color="inherit" />
              </Backdrop>
            )}
          </div>

          <Paper>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell></StyledTableCell>
                    <StyledTableCell>#</StyledTableCell>
                    <StyledTableCell>Team</StyledTableCell>
                    <StyledTableCell>NickName</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>Date Absences</StyledTableCell>
                    <StyledTableCell>Notify</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {loadingAll ? (
                    <>
                      <Backdrop className={classes.backdrop} open={loadingAll}>
                        <CircularProgress color="inherit" />
                      </Backdrop>
                    </>
                  ) : (
                    <>
                      {emailNotify
                        ?.map((e, key) => {
                          return (
                            <NotificationRows
                              index={key + 1}
                              notify={e}
                              //selectemailNotifyLog={selectemailNotifyLog}
                              insertEmailLog={insertEmailLog}
                              successAll={loading}
                              sendEmailNotify={sendEmailNotify}
                              checkFails={checkFails}
                              changePage={changePage}
                              setChangePage={setChangePage}
                            />
                          );
                        })
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={emailNotify.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <ConFirmDialog
          title="Send Email"
          description="Do you want to send email ?"
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          actionDialog={() => keepLog(emailNotify)}
        />

        <ConFirmDialog
          title="Send Email"
          description="Do you want to send email is failed ?"
          open={openConfirmDialogFails}
          setOpen={setOpenConfirmDialogFails}
          actionDialog={() => keepLog(checkFails)}
        />
      </div>
    </>
  );
}

export default Notification;
