import { useCallback, useState } from "react"
import KeepLoginMachineLogApi from "../api/KeepLoginMachineLogAPI"
import KeepLoginMachineLog from "../models/KeepLoginMachineLog"

const useKeepLoginMachineLog = () => {
    const [loginLog, setLoginLog] = useState<KeepLoginMachineLog>()

    const insertKeepLoginMachineLog = useCallback(async (log: KeepLoginMachineLog) => {
        try{
            const insertLog = await KeepLoginMachineLogApi.postKeepLoginMachineLog(log)
            setLoginLog(insertLog.data)
        }catch(err){
        }
    },[setLoginLog])

    return [insertKeepLoginMachineLog, loginLog] as const
}

export default useKeepLoginMachineLog