
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import { useEffect, useState } from "react";
import PieChartCustom from "../../Chart/PieChartCustom";
import BarChartCustom from "./BarChartCustom";
import CardCustom from "./CardCustom";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import StatisticReport from "../../../models/StatisticReport";
import PieChartRatio from "../../../models/PieChartRatio";
import BarChart from "../../../models/Barchart";
import EqualizerIcon from '@material-ui/icons/Equalizer';

interface IStatistic {
  loadingStatistic: boolean;
  getStatistics: any;
  statisticReport?: StatisticReport;
  ratioEmpWorks: PieChartRatio[];
  datasBarchart: BarChart[];
}

const useStyles = makeStyles({
  root: {
    marginTop: '1%'
  },
  title: {
    margin: "1% 0% 1% 0%"
  },
  statisticContainer: {
    margin: "2% 0% 2% 0%"
  },
  barchartContainer: {
    marginTop: "2%"
  },
  stardDardCard: {
    height: '200px',
    backgroundColor: '#2D2E33',
    color: 'white'
  },
  avgWorkCard: {
    height: '200px',
    backgroundColor: '#FFAB00',
    color: 'white'
  },
  avgTimeInCard: {
    height: '200px',
    backgroundColor: '#6EC1B2',
    color: 'white'
  },
  avgTimeOutCard: {
    height: '200px',
    backgroundColor: '#FF7452',
    color: 'white'
  },
})

function Statistic({
  loadingStatistic,
  getStatistics,
  statisticReport,
  ratioEmpWorks,
  datasBarchart
}: IStatistic) {
  const classes = useStyles();

  const [startDate, setStartDate] = useState<any>(moment().subtract(1, "days").format("YYYY-MM-DDT00:00:00"))
  const [endDate, setEndDate] = useState<any>(moment().subtract(1, "days").format("YYYY-MM-DDT23:59:00"))

  useEffect(() => {
    getStatistics(moment().subtract(1, "days").format("YYYY-MM-DDT00:00:00"), moment().subtract(1, "days").format("YYYY-MM-DDT23:59:00"));
  }, [getStatistics]);


  return (
    <>
      <Grid
        container
        spacing={2}
        xs={12}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={5} >
          <Typography variant="h4" className={classes.title} >
            <EqualizerIcon />
            STATISTICS
          </Typography>
          <Box>
            {moment(startDate).format("LL")} -
            {moment(endDate).format("LL")}
          </Box>
        </Grid>
        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Start Date"
              format="dd/MM/yyyy"
              value={startDate ? startDate : null}
              onChange={(date: any) => {
                setStartDate(moment(new Date(date)).format("YYYY-MM-DDT00:00:00"))
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              required
              maxDate={new Date()}
              //maxDateMessage="Date should not be after maximal date"
              fullWidth={true}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={3}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="End Date"
              format="dd/MM/yyyy"
              value={endDate ? endDate : null}
              onChange={(date: any) => {
                setEndDate(moment(new Date(date)).format("YYYY-MM-DDT23:59:59"))
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              required
              minDate={startDate ? startDate : null}
              maxDate={new Date()}
              maxDateMessage="Date should not be after maximal date"
              fullWidth={true}
            />
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={1}>
          <Button fullWidth={true} variant="contained" onClick={() => {
            getStatistics(startDate, endDate)
          }}>
            <SearchIcon />
          </Button>
        </Grid>

      </Grid>

      {loadingStatistic ? (
        <>
          <Grid container
            justify="center"
            alignItems="center">
            <CircularProgress></CircularProgress>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            xs={12}
            direction="row"
            justify="center"
            alignItems="center"
            className={classes.statisticContainer}
          >
            <Grid item xs={6}>
              <Grid container item direction="column">
                <Grid item xs>
                  <Grid container spacing={3} item direction="row" xs={12}>
                    <Grid item xs={6}>
                      <CardCustom
                        title="Standard Work Hours"
                        value={
                          statisticReport
                            ? statisticReport?.standardWorkHours
                            : "--"
                        }
                        theme={classes.stardDardCard}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <CardCustom
                        title="Average Work Hours"
                        value={
                          statisticReport
                            ? statisticReport?.avgWorkHours.toFixed(2)
                            : "--"
                        }
                        theme={classes.avgWorkCard}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} item direction="row" xs={12}>
                    <Grid item xs>
                      <CardCustom
                        title="Average Time In"
                        value={
                          statisticReport?.avgTimeIn !== null
                            ? moment(statisticReport?.avgTimeIn).format("LT")
                            : "--"
                        }
                        theme={classes.avgTimeInCard}
                      />
                    </Grid>
                    <Grid item xs>
                      <CardCustom
                        title="Average Time Out"
                        value={
                          statisticReport?.avgTimeOut !== null
                            ? moment(statisticReport?.avgTimeOut).format("LT")
                            : "--"
                        }
                        theme={classes.avgTimeOutCard}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Paper>
              <Grid item xs={6}>
                <PieChartCustom
                  title="Work Hours"
                  datas={ratioEmpWorks}
                  value="workHour"
                  argumentField="label"
                  height={400}
                />
              </Grid>
            </Paper>
          </Grid>
          <Grid container xs={12} spacing={2} direction="row" justify="center" className={classes.barchartContainer}>
            <BarChartCustom datas={datasBarchart} title="Average Time Attendance" />
          </Grid>
        </>
      )}
    </>
  );
}

export default Statistic;
