import moment from "moment";
import {
    TableRow,
    TableCell,
    Button,
    TextField,
    Grid,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DateFnsUtils from "@date-io/date-fns";
import {
    KeyboardDatePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import AttendanceWFH from "../../models/AttendanceWFH";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditorConfig } from "../../helper/ckEditorConfig";
import { unescape } from 'html-escaper';
import "./rte.css";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            maxWidth: 1200
        },
        container: {
            display: "flex",
            flexWrap: "wrap",
        },
        //textField: {
        //    marginLeft: theme.spacing(1),
        //    marginRight: theme.spacing(1),
        //    width: 200,
        //},
        button: {
            margin: theme.spacing(1),
        },
    })
);

interface IEditTimeAttendance {
    index: number;
    attend: AttendanceWFH;
    editWFHAttendance: any;
    enableEditModeAndResendEmail: any;
}

function EditTimeAttendanceWFH({
    index,
    attend,
    editWFHAttendance,
    enableEditModeAndResendEmail
}: IEditTimeAttendance) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const DEFAUT_DATE_TIMEIN = new Date(attend.checkIn)
    const DEFAUT_DATE_TIMEOUT = attend.checkOut ? new Date(attend.checkOut) : null

    const [workDate, setWorkDate] = useState<Date | null>(DEFAUT_DATE_TIMEIN);
    const [timeIn, setTimeIn] = useState<Date | null>(DEFAUT_DATE_TIMEIN);
    const [timeOut, setTimeOut] = useState<Date | null>(DEFAUT_DATE_TIMEOUT);
    const [hours, setHours] = useState<any>();
    const [hoursText, setHoursText] = useState<string>();
    const [inOutDetails, setInOutDetails] = useState<string>();

    useEffect(() => {
        const DEFAUT_DATE_TIMEIN = new Date(attend.checkIn);
        const DEFAUT_DATE_TIMEOUT = attend.checkOut ? new Date(attend.checkOut) : null;

        setTimeIn(DEFAUT_DATE_TIMEIN);
        setTimeOut(DEFAUT_DATE_TIMEOUT);
        setHours(attend.hours);
        setHoursText(attend.hoursText);
        setInOutDetails(unescape((attend && attend.inOutDetail) ? attend.inOutDetail : ""));
    }, [attend, attend.hours, attend.hoursText, attend.checkIn, attend.checkOut, attend.inOutDetail])

    const selectTimeIn = (date: Date | null) => {
        if (date) {
            setTimeIn(new Date(date));
        }
    };

    const selectTimeOut = (date: Date | null) => {
        if (date) {
            setTimeOut(new Date(date))
        }
    };

    const convertDate_2_Datetime = (
        date: Date | null,
        time: Date | null
    ): string | null => {
        if (date === null || time === null) {
            return null;
        } else {
            return (
                date.getFullYear() +
                "-" +
                leftpad(date.getMonth() + 1, 2) +
                "-" +
                leftpad(date.getDate(), 2) +
                "T" +
                leftpad(time.getHours(), 2) +
                ":" +
                leftpad(time.getMinutes(), 2) +
                ":" +
                leftpad(time.getSeconds(), 2)
            );
        }
    };

    const leftpad = (val: any, resultLength = 2, leftpadChar = "0"): string => {
        return (String(leftpadChar).repeat(resultLength) + String(val)).slice(
            String(val).length
        );
    };

    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

    const editTimeAttendance = () => {
        // edit data
        if ((workDate !== null) && (timeOut !== null && timeIn !== null)) {
            if (Number(moment(timeIn).format("HH.mm")) <= Number(moment(timeOut).format("HH.mm"))) {
                editWFHAttendance(
                    attend.wfh_Id,
                    convertDate_2_Datetime(new Date(workDate), timeIn),
                    convertDate_2_Datetime(new Date(workDate), timeOut),
                    hours,
                    hoursText,
                    inOutDetails
                )
            }
            else {
                setWarning(true);
            }
        }
        else {
            setWarning(true);
        }
    };

    const displayConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const [warning, setWarning] = useState<boolean>(false);

    const warningClose = () => {
        setWarning(false);
    };

    const handleWorkDateChange = (date: any) => {
        var dateVal = new Date(date);
        setWorkDate(dateVal);
    };

    const handleTimeWorkHourInput = (evt: any) => {
        const newValue = evt.target.value;
        if (newValue <= 24) {
            setHours(newValue);
        }
    };

    const sanitizeNewLine = (value: string) => {
        return value.replaceAll(/\\n/g, "\r\n");
    }

    const enableEditModeAndResendEmailHandle = () => {
        if ((attend && attend.wfh_Id) && (workDate !== null)) {
            //Ingore timezone
            var workDateFormatted = moment(new Date(workDate)).format('YYYY-MM-DDT00:00:00');
            enableEditModeAndResendEmail(attend.wfh_Id, attend.employeeId, workDateFormatted);
        }
    }

    return (
        <>
            <TableRow>
                <TableCell component="th" scope="row" align="center">
                    {index}
                </TableCell>
                <TableCell align="center">
                    {moment(attend.checkIn).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell align="center">{attend.employeeName}</TableCell>
                <TableCell align="center">
                    {moment(attend.checkIn).format("HH:mm")}
                </TableCell>
                <TableCell align="center">
                    {attend.checkOut === null && <>-- </>}
                    {attend.checkOut !== null && moment(attend.checkOut).format("HH:mm")}
                </TableCell>
                <TableCell align="center">
                    {
                        attend && attend.hoursText && attend.hoursText.toLowerCase() === "sync from task tracker"
                            ? `${attend.note} (${attend.hoursText})`
                            : attend.note
                    }
                </TableCell>
                <TableCell align="center">
                    {attend.hours !== null ? (
                        <>{Number(attend.hours).toFixed(2)}</>
                    ) : (
                        <>--</>
                    )}
                </TableCell>
                <TableCell align="center">
                    <Button variant="outlined" color="primary" onClick={handleOpen}>
                        <EditIcon />
                    </Button>
                </TableCell>
            </TableRow>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper} >
                        <h2 id="transition-modal-title">{attend.employeeName}</h2>
                        <h3 id="transition-modal-title">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label="Work Date"
                                    placeholder="dd/MM/yyyy"
                                    format={"dd/MM/yyyy"}
                                    value={workDate}
                                    maxDate={new Date()}
                                    onChange={handleWorkDateChange}
                                    required />
                            </MuiPickersUtilsProvider>
                        </h3>
                        <form className={classes.container} noValidate>
                            <Grid
                                container
                                spacing={3}
                                direction="row"
                                justify="center"
                                alignItems="center"
                            >
                                <Grid item xs={12} style={{ maxWidth: 1200 }}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <Grid
                                            container
                                            justify="space-around"
                                            style={{ background: "white", marginBottom: "15px" }}
                                        >
                                            <KeyboardTimePicker
                                                margin="normal"
                                                label="Time In"
                                                value={timeIn}
                                                onChange={selectTimeIn}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change time",
                                                }}
                                                required
                                                ampm={false}
                                            />
                                            <KeyboardTimePicker
                                                margin="normal"
                                                label="Time Out"
                                                value={timeOut ? timeOut : null}
                                                onChange={selectTimeOut}
                                                KeyboardButtonProps={{
                                                    "aria-label": "change time",
                                                }}
                                                format="HH:mm"
                                                ampm={false}
                                            />
                                            <TextField
                                                margin="normal"
                                                label="Total Time"
                                                value={hours}
                                                onChange={handleTimeWorkHourInput}
                                                inputProps={{ maxLength: 5 }}
                                                id="formatted-numberformat-input"
                                            />
                                        </Grid>
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item xs={12} style={{ maxWidth: 1200 }}>
                                    <TextField
                                        id="outlined-helperText"
                                        label="Hours Text"
                                        value={hoursText}
                                        onChange={(e) => {
                                            setHoursText(e.target.value);
                                        }}
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ maxWidth: 1200 }}>
                                    <div className="custom-rte custom-rte-edit">
                                        <CKEditor
                                            editor={ClassicEditor}
                                            config={CKEditorConfig}
                                            data={sanitizeNewLine(inOutDetails ? inOutDetails : "")}
                                            onChange={(event: any, editor: any) => {
                                                if (editor) {
                                                    const data = editor.getData();
                                                    setInOutDetails(data);
                                                }
                                            }}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={12} style={{ maxWidth: 1200, display: "flex", alignItems: "center", justifyContent: "center" }}>
                                    {
                                        attend && attend.hoursText && attend.hoursText.toLowerCase() === "sync from task tracker"
                                            ? (
                                                <Button
                                                    type="button"
                                                    variant="contained"
                                                    size="small"
                                                    className={classes.button}
                                                    startIcon={<EditIcon />}
                                                    onClick={enableEditModeAndResendEmailHandle}>
                                                    Enable edit mode & Resend email
                                                </Button>
                                            )
                                            : ""
                                    }
                                    <Button
                                        type="button"
                                        variant="contained"
                                        color="primary"
                                        size="small"
                                        className={classes.button}
                                        startIcon={<SaveIcon />}
                                        onClick={displayConfirmDialog}>
                                        Save
                                    </Button>
                                </Grid>
                            </Grid>
                            <Snackbar
                                open={warning}
                                autoHideDuration={6000}
                                onClose={warningClose}
                            >
                                <Alert onClose={warningClose} severity="warning">
                                    check out time should be more than check in time
                                </Alert>
                            </Snackbar>

                            <ConFirmDialog
                                title="Save TimeAttendance"
                                description="Do you want to save timeattendance ?"
                                open={openConfirmDialog}
                                setOpen={setOpenConfirmDialog}
                                actionDialog={editTimeAttendance}
                            />
                        </form>
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
export default EditTimeAttendanceWFH;
