import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  makeStyles,
  createStyles,
  withStyles,
  TableCell,
  Container,
} from "@material-ui/core";
import moment from "moment";
import React, { useEffect } from "react";
import EmployeeLeave from "../../models/EmployeeLeave";
import TimeEmployeeLeaveRows from "./TimeEmployeeLeaveRows";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    thead: {
      background: "#25272E",
    },
    tbody: {
      background: "white",
    },
  })
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "white",
    textAlign: "center",
  },
}))(TableCell);

interface IDashBoardLeaveEmployee {
  employeesLeave: EmployeeLeave[] | undefined;
  loading: boolean;
  getEmployeesLeave: any;
  refresh: Date;
}

function DashBoardLeaveEmployee({
  employeesLeave,
  loading,
  getEmployeesLeave,
  refresh,
}: IDashBoardLeaveEmployee) {
  const classes = useStyles();

  useEffect(() => {
    // set date
    getEmployeesLeave(moment(new Date()).format("YYYY-MM-DD"));
  }, [refresh, getEmployeesLeave]);

  return (
    <Container maxWidth="lg">
      {loading ? (
        <>Loading ...</>
      ) : (
        <>
          {employeesLeave?.length !== 0 ? (
            <>
              <TableContainer className={classes.root}>
                <Table>
                  <colgroup>
                    <col style={{ width: "5%" }} />
                    <col style={{ width: "5%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "10%" }} />
                    <col style={{ width: "5%" }} />
                    <col style={{ width: "15%" }} />
                    <col style={{ width: "20%" }} />
                    <col style={{ width: "30%" }} />
                  </colgroup>
                  <TableHead>
                    <TableRow className={classes.thead}>
                      <StyledTableCell>#</StyledTableCell>
                      <StyledTableCell>Name</StyledTableCell>
                      <StyledTableCell>DateLeave</StyledTableCell>
                      <StyledTableCell>DateLeaveto</StyledTableCell>
                      <StyledTableCell>LeaveAmount</StyledTableCell>
                      <StyledTableCell>Subject</StyledTableCell>
                      <StyledTableCell>Description</StyledTableCell>
                      <StyledTableCell>Note</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody className={classes.tbody}>
                    {employeesLeave?.map((e, key) => {
                      return (
                        <TimeEmployeeLeaveRows index={key + 1} empLeave={e} />
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            <>Not Found Leaves Employee</>
          )}
        </>
      )}
    </Container>
  );
}

export default DashBoardLeaveEmployee;
