import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Grid } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

interface IInvalidModal {
  open: boolean;
  handleClose: any;
  invalid_data: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "25%",
      height: "75%",
      overflow: "auto",
    },
  })
);

function InvalidModal({ open, handleClose, invalid_data }: IInvalidModal) {
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Grid className={classes.paper}>

            <Grid container direction="row" justify="space-between">
                <h2 id="transition-modal-title">Invalid data</h2>
                <Button onClick={handleClose}><CloseIcon/></Button>
            </Grid>

            <Grid container justify="center">
              <p id="transition-modal-description">
                Please check id
                {invalid_data.map((i: any) => {
                  return (
                    <ul>
                      <li>{"ID = " + i.id + " : " + i.email}</li>
                    </ul>
                  );
                })}
              </p>
            </Grid>
          </Grid>
        </Fade>
      </Modal>
    </div>
  );
}

export default InvalidModal;
