import { Button, createStyles, Grid, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme, Typography, withStyles } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import Autocomplete  from '@material-ui/lab/Autocomplete';
import { useEffect, useState } from 'react';
import EmployeeAbsences from '../../models/EmployeeAbsences';
import EmployeeIsActive from '../../models/EmployeeIsActive';
import EmployeeAbsencesTableRow from './EmployeeAbsencesRow';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: "1%"
    },
    table_contianer: {
      width: "100%",
      paddingBottom: "1%"
    },
    thead: {
      background: "#25272E",
    },
    tbody: {
      background: "white",
    },
    title: {
      marginTop: "3%",
      marginLeft: "2%",
      marginBottom: "2%",
    },
    padding: {
      paddingBottom: "10pt"
    }
  })
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "white",
    textAlign: "center",
  },
}))(TableCell);

interface EmployeeAbsencesTableProps {
  abs: EmployeeAbsences[];
  loading: boolean;
  addAtt: any;
  refreshData: any;
  employees: EmployeeIsActive[];
}

function EmployeeAbsencesTable({ abs, loading, addAtt, refreshData, employees }: EmployeeAbsencesTableProps) {

  const classes = useStyles()
  const [empAttId, setEmpAttId] = useState<string>()
  const [absences, setAbsences] = useState<EmployeeAbsences[]>(abs)

  useEffect(() => {
    if (empAttId !== undefined) {
      setAbsences(abs.filter(e => e.attendId === empAttId))
    } else {
      setAbsences(abs)
    }
  }, [empAttId, abs])

  return (
    <div className={classes.root} >
      <Grid container spacing={1} >
        <Grid item>
          <PermIdentityIcon fontSize="large" />
        </Grid>
        <Grid item xs zeroMinWidth style={{ margin: "auto" }}>
          <Typography variant="h5" noWrap>
            Employee Absences
          </Typography>
        </Grid>
        <a href="#att-table" style={{ textDecorationLine: "none" }}><Button><ArrowUpwardIcon style={{ marginRight: "5px" }} />Go UP</Button> </a>
      </Grid>

      <Autocomplete
        id="combo-box-demo"
        options={employees}
        getOptionLabel={(option) => option.nickName}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Name"
            variant="outlined"
            value={empAttId}
            style={{ background: "white" }}
          />
        )}
        renderOption={(option) => (
          <>
            {option.nickName} {option.firstName}
          </>
        )}
        onChange={(e, val) => {
          if (val !== null) {
            setEmpAttId(val.attendId)
          } else {
            setEmpAttId(undefined)
          }
        }}
      />

      <TableContainer className={classes.table_contianer}>
        <Table>
          <TableHead>
            <TableRow className={classes.thead}>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Date</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Add Time Attendance</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody className={classes.tbody}>
            {loading ?
              <>
                Loading ...
              </>
              :
              <>
                {absences.map((e, key) => {
                  return (<EmployeeAbsencesTableRow index={key + 1} empAbs={e} checkIn={addAtt} refreshData={refreshData} />)
                })}
              </>
            }
          </TableBody>
        </Table>
      </TableContainer>

    </div>
  )
}

export default EmployeeAbsencesTable