import { useEffect, useState } from "react";
import useAnnounceApi from "../../hook/useAnnounceAPI";
import AddAnnounce from "../AddAnnounce/AddAnnounce";
import AnnounceDetail from "./AnnounceDetail";
import "./Announce.css";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { LinearProgress } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    root1: {
      width: "93%",
      margin: "auto"
    },
    table: {
      minWidth: 650,
    },
    updateButton: {
      color: "#ffc107",
      borderColor: "#ffc107"
    },
    addAnnBtn: {
      textAlign: "center",
      marginBottom: "25px"
    },
    title: {
      marginTop: "3%",
      marginLeft: "2%",
      marginBottom: "2%"
    },
    img: {
      objectFit: "contain",
      height: "250px",
      maxWidth: "100%"
    }
  })

);

function Announce() {

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  //////////////////////////////////////////////// MATERIAL UI ////////////////////////////////////////////

  const [announce, post_announce, get_announce, put_announce, delete_announce, loading, success] = useAnnounceApi();
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    get_announce();
  }, [get_announce]);

  const [baseImage, setBaseImage] = useState<any>("");
  const [preImg, setPreImg] = useState<any>()

  return (

    <>
      <div className={classes.root}>


        <Grid className={classes.title}>
          <Grid container spacing={1}>
            <Grid item>
              <AnnouncementIcon fontSize="large" />
            </Grid>
            <Grid item xs zeroMinWidth style={{ margin: "auto" }}>
              <Typography variant="h4" noWrap>
                ANNOUNCEMENT
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <div className={classes.addAnnBtn}>
          <Fab variant="extended" color="primary" onClick={() => setOpen((pre) => !pre)}>
            <AddIcon />
            ADD NEW ANNOUNCEMENT
          </Fab>
        </div>

        {open && (
          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
              setPreImg("")
            }}
            
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <AddAnnounce
              baseImage={baseImage}
              post_announce={post_announce}
              setBaseImage={setBaseImage}
              success={success}
              setPreImg={setPreImg}
              preImg={preImg}
            />
          </Modal>
        )}

        <div>
          <Paper className={classes.root}>
            {loading &&
              <LinearProgress></LinearProgress>
            }
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <colgroup>
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '30%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                  <col style={{ width: '10%' }} />
                </colgroup>
                <TableHead>
                  <TableRow >
                    <TableCell align="center" >#</TableCell>
                    <TableCell align="center" >Image</TableCell>
                    <TableCell align="center" >Text Marquee</TableCell>
                    <TableCell align="center" >Show Text Marquee</TableCell>
                    <TableCell align="center" >Update</TableCell>
                    <TableCell align="center" >Delete</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody >
                  {announce

                    .map((ann, key) => {
                      return (
                        <AnnounceDetail
                          ann={ann}
                          baseImage={baseImage}
                          put_announce={put_announce}
                          delete_announce={delete_announce}
                          classes={classes}
                          no={key}
                          setBaseImage={setBaseImage}
                          success={success}
                        />
                      );
                    })
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

                  }
                </TableBody>

              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={announce.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>

        </div>

      </div>

    </>
  );
}

export default Announce;
