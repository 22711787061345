import HttpRequest from "../helper/httpRequest"
import AttendanceWFH from "../models/AttendanceWFH";

const API_URL = process.env.REACT_APP_API_URL

const httpRequest = new HttpRequest(`${API_URL}`);


const wfhAttendApi = {
    getAttendanceWFH: (date1: any, date2: any) => {
        return httpRequest.get<AttendanceWFH[]>(API_URL + "api/AttendanceWFH/" + date1 + "/" + date2)
    },
    editAttendanceWFH: (count: number, checkin: any, checkout: any, hours: string, hoursText: string, inoutDetails: string) => {
        return httpRequest.put<AttendanceWFH>(`${API_URL}api/AttendanceWFH/EditAttendanceWFH`, null, {
            params: {
                count: count,
                checkin: checkin,
                checkout: checkout,
                Hours: hours,
                HoursText: hoursText,
                inOutDetails: inoutDetails
            }
        })
    },

    //Import Excel
    insertExcel: (data: AttendanceWFH[]) => {
        return httpRequest.post<AttendanceWFH[]>(`${API_URL}api/Report/WorkFormHome`, data)
    },
    //Import Excel
    insertEmp: (data: AttendanceWFH) => {
        return httpRequest.post<AttendanceWFH>(`${API_URL}api/AttendanceWFH/AddAttendanceWFHValidation`, data)
    },
    enableEditModeAndResendEmail: (wfhId: number, empId: number, workDate: any) => {
        return httpRequest.post<boolean>(`${API_URL}api/AttendanceWFH/EnableEditModeAndResendEmail`, null, {
            params: {
                wfhId: wfhId,
                empId: empId,
                workDate: workDate
            }
        })
    }
}

export default wfhAttendApi
