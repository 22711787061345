import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

interface IAlertSuccess {
  msg: string;
  open: boolean;
  setOpen: any;
  dateDefault: string
  setTimeIn: any;
  setTimeOut: any;
  setEmpEmail: any;
  setJobTask: any;
  setProject: any;
  setHours: any;
  setHoursText: any;
  handleClose: any;
}

function AlertSuccessAddWFH({
  msg,
  open,
  setOpen,
  dateDefault,
  setTimeIn,
  setTimeOut,
  setEmpEmail,
  setJobTask,
  setProject,
  setHours,
  setHoursText,
  handleClose
}: IAlertSuccess) {
  const onClose = () => {
    setOpen(false);
  };
  
  setTimeIn(dateDefault);
  setTimeOut(dateDefault)
  setEmpEmail("")
  setJobTask("")
  setProject("")
  setHours(null)
  setHoursText("")
  
  handleClose()

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000} onClose={onClose}>
        <Alert onClose={onClose} severity="success">
          {msg}
        </Alert>
      </Snackbar>

    </>
  );
}

export default AlertSuccessAddWFH;
