import {
    Card,
    CardContent,
    Typography,

} from "@material-ui/core"
import {
    makeStyles,
} from "@material-ui/styles"


interface ICardCustom {
    title: string
    value?: any
    theme?: any
}

const useStyles = makeStyles({
    root: {
        minWidth: 250
    },
    title: {
        fontSize: 20
    },
    value: {
        fontSize: 50
    }
})

function CardCustom({ title, value, theme }: ICardCustom) {

    const classes = useStyles();

    return (

        <Card className={theme} variant="outlined">
            <CardContent>
                <Typography className={classes.title}>
                    {title}
                </Typography>
                <Typography className={classes.value}>
                    {value}
                </Typography>
            </CardContent>
        </Card>

    )
}

export default CardCustom