import {
    Chart,
    PieSeries,
    Legend,
    Title,
} from '@devexpress/dx-react-chart-material-ui';

/* 
    โยนค่า
        datas = array of obj
        value = ค่าไหนที่เราจะเอาคำนวณ
        argumentField = ค่าที่เราคำนวณชื่ออะไร

*/
interface IPieChartCustom {
    title?: string
    datas: any[]
    value: string
    argumentField: string
    height: number
}



function PieChartCustom({ title, datas, value, argumentField, height }: IPieChartCustom) {

    return (
        <>
            <Chart
                data={datas}
                height={height}
                width={500}
            >
                <Title text={title} />
                <PieSeries
                    valueField={value}
                    argumentField={argumentField}
                />
                <Legend />
            </Chart> :

        </>
    )
}

export default PieChartCustom;
