import DateFnsUtils from "@date-io/date-fns";
import { Backdrop, Button, createStyles, Grid, makeStyles, TextField, Theme, Typography } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import { useState } from "react";
import Attendance from "../../models/Attendance";
import AttendanceForm from "../../models/AttendanceForm";
import EmployeeIsActive from "../../models/EmployeeIsActive";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import AlertWarning from "../SnackBarAlert/AlertWarning";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../ManageTimeAttendance/rte.css';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        container: {
            display: "flex",
            flexWrap: "wrap",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 200,
        },
        button: {
            margin: theme.spacing(1),
        },
        form: {
            paddingBottom: '10%'
        },
        gridPadding: {
            paddingTop: 10,
            paddingBottom: 10
        }
    })
);

interface IAddTimeAttendanceTotal {
    checkIn: any;
    refreshData: any;
    employeesIsActive: EmployeeIsActive[];
    attendances: Attendance[];
}

function AddTimeAttendanceTotal({ checkIn, refreshData, employeesIsActive, attendances }: IAddTimeAttendanceTotal) {
    const classes = useStyles()
    const [showTable, setShowTable] = useState<boolean>(false)
    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");
    const [worklog, setWorkLog] = useState<string>("");
    const [timeIn, setTimeIn] = useState<Date | null>(null);
    const [timeOut, setTimeOut] = useState<Date | null>(null);
    const [warningTime, setWarningTime] = useState<boolean>(false);
    const [warningFillTimeIn, setWarningFillTimeIn] = useState<boolean>(false);
    const [duplicate, setDuplicate] = useState<boolean>(false);
    const [empAttId, setEmpAttId] = useState<string>("")
    const [date, setDate] = useState<Date>(new Date());
    const [isIncludeBreak, setIsIncludeBreak] = useState(true);

    const handleClose = () => {
        setShowTable(false);
        setDuplicate(false);
    };

    const displayConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const selectTimeIn = (date: Date | null) => {
        setTimeIn(date);
    };

    const selectTimeOut = (date: Date | null) => {
        setTimeOut(date);
    };

    const selectStartDateTime = (date: any) => {
        setDate(new Date(date));
    };

    const convertDate_2_Datetime = (
        date: Date | null,
        time: Date | null
    ): string | null => {
        if (date === null || time === null) {
            return null;
        } else {
            return (
                date.getFullYear() +
                "-" +
                leftpad(date.getMonth() + 1, 2) +
                "-" +
                leftpad(date.getDate(), 2) +
                "T" +
                leftpad(time.getHours(), 2) +
                ":" +
                leftpad(time.getMinutes(), 2) +
                ":" +
                leftpad(time.getSeconds(), 2)
            );
        }
    };

    const leftpad = (val: any, resultLength = 2, leftpadChar = "0"): string => {
        return (String(leftpadChar).repeat(resultLength) + String(val)).slice(
            String(val).length
        );
    };
    const addAttendance = async () => {
        let formAtt: AttendanceForm = {
            attendId: empAttId,
            note: note,
            checkIn: convertDate_2_Datetime(new Date(date), timeIn),
            checkOut: convertDate_2_Datetime(new Date(date), timeOut),
            isIncludeBreak: isIncludeBreak,
            inOutDetail: worklog
        };

        if ((timeOut !== null && timeIn !== null) || (timeOut === null && timeIn !== null)) {
            var response: any;
            if (timeOut && timeIn) {
                if (timeIn > timeOut) {
                    setWarningTime(true);
                }
                else {
                    response = await checkIn(formAtt);
                    if (response && response.status === 200) {
                        handleClose();
                    }
                    else if (response && response.status === 409) {
                        setDuplicate(true);
                    }

                    refreshData();
                }
            }
            else {
                response = await checkIn(formAtt);
                if (response && response.status === 200) {
                    setTimeIn(null);
                    setTimeOut(null);
                    setEmpAttId("");
                    setNote("");
                    handleClose();
                    refreshData();
                }
                else if (response && response.status === 409) {
                    setDuplicate(true);
                }
            }
        }
        else {
            setWarningFillTimeIn(true);
        }
    };

    return (
        <>
            <Button fullWidth={true} variant="contained" onClick={() => { setShowTable(true) }}>
                <Typography align="center" >
                    New TimeAttendance
                </Typography>
            </Button>
            <Dialog
                open={showTable}
                onClose={handleClose}
                className={classes.modal}
                maxWidth={"md"}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <div className={classes.paper}>
                    <Grid item xs={12} sm={12}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <Grid container justify="space-around" >
                                <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Select Date"
                                    format="dd/MM/yyyy"
                                    value={date}
                                    onChange={selectStartDateTime}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    required
                                    maxDate={new Date()}
                                    maxDateMessage="Date should not be after maximal date"
                                    fullWidth={true}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={12} className={classes.gridPadding}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={employeesIsActive}
                            getOptionLabel={(option) => option.nickName}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Name"
                                    variant="outlined"
                                    value={empAttId}
                                    style={{ background: "white" }}
                                />
                            )}
                            renderOption={(option) => (
                                <>
                                    {option.nickName} {option.firstName}
                                </>
                            )}
                            onChange={(e, val) => {
                                if (val !== null) {
                                    setEmpAttId(val.attendId)
                                }
                            }}
                        />
                    </Grid>
                    <form className={classes.container} noValidate>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid
                                container
                                justify="space-around"
                                style={{ background: "white" }}
                            >
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Time In"
                                    value={timeIn}
                                    onChange={selectTimeIn}
                                    KeyboardButtonProps={{
                                        "aria-label": "change time",
                                    }}
                                    required
                                    ampm={false}
                                />
                                <KeyboardTimePicker
                                    margin="normal"
                                    id="time-picker"
                                    label="Time Out"
                                    value={timeOut}
                                    onChange={selectTimeOut}
                                    KeyboardButtonProps={{
                                        "aria-label": "change time",
                                    }}
                                    ampm={false}
                                />
                            </Grid>
                        </MuiPickersUtilsProvider>
                        <Grid item xs={12} className={classes.gridPadding}>
                            <FormControlLabel control={
                                <Switch checked={isIncludeBreak} color="primary" onChange={(e) => {
                                    setIsIncludeBreak(e.target.checked);
                                }} />
                            } label="Include a lunch break time" />
                        </Grid>
                        <Grid item xs={12} className={classes.gridPadding}>
                            <TextField
                                fullWidth={true}
                                label="Note"
                                variant="filled"
                                style={{ background: "white" }}
                                type="text"
                                value={note}
                                multiline
                                inputProps={{ maxLength: 100 }}
                                onChange={(e) => {
                                    setNote(e.target.value);
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} className={classes.gridPadding}>
                            {/* <TextField
                                fullWidth={true}
                                label="Work details"
                                variant="filled"
                                style={{ background: "white" }}
                                type="text"
                                value={worklog}
                                multiline
                                onChange={(e) => {
                                    setWorkLog(e.target.value);
                                }}
                            /> */}
                            <div className="custom-rte custom-rte-edit">
                                <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                        toolbar: ['bold', 'italic', 'link'],
                                        placeholder: "Worklog"
                                    }}
                                    data={worklog}
                                    onChange={(event: any, editor: any) => {
                                        console.log(event);
                                        console.log(editor);
                                        const data = editor.getData();
                                        setWorkLog(data);
                                    }}
                                />
                            </div>
                        </Grid>
                        <Button
                            fullWidth={true}
                            variant="contained"
                            type="button"
                            style={{ background: "#FAC330" }}
                            onClick={displayConfirmDialog} >
                            Submit
                        </Button>
                        <AlertWarning
                            msg="check in time should be more than check out time !"
                            open={warningTime}
                            setOpen={setWarningTime}
                        />
                        <AlertWarning
                            msg="fill check in time!"
                            open={warningFillTimeIn}
                            setOpen={setWarningFillTimeIn}
                        />
                        <AlertWarning
                            msg="Duplicate!"
                            open={duplicate}
                            setOpen={setDuplicate}
                        />
                        <ConFirmDialog
                            title="Save TimeAttendance"
                            description="Do you want to save timeattendance ?"
                            open={openConfirmDialog}
                            setOpen={setOpenConfirmDialog}
                            actionDialog={addAttendance} />
                    </form>
                </div>
            </Dialog>
        </>
    )
}

export default AddTimeAttendanceTotal
