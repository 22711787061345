import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

interface IAlertSuccess {
    msg: string
    open: boolean
    setOpen: any
}

function AlertSuccess({ msg, open, setOpen}: IAlertSuccess) {

    const onClose = () => {
        setOpen(false)
    }

    return (
        <>
            <Snackbar open={open} autoHideDuration={3500} onClose={onClose}>
                <Alert onClose={onClose} severity="success">
                    {msg}
                </Alert>
            </Snackbar>
        </>
    )
}

export default AlertSuccess