import { useState } from "react";
import Announce from "../../models/Announce";
import "./AddAnnounce.css";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CardMedia } from "@material-ui/core";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import AlertSuccess from "../SnackBarAlert/AlertSuccess";

interface IAddAnnounce {
  baseImage: any;
  post_announce: any;
  setBaseImage: any;
  success: boolean;
  setPreImg: any;
  preImg: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      textAlign: "center",
    },
    textArea: {
      padding: theme.spacing(3),
    },
    btnSelectFile: {
      display: "none",
    },
    imgAnn: {
      objectFit: "cover",
      width: "100%",
      height: "250px",
      border: "1px solid #DCDCDC",
    },
  })
);

function AddAnnounce({
  baseImage,
  post_announce,
  setBaseImage,
  success,
  setPreImg,
  preImg,
}: IAddAnnounce) {
  const classes = useStyles();
  const DEFAULT_NAME = "select image";

  const [imgName, setImgName] = useState<string>(DEFAULT_NAME);
  const [msg, setMsg] = useState<string>("");
  const [checked, setChecked] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState<boolean>(false);

  const addAnnounce = async () => {
    // add data

    let nAnn: Announce = {
      announceId: 0,
      img:
        baseImage.target === undefined ? null : baseImage.target.files[0].name,
      msg: msg,
      isTextmarquee: checked,
      fileImg:
        baseImage.target === undefined ? null : baseImage.target.files[0],
    };

    await post_announce(nAnn);
    setMsg("");
    setBaseImage("");
    setImgName(DEFAULT_NAME);
    setChecked(false);
    setOpenAlertSuccess(true);
    setPreImg("");
  };

  const displayConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };


  return (
    <>
      <Container maxWidth="md">
        <Paper elevation={3} className={classes.paper}>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid container justify="flex-start" alignItems="stretch">
                  <input
                    type="file"
                    accept="image/*"
                    id="contained-button-file"
                    key={imgName}
                    onChange={(e: any) => {
                      setImgName(e.currentTarget.files[0].name);
                      setBaseImage(e);
                      setPreImg(URL.createObjectURL(e.target.files[0]));
                    }}
                    className={classes.btnSelectFile}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload
                    </Button>{" "}
                    {imgName}
                  </label>

                  <CardMedia className={classes.imgAnn} image={preImg} />

                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="stretch"
                >
                  <TextField
                    variant="outlined"
                    placeholder="Message"
                    multiline
                    fullWidth
                    className={classes.textArea}
                    size="medium"
                    rows="5"
                    rowsMax="5"
                    value={msg}
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => {
                      setMsg(e.target.value);
                    }}
                  ></TextField>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label="Show Textmarquee"
                  />
                </Grid>
                <Box>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={displayConfirmDialog}
                  >
                    ADD
                  </Button>

                  <ConFirmDialog
                    title="Confirm Add Announce"
                    description="Do you want to add announce?"
                    open={openConfirmDialog}
                    setOpen={setOpenConfirmDialog}
                    actionDialog={addAnnounce}
                  />
                  {success && (
                    <AlertSuccess
                      msg="Add Announce Successfully"
                      open={openAlertSuccess}
                      setOpen={setOpenAlertSuccess}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
}

export default AddAnnounce;
