import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import LabelIcon from '@material-ui/icons/Label';
import ProjectList from "../../../models/ProjectList";

interface IWorkHistoryTeamBadge {
    projectList: ProjectList[];
}

export default function WorkHistoryTeamBadge({ projectList }: IWorkHistoryTeamBadge) {
    return (
        <div style={{ float: "right" }}>
            <Box p={1}>
                <Grid container spacing={1} direction="row">
                    {projectList.map((project) => {
                        var projectColor = projectList.filter((x) => x.projectId === project.projectId && !project.isMainProject);
                        var rowProjectColor = projectColor && projectColor.length > 0 ? projectColor[0].projectColor : "#eeeeee";

                        return (
                            <Grid key={project.projectId} item style={{ paddingTop: 0 }}>
                                <Typography>
                                    <LabelIcon
                                        fontSize="small"
                                        style={{
                                            color: rowProjectColor,
                                            marginRight: 5,
                                            top: 4,
                                            position: "relative",
                                        }}
                                    />
                                    {project.projectName}
                                </Typography>
                            </Grid>
                        );
                    })}
                </Grid>
            </Box>
        </div>
    );
}
