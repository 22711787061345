import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

interface IAlertWarningAddWFH {
  msg: string;
  open: boolean;
  setOpen: any;
}

function AlertWarningAddWFH({
  msg,
  open,
  setOpen,
}: IAlertWarningAddWFH) {
  const onClose = () => {
    setOpen(false);
  };

  
  return (
    <>
      <Snackbar open={open} autoHideDuration={3500} onClose={onClose}>
        <Alert onClose={onClose} severity="warning">
          {msg}
        </Alert>
      </Snackbar>

    </>
  );
}

export default AlertWarningAddWFH;
