import moment from "moment";
import {
  TableRow,
  TableCell,
  Button,
  TextField,
  Grid
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import { unescape } from 'html-escaper';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../ManageTimeAttendance/rte.css';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
    button: {
      margin: theme.spacing(1),
    },
    lightSwitch: {
      opacity: "50%",
      pointerEvents: "none"
    }
  })
);

interface IEditTimeAttendance {
  index: number;
  attend: any;
  editAttendanceAdmin: any;
  refreshData: any;
  color: any;
}

function EditTimeAttendance({
  index,
  attend,
  editAttendanceAdmin,
  refreshData,
  color,
}: IEditTimeAttendance) {
  const classes = useStyles();

  const DEFAUT_DATE_TIMEIN = new Date(attend.checkIn)
  const DEFAUT_DATE_TIMEOUT = attend.checkOut ? new Date(attend.checkOut) : null

  const [open, setOpen] = React.useState(false);
  const [timeIn, setTimeIn] = useState<Date | null>(DEFAUT_DATE_TIMEIN);
  const [timeOut, setTimeOut] = useState<Date | null>(DEFAUT_DATE_TIMEOUT);
  const [editnote, setEditNote] = useState<any>((attend && attend.note) ? unescape(attend.note) : "");
  const [warning, setWarning] = useState<boolean>(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [isIncludeBreak, setIsIncludeBreak] = useState(attend ? attend.isIncludeBreak : true);
  const [worklog, setWorkLog] = useState<any>((attend && attend.inOutDetail) ? unescape(attend.inOutDetail) : "");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setIsIncludeBreak(attend.isIncludeBreak);
    setOpen(false);
  };

  const selectTimeIn = (date: Date | null) => {
    if (date) {
      setTimeIn(new Date(date));
    }
  };

  const selectTimeOut = (date: Date | null) => {
    if (date) {
      setTimeOut(new Date(date))
    }
  };

  useEffect(() => {
    const DEFAUT_DATE_TIMEIN = new Date(attend.checkIn);
    const DEFAUT_DATE_TIMEOUT = attend.checkOut ? new Date(attend.checkOut) : null;

    setTimeIn(DEFAUT_DATE_TIMEIN);
    setTimeOut(DEFAUT_DATE_TIMEOUT);
    setEditNote(attend.note);
    setIsIncludeBreak(attend.isIncludeBreak);
    setWorkLog(attend.inOutDetail);
  }, [attend.note, attend.checkOut, attend.checkIn, attend.isIncludeBreak, attend.inOutDetail]);

  useEffect(() => {
    setIsIncludeBreak(attend ? attend.isIncludeBreak : true);
  }, [attend]);

  const convertDate_2_Datetime = (
    date: Date | null,
    time: Date | null
  ): string | null => {
    if (date === null || time === null) {
      return null;
    } else {
      return (
        date.getFullYear() +
        "-" +
        leftpad(date.getMonth() + 1, 2) +
        "-" +
        leftpad(date.getDate(), 2) +
        "T" +
        leftpad(time.getHours(), 2) +
        ":" +
        leftpad(time.getMinutes(), 2) +
        ":" +
        leftpad(time.getSeconds(), 2)
      );
    }
  };

  const leftpad = (val: any, resultLength = 2, leftpadChar = "0"): string => {
    return (String(leftpadChar).repeat(resultLength) + String(val)).slice(
      String(val).length
    );
  };

  const editTimeAttendance = async () => {
    // edit data
    if (timeIn !== null && timeOut !== null) {
      if (Number(moment(timeIn).format("HH.mm")) <= Number(moment(timeOut).format("HH.mm"))) {
        await editAttendanceAdmin(
          attend.count,
          convertDate_2_Datetime(new Date(attend.checkIn), timeIn),
          convertDate_2_Datetime(new Date(attend.checkIn), timeOut),
          editnote,
          isIncludeBreak,
          worklog
        );

        refreshData();
      }
      else {
        setWarning(true);
      }
    }
    else if (timeIn !== null) {
      //Case: user fill only time-in and admin want to edit it
      if (Number(moment(timeIn).format("HH.mm"))) {
        await editAttendanceAdmin(
          attend.count,
          convertDate_2_Datetime(new Date(attend.checkIn), timeIn),
          null,
          editnote,
          isIncludeBreak,
          worklog
        );

        refreshData();
      }
      else {
        setWarning(true);
      }
    }
    else {
      setWarning(true);
    }
  };

  const displayConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const warningClose = () => {
    setWarning(false);
  };

  return (
    <>
      <TableRow className={color}>
        <TableCell component="th" scope="row" align="center">
          {index}
        </TableCell>
        <TableCell align="center">
          {moment(attend.checkIn).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell align="center">{attend.employeeName}</TableCell>
        <TableCell align="center">
          {moment(attend.checkIn).format("HH:mm")}
        </TableCell>
        <TableCell align="center">
          {attend.checkOut === null && <>-- </>}
          {attend.checkOut !== null && moment(attend.checkOut).format("HH:mm")}
        </TableCell>
        <TableCell align="center">{attend.note}</TableCell>
        <TableCell align="center">
          <Switch color="primary" size="small" checked={isIncludeBreak} className={classes.lightSwitch} />
        </TableCell>
        <TableCell align="center">
          {attend.hours !== null ? (
            <>{Number(attend.hours).toFixed(2)}</>
          ) : (
            <>--</>
          )}
        </TableCell>
        <TableCell align="center">
          <Button variant="outlined" color="primary" onClick={handleOpen}>
            <EditIcon />
          </Button>
        </TableCell>
      </TableRow>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        maxWidth={"md"}
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">{attend.employeeName}</h2>
            <h3 id="transition-modal-title">
              {moment(attend.checkIn).format("YYYY/MM/DD")}
            </h3>
            <form className={classes.container} noValidate>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid
                  container
                  justify="space-around"
                  style={{ background: "white", marginBottom: "15px" }}
                >
                  <KeyboardTimePicker
                    margin="normal"
                    label="Time In"
                    value={timeIn}
                    onChange={selectTimeIn}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    required
                    ampm={false}
                  />
                  <KeyboardTimePicker
                    margin="normal"
                    label="Time Out"
                    value={timeOut ? timeOut : null}
                    onChange={selectTimeOut}
                    KeyboardButtonProps={{
                      "aria-label": "change time",
                    }}
                    format="HH:mm"
                    ampm={false}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
              <div style={{
                margin: "auto",
                marginBottom: "15px",
                marginLeft: 10,
                marginRight: 10
              }}>
                <FormControlLabel control={
                  <Switch checked={isIncludeBreak} color="primary" onChange={(e) => {
                    setIsIncludeBreak(e.target.checked);
                  }} />
                } label="Include a lunch break time" />
              </div>
              <TextField
                style={{
                  margin: "auto",
                  marginBottom: "15px",
                  marginLeft: 10,
                  marginRight: 10
                }}
                id="outlined-helperText"
                label="Note"
                value={editnote}
                onChange={(e) => {
                  setEditNote(e.target.value);
                }}
                variant="outlined"
                fullWidth
              />
              <div
                className="custom-rte custom-rte-edit"
                style={{
                  overflowX: "hidden",
                  margin: "auto",
                  marginBottom: "15px",
                  marginLeft: 10,
                  marginRight: 10
                }}>
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: ['bold', 'italic', 'link'],
                    placeholder: "Worklog"
                  }}
                  data={worklog}
                  onChange={(event: any, editor: any) => {
                    console.log(event);
                    console.log(editor);
                    const data = editor.getData();
                    setWorkLog(data);
                  }}
                />
              </div>
              {/* <TextField
                style={{
                  margin: "auto",
                  marginBottom: "15px",
                  marginLeft: "9%",
                  marginRight: "9%",
                }}
                id="outlined-helperText"
                label="Worklog"
                value={worklog}
                onChange={(e) => {
                  setWorkLog(e.target.value);
                }}
                variant="outlined"
                type="text"
                fullWidth
                multiline
              /> */}
              <Button
                style={{ margin: "auto" }}
                type="button"
                variant="contained"
                color="primary"
                size="small"
                className={classes.button}
                startIcon={<SaveIcon />}
                onClick={displayConfirmDialog}
              >
                Save
              </Button>
              <Snackbar
                open={warning}
                autoHideDuration={6000}
                onClose={warningClose}
              >
                <Alert onClose={warningClose} severity="warning">
                  check out time should be more than check in time
                </Alert>
              </Snackbar>
              <ConFirmDialog
                title="Save TimeAttendance"
                description="Do you want to save timeattendance ?"
                open={openConfirmDialog}
                setOpen={setOpenConfirmDialog}
                actionDialog={editTimeAttendance}
              />
            </form>
          </div>
        </Fade>
      </Dialog>
    </>
  );
}
export default EditTimeAttendance;
