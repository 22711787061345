import {
  Grid,
  Typography,
} from "@material-ui/core";
import { useEffect } from "react";
import useEmailNotify from "../../hook/useEmailNotify";
import FormEmail from "./FormEmail";
import EmailIcon from "@material-ui/icons/Email";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    title: {
      marginTop: "3%",
      marginLeft: "2%",
      marginBottom: "2%",
    },
    FormHelperTextProps: {
      color: "red",
      marginTop: "1%",
      marginLeft: "1%",
      marginBottom: "1%",
    }
  })
);

function EmailTemplate() {
  const [
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    getEmailConfig,
    emailConfig,
    putEmailConfig,
  ] = useEmailNotify();

  const classes = useStyles();

  useEffect(() => {
    getEmailConfig();
  }, [getEmailConfig]);

  return (
    <>
      <div className={classes.root}>
        <Grid className={classes.title}>
          <Grid container spacing={1}>
            <Grid item>
              <EmailIcon fontSize="large" />
            </Grid>
            <Grid item xs zeroMinWidth style={{ margin: "auto" }}>
              <Typography variant="h4" noWrap>
                EDIT FORM EMAIL
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        {emailConfig.map((config, key) => {
          return (
            <FormEmail
              config={config}
              putEmailConfig={putEmailConfig}
              classes={classes}
            />
          );
        })}
      </div>


    </>
  );
}
export default EmailTemplate;
