import Attendance from "../../models/Attendance"
import moment from "moment";
import {
    TableRow,
    TableCell,
    makeStyles,
    createStyles,
} from "@material-ui/core";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(() =>
    createStyles({
        note: {
            whiteSpace: 'normal',
            wordBreak: 'break-word'
        },
        lightSwitch: {
			opacity: "50%",
			pointerEvents: "none"
		}
    })
)

interface ITimeAttendanceRows {
    index: number
    attend: Attendance
}

function TimeAttendanceRows({ index, attend }: ITimeAttendanceRows) {
    const classes = useStyles()
    return (
        <TableRow >
            <TableCell component="th" scope="row" align="center">{index}</TableCell>
            <TableCell align="center">{attend.employeeName}</TableCell>
            <TableCell align="center">{moment(attend.checkIn).format('HH:mm')}</TableCell>
            <TableCell align="center">
                {attend.checkOut === null &&
                    <>--</>}
                {attend.checkOut !== null &&
                    moment(attend.checkOut).format('HH:mm')}
            </TableCell>
            <TableCell align="center">
                <Switch color="primary" size="small" checked={attend.isIncludeBreak} className={classes.lightSwitch} />
            </TableCell>
            <TableCell className={classes.note}>{attend.note}</TableCell>
            <TableCell align="center">
                {attend.hours !== null ?
                    <>
                        {Number(attend.hours).toFixed(2)}
                    </>
                    :
                    <>
                        --
                    </>
                }
            </TableCell>
        </TableRow>

    )
}

export default TimeAttendanceRows