import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { history } from './history';
interface IHeader {
  key: string
  value: string
}
class HttpRequest {
  axiosInstance: AxiosInstance
  constructor(url: string) {
    this.axiosInstance = axios.create({
      baseURL: url,
      timeout: 600000,
    })
    this.axiosInstance.defaults.headers['Content-Type'] =
      'application/json'

    this.axiosInstance.interceptors.request.use(
      (config) => {
        // Do something before request is sent
        let token = localStorage.getItem("token")
        if (token && token !== "") {
          config.headers = {
            'Authorization': `Bearer ${token}`,
          }
        }
        return config
      },
      (error) => {
        // Do something with request error
        return Promise.reject(error)
      },
    )

    // Add a response interceptor
    this.axiosInstance.interceptors.response.use(
      (response) => {
        // Do something with response data
        return response
      },
      (error) => {
        if (error.response === undefined) {
          setTimeout(() => { history.push('/error500'); });
        }
        else if (error.response.status === 401) {
          localStorage.removeItem('token');
          setTimeout(() => { history.push('/login'); });
        }
        else if (error.response.status === 404) {
          setTimeout(() => { history.push('/error404'); });
        }
        else if (error.response.status === 500) {
          setTimeout(() => { history.push('/error500'); });
        }

        return new Promise((resolve, reject) => {
          reject(error);
        });
      });

  }

  setHeader(header: IHeader) {
    this.axiosInstance.defaults.headers.common[header.key] = header.value
  }

  get<type>(methodName: string, config?: AxiosRequestConfig) {
    return this.axiosInstance.get<type>(methodName, config)
  }

  post<type>(methodName: string, data?: any, config?: AxiosRequestConfig) {
    return this.axiosInstance.post<type>(methodName, data, config)
  }

  put<type>(methodName: string, data?: any, config?: AxiosRequestConfig) {
    return this.axiosInstance.put<type>(methodName, data, config)
  }

  delete<type>(methodName: string, config?: AxiosRequestConfig) {
    return this.axiosInstance.delete<type>(methodName, config)
  }
}

export default HttpRequest