import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

interface IAlertSuccess {
    msg: string
    open: boolean
    setOpen: any
    setFileName: any
    DEFAULT_FILENAME: any
    setData: any
}

function AlertSuccess({ msg, open, setOpen, setFileName, DEFAULT_FILENAME, setData}: IAlertSuccess) {

    const onClose = () => {
        setOpen(false)
    }
    setFileName(DEFAULT_FILENAME)
    setData()

    return (
        <>
            <Snackbar open={open} autoHideDuration={3500} onClose={onClose}>
                <Alert onClose={onClose} severity="success">
                    {msg}
                </Alert>
            </Snackbar>
        </>
    )
}

export default AlertSuccess