import {
  Button,
  Container,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import './pageRespon.css'

interface IPage404 {
  setOpen: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      background: "linear-gradient(180deg, #010101 30%, #4a4a4a 180%)",
    },
    btn: {
      marginTop: "20px",
      color: "#FFCB39",
      borderRadius: "20px"
    },
    responText: {
      color: "white",
      marginBottom: "20px",
      letterSpacing: "0.5em"
    }

  })
);

function Page404({ setOpen }: IPage404) {
  const classes = useStyles();

  useEffect(() => {
    if (window.location.pathname === "/error404") {
      setOpen(false);
    }
  }, [setOpen])

  return (
    <>

      <div className={classes.root} >

        <div className="stars">
          <Container >

            <Grid container direction="row">
              <Grid direction="column" xs={10}>
                <img
                  alt="img"
                  src="http://salehriaz.com/404Page/img/earth.svg"
                  width="100px"
                />
                <img alt="img" src="http://salehriaz.com/404Page/img/moon.svg" width="80px" />
              </Grid>
              <Grid direction="column" xs={2}>
                <img
                  alt="img"
                  src="http://salehriaz.com/404Page/img/rocket.svg"
                  width="40px"
                />
              </Grid>
            </Grid>

            <Grid container direction="row" >
              <Grid container direction="column" xs={12} justify="center" alignItems="center">
                <Grid item >
                  <Typography align="center" className={classes.responText}>PAGE NOT FOUND</Typography>
                  <img
                    alt="img"
                    src="http://salehriaz.com/404Page/img/404.svg"
                    width="300px"
                  />
                </Grid>

              </Grid>

              <Grid container direction="row" xs={12} justify="center" alignItems="center">
                {/* <div style={{ backgroundColor: "gray" }}>AAA</div> */}
                <Link to="/" style={{ textDecoration: "none" }} >
                  <Button variant="outlined" color="inherit" className={classes.btn}>
                    GO TO DASHBOARD
                  </Button>
                </Link>
              </Grid>


            </Grid>

            <Grid direction="row" xs={12} style={{ marginLeft: "70%" }}>
              {/* <div style={{ backgroundColor: "blue" }}>AAA</div> */}
              <img alt="img" className="object_astronaut" src="http://salehriaz.com/404Page/img/astronaut.svg" width="140px" />
            </Grid>

          </Container>
        </div>
      </div>
    </>
  );
}
export default Page404;
