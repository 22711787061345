import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import DescriptionIcon from "@material-ui/icons/Description";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Statistic from "./Statistic/Statistic";
import Leave from "./Leave/Leave";
import TimeAttendance from "./TimeAttendance-Report.tsx/TimeAttendance";
import WorkHours from "./WorkHours/WorkHours";
import useReportAPI from "../../hook/useReportAPI";
import React, { useEffect } from "react";
import { BottomNavigation, BottomNavigationAction } from "@material-ui/core";
import EqualizerIcon from "@material-ui/icons/Equalizer";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import moment from "moment";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      //marginTop: "2%",
      marginLeft: "2%",
      marginBottom: "2%",
    },
    root: {
      padding: theme.spacing(3),
    },
    marginComponent: {
      marginTop: "5%",
    },
    menuBarPosition: {
      position: "fixed",
      // zIndex:99,
      justify: "flex-start",
      marginLeft: "5%",
      overflow: "hidden",
      bottom: "4%",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "80%",
      height: "80%",
      overflow: "scroll",
    },
  })
);

function Report() {
  const classes = useStyles();

  const [
    loading,
    loadingStatistic,
    loadingLeaves,
    loadingTimeAttendance,
    getTeams,
    teams,
    getLeaves,
    leaves,
    getStatistics,
    statisticReport,
    ratioEmpWorks,
    datasBarchart,
    timeAtts,
    getTimeAttendances,
    getWorkHoursDetail,
    workHoursDetail,
    loadingWorkHoursDetail,
  ] = useReportAPI();

  const [value, setValue] = React.useState("recents");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    getWorkHoursDetail(
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DDT23:59:59"),
      0,
      0
    );
  }, [getWorkHoursDetail]);



  return (
    <>
      <Grid>
        {/* Menu Bar */}
        <Grid container>
          <BottomNavigation value={value} onChange={handleChange}>
            <Grid className={classes.menuBarPosition}>
              <Grid>
                <BottomNavigationAction
                  title="Statistic"
                  href="#statistic"
                  label="Statictics"
                  value="recents"
                  icon={<EqualizerIcon />}
                />
              </Grid>
              <Grid>
                <BottomNavigationAction
                  title="WorkHours"
                  href="#work-hours"
                  label="WorkHours"
                  value="favorites"
                  icon={<LibraryBooksIcon />}
                />
              </Grid>
              <Grid>
                <BottomNavigationAction
                  title="Leaves"
                  href="#leaves"
                  label="Leaves"
                  value="nearby"
                  icon={<CardTravelIcon />}
                />
              </Grid>
              <Grid>
                <BottomNavigationAction
                  title="Time Attendances"
                  href="#time-attendances"
                  label="TimeAttend"
                  value="folder"
                  icon={<QueryBuilderIcon />}
                />
              </Grid>
            </Grid>
          </BottomNavigation>
        </Grid>

        {/* Menu Bar */}

        <Grid container direction="column" justify="space-between">
          <Grid className={classes.root}>
            <Grid className={classes.title}>
              <Grid container spacing={1}>
                <Grid item>
                  <DescriptionIcon fontSize="large" />
                </Grid>

                <Grid item xs zeroMinWidth style={{ margin: "auto" }}>
                  <Typography variant="h4" noWrap>
                    REPORT
                  </Typography>

                  <Grid id="statistic" style={{ marginBottom: "7%" }}></Grid>
                  <Grid>
                    <Statistic
                      loadingStatistic={loadingStatistic}
                      getStatistics={getStatistics}
                      statisticReport={statisticReport}
                      ratioEmpWorks={ratioEmpWorks}
                      datasBarchart={datasBarchart}
                    />
                  </Grid>

                  <Grid id="work-hours" style={{ marginBottom: "7%" }}></Grid>
                  <Grid className={classes.marginComponent}>
                    <WorkHours
                      loading={loading}
                      getTeams={getTeams}
                      teams={teams}
                      getWorkHoursDetail={getWorkHoursDetail}
                      workHoursDetail={workHoursDetail}
                      icon={<LibraryBooksIcon />}
                      loadingWorkHoursDetail={loadingWorkHoursDetail}
                    />
                  </Grid>

                  <Grid id="leaves" style={{ marginBottom: "7%" }}></Grid>
                  <Grid className={classes.marginComponent}>
                    <Leave
                      loading={loadingLeaves}
                      getLeaves={getLeaves}
                      leaves={leaves}
                      getTeams={getTeams}
                      teams={teams}
                      icon={<CardTravelIcon />}
                    />
                  </Grid>

                  <Grid
                    id="time-attendances"
                    style={{ marginBottom: "7%" }}
                  ></Grid>
                  <Grid className={classes.marginComponent}>
                    <TimeAttendance
                      loading={loadingTimeAttendance}
                      getTimeAttendances={getTimeAttendances}
                      timeAtts={timeAtts}
                      getTeams={getTeams}
                      teams={teams}
                      icon={<QueryBuilderIcon />}
                    />
                  </Grid>


                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Report;
