import { Container, createStyles, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import Attendance from "../../models/Attendance";
import EmployeeIsActive from "../../models/EmployeeIsActive"
import EmployeeLeave from "../../models/EmployeeLeave";
import StatDetailRow from "./StatDetailRow";

interface StatDetailTableProps {
  employees: EmployeeIsActive[]
  attendances: Attendance[]
  leaves?: EmployeeLeave[]
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    thead: {
      background: "#25272E",
    },
    tbody: {
      background: "white",
    },
    attended: {
      background: "white"
    },
    not_attend: {
      background: "#E3E3E3"
    }
  })
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "white",
    textAlign: "center",
  },
}))(TableCell);

function StatDetailTable({ employees, attendances, leaves = [] }: StatDetailTableProps) {
  const classes = useStyles();
  return (
    <Container maxWidth="lg">
      <TableContainer className={classes.root}>
        <Table>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '30%' }} />
          </colgroup>
          <TableHead>
            <TableRow className={classes.thead}>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Nickname</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody className={classes.tbody}>

            {employees.map((e, key) => {
              if (attendances.find(m => m.employeeId === e.employeeId)) {
                return <StatDetailRow index={key + 1} employee={e} className={classes.attended} status="ATTENDED" />
              } else if (leaves.find(m => m.nickName === e.nickName)) {
                return <StatDetailRow index={key + 1} employee={e} className={classes.attended} status="LEAVE" />
              } else {
                return <StatDetailRow index={key + 1} employee={e} className={classes.not_attend} status="NOT ATTEND" />
              }
            })}

          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  )
}

export default StatDetailTable