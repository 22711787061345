import { Button, TableCell, TableRow } from "@material-ui/core";
import { useState } from "react";
import AddIcon from '@material-ui/icons/Add';
import moment from "moment";
import EmployeeAbsences from "../../models/EmployeeAbsences";
import AddTimeAttendance from "./AddTimeAttendance";

interface EmployeeAbdencesTableRow {
  index: number;
  empAbs: EmployeeAbsences;
  checkIn: any;
  refreshData: any;
}

function EmployeeAbsencesTableRow({ index, empAbs, checkIn, refreshData }: EmployeeAbdencesTableRow) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <>
      <TableRow>
        <TableCell component="th" scope="row" align="center">{index}</TableCell>
        <TableCell align="center">{moment(empAbs.date).format('DD/MM/yyyy')}</TableCell>
        <TableCell align="center">{empAbs.nickName}</TableCell>
        <TableCell align="center">
          <Button variant="contained" color="primary" onClick={handleOpen}>
            <AddIcon />
          </Button>
        </TableCell>
      </TableRow>

      <AddTimeAttendance emp={empAbs} open={open} setOpen={setOpen} checkIn={checkIn} refreshData={refreshData}/>
    </>
  )
}

export default EmployeeAbsencesTableRow