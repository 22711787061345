import moment from "moment";
import {
    TableRow,
    TableCell
} from "@material-ui/core";
import EmployeeLeave from "../../models/EmployeeLeave";

interface ITimeEmployeeLeaveRows {
    index: number
    empLeave: EmployeeLeave
}

function TimeEmployeeLeaveRows({ index, empLeave }: ITimeEmployeeLeaveRows) {
    return (
        <>
            <TableRow >
                <TableCell component="th" scope="row" align="center">{index}</TableCell>
                <TableCell align="center">{empLeave.nickName}</TableCell>
                <TableCell align="center">{moment(empLeave.dateLeave).format('DD/MM/yyy')}</TableCell>
                <TableCell align="center">{moment(empLeave.dateLeaveto).format('DD/MM/yyyy')}</TableCell>
                <TableCell align="center">{empLeave.leaveAmount} Day</TableCell>
                <TableCell align="center">{empLeave.subject}</TableCell>
                <TableCell align="center">
                    {empLeave.description === null &&
                        <>-</>}
                    {empLeave.description !== null &&
                        empLeave.description}
                </TableCell>
                <TableCell align="center">
                    {empLeave.note === null &&
                        <>-</>}
                    {empLeave.note !== null &&
                        empLeave.note}</TableCell>
            </TableRow>
        </>
    )
}

export default TimeEmployeeLeaveRows