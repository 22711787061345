import Paper from '@material-ui/core/Paper';
import {
    Chart,
    BarSeries,
    ArgumentAxis,
    ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import { Animation, Stack } from '@devexpress/dx-react-chart';
import { Backdrop, CircularProgress, Container, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import WeeklyEmployee from '../../models/WeeklyEmployee';
import moment from 'moment';
import { useEffect, useState } from 'react';

interface DashBoardWeeklyChartProps {
    weeklyEmployees: WeeklyEmployee[],
    loading: any
}
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#fff',
        },
    })
);

function DashBoardWeeklyChart({ weeklyEmployees, loading }: DashBoardWeeklyChartProps) {
    const classes = useStyles();
    const monday = weeklyEmployees.find(e => moment(e.date).format('dd') === 'Mo')?.date
    const [data, setData] = useState<{ "date": any, "total": number, "clockIn": number, "clockOut": number, "leave": number }[]>([])
    useEffect(() => {

        weeklyEmployees.sort((a, b) => (a.date > b.date) ? 1 : -1).map((m) => {
            return (setData(d => [...d, { "date": moment(m.date).format('DD MMMM yyyy'), "total": m.countEmployee, "clockIn": m.countClockIn, "clockOut": m.countClockOut, "leave": m.countLeave }]))
        })
    }, [weeklyEmployees])

    return (
        <Container maxWidth="md" style={{ paddingBottom: "30px" }}>
            <Typography>
                {moment(monday).format('DD MMMM yyyy')} - {moment(monday).add(4, 'days').format('DD MMMM yyyy')}
            </Typography>
            <Paper>
                {loading ?
                    <>
                        <Backdrop className={classes.backdrop} open={loading}>
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </> : <>
                        <Chart
                            data={data}
                        >
                            <ArgumentAxis />
                            <ValueAxis />

                            <BarSeries
                                name="Total"
                                valueField="total"
                                argumentField="date"
                                color="#2D2E33"
                            />

                            <BarSeries
                                name="Clock In"
                                valueField="clockIn"
                                argumentField="date"
                                color="#FAC330"
                            />

                            <BarSeries
                                name="Clock Out"
                                valueField="clockOut"
                                argumentField="date"
                                color="#6EC1B2"
                            />

                            <BarSeries
                                name="Leave"
                                valueField="leave"
                                argumentField="date"
                                color="#F77C6F"
                            />

                            <Animation />
                            <Stack />
                        </Chart>
                    </>
                }
            </Paper>
        </Container>
    );

}

export default DashBoardWeeklyChart
