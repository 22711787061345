import HttpRequest from "../helper/httpRequest"
import EmailConfig from "../models/EmailConfig";
import EmailNotification from "../models/EmailNotification";
import SelectEmailNotifyLogModel from "../models/SelectEmailNotifyLogModel";

const API_URL = process.env.REACT_APP_API_URL

const httpRequest = new HttpRequest(`${API_URL}`);


const emailNotifyApi = {
    // get 
    getEmailNotify: (empId: number, projactId: number, startdate: any, enddate: any) => {
        return httpRequest.get<EmailNotification[]>(API_URL + "api/Notification/SelectNoifyEmployee", {
            params: {
                empId: empId,
                proId: projactId,
                startdate: startdate,
                enddate: enddate,
            }
        })
    },

    getEmailNotifyFilter: (empId: number, projactId: number, startdate: any, enddate: any) => {
        return httpRequest.get<EmailNotification[]>(API_URL + "api/Notification/SelectCheckFailedNoifyEmployee", {
            params: {
                empId: empId,
                proId: projactId,
                startdate: startdate,
                enddate: enddate,
            }
        })
    },

    insertEmailNotifyLog: (emailLog: SelectEmailNotifyLogModel[]) => {
        return httpRequest.post<SelectEmailNotifyLogModel[]>(`${API_URL}api/Notification/InsertNotifyLog`, emailLog)
    },


    sendEmailNotifyLog: (sendEmail: EmailNotification[]) => {
        return httpRequest.post<EmailNotification[]>(`${API_URL}api/Notification/SendEmailNoify`, sendEmail)
    },


    getEmailConfig: () => {
        return httpRequest.get<EmailConfig[]>(API_URL + "api/Notification/SelectEmailConfig")
    },

    editEmailConfig: (editConfig: EmailConfig) => {
        return httpRequest.put<EmailConfig>(`${API_URL}api/Notification/EditEmailConfig`, editConfig)
},

    // get email log
    // getEmailNotifyLog: (empId: number,startdate: any, enddate: any) => {
    //     return httpRequest.get<SelectEmailNotifyLogModel[]>(API_URL + "api/Notification/SelectNoifyLog", {
    //         params: {
    //             empId: empId,
    //             startdate: startdate,
    //             enddate: enddate
    //         }
    //     })
    // }
}

export default emailNotifyApi
