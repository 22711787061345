import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  makeStyles,
  createStyles,
  withStyles,
  TableCell,
  Container,
} from "@material-ui/core";
import { useEffect } from "react";
import moment from "moment";
import TimeAttendanceRows from "./TimeAttendanceRow";
import Attendance from "../../models/Attendance";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
    },
    thead: {
      background: "#25272E",
    },
    tbody: {
      background: "white",
    },
  })
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "white",
    textAlign: "center",
  },
}))(TableCell);

interface DashBoardTimeAttendProps {
  attendances: Attendance[] | undefined
  loading: boolean
  getAttendances: any
  refresh: Date
}

function DashBoardTimeAttend({ attendances, loading, getAttendances, refresh }: DashBoardTimeAttendProps) {
  const classes = useStyles();
  // const [weeklyEmployees, getWeeklyEmployees, getAttendances, attendances, loading] = useAttendanceApi();

  useEffect(() => {

    getAttendances(moment(new Date()).format("YYYY-MM-DD"), moment(new Date()).format("YYYY-MM-DD"));

  }, [refresh, getAttendances]);

  return (
    <Container maxWidth="lg">
      <TableContainer className={classes.root}>
        <Table>
          <colgroup>
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '10%' }} />
          </colgroup>
          <TableHead>
            <TableRow className={classes.thead}>
              <StyledTableCell>#</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Check-in</StyledTableCell>
              <StyledTableCell>Check-out</StyledTableCell>
              <StyledTableCell>Include Break</StyledTableCell>
              <StyledTableCell>Note</StyledTableCell>
              <StyledTableCell>Summary</StyledTableCell>
            </TableRow>
          </TableHead>

          <TableBody className={classes.tbody}>

            {loading ?
              <>
                Loading ...
              </>
              :
              <>
                {attendances?.map((e, key) => {
                  return <TimeAttendanceRows index={key + 1} attend={e} />
                })}
              </>
            }

          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default DashBoardTimeAttend;
