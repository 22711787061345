import { createStyles, Link, makeStyles, Typography } from "@material-ui/core"
const useStyles = makeStyles(() =>
  createStyles({
    copyright_part: {
      // marginTop: "1.5%",
      background: "#DCDCDC",
      color: "black",
      // paddingLeft: "1%",
      // paddingRight: "1%"
      padding: "0.7%",
    },
  })
);

function CopyRight() {
  const classes = useStyles();

  return (
    <div className={classes.copyright_part}>
      <Typography variant="body2" align="center">
        {"Copyright © "}
        <Link color="inherit" href="https://www.mycostech.com/">
          Mycos Technologies
        </Link>{" "}
        {new Date().getFullYear()}
        {"."}
      </Typography>
    </div>
  )
}
export default CopyRight