import { Container, createStyles, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles } from "@material-ui/core";
import moment from "moment";
import LeaveReport from "../../../models/LeaveReport";

interface IStatDetailLeaveRow {
    leave?: LeaveReport[] | undefined
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            width: "100%",
        },
        thead: {
            background: "#25272E",
        },
        tbody: {
            background: "white",
        },
        attended: {
            background: "white"
        },
        not_attend: {
            background: "#E3E3E3"
        }
    })
);

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: "white",
        textAlign: "center",
    },
}))(TableCell);

function StatDetailLeaveRow({ leave }: IStatDetailLeaveRow) {
    const classes = useStyles();
    return (
        <Container maxWidth="lg">
            <TableContainer className={classes.root}>
                <Table>
                    <colgroup>
                        <col style={{ width: '5%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '15%' }} />
                        <col style={{ width: '5%' }} />
                    </colgroup>
                    <TableHead>
                        <TableRow className={classes.thead}>
                            <StyledTableCell>#</StyledTableCell>
                            <StyledTableCell>Name</StyledTableCell>
                            <StyledTableCell>DateLeave</StyledTableCell>
                            <StyledTableCell>DateLeaveTo</StyledTableCell>
                            <StyledTableCell>LeaveType</StyledTableCell>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell>Note</StyledTableCell>
                            <StyledTableCell>TotalHoursLeave</StyledTableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody className={classes.tbody}>
                        {leave?.map((e, key) => {
                            return (
                                <TableRow>
                                    <TableCell component="th" scope="row" align="center">{key + 1}</TableCell>
                                    <TableCell component="th" scope="row" align="center">{e.nickName} {e.name}</TableCell>
                                    <TableCell component="th" scope="row" align="center">{moment(e.dateLeave).format('DD/MM/yyy')}</TableCell>
                                    <TableCell component="th" scope="row" align="center">{moment(e.dateLeaveto).format('DD/MM/yyy')}</TableCell>
                                    <TableCell component="th" scope="row" align="center">{e.leaveType}</TableCell>
                                    <TableCell component="th" scope="row" align="center">{e.description}</TableCell>
                                    <TableCell component="th" scope="row" align="center">{e.note}</TableCell>
                                    <TableCell component="th" scope="row" align="center">{e.totalHoursLeave} hr.</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    )
}

export default StatDetailLeaveRow