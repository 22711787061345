import { Button } from "@material-ui/core";
import SaveAltIcon from "@material-ui/icons/SaveAlt";

interface IImportButton {
  loadingImportWFH: boolean;
  insertData: any;
  disableButton: boolean;
}

function ImportButton({
  loadingImportWFH,
  insertData,
  disableButton,
}: IImportButton) {
  return disableButton ? (
    <Button type="button" color="primary" variant="contained" disabled>
      <SaveAltIcon />
      IMPORT
    </Button>
  ) : (
    <Button
      type="button"
      color="primary"
      variant="contained"
      onClick={insertData}
    >
      <SaveAltIcon />
      IMPORT
    </Button>
  );
}
export default ImportButton;
