import { useState } from "react";
import EditAnnounce from "../EditAnnounce/EditAnnounce";
import "./Announce.css";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Button } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import { Modal } from "@material-ui/core";
import { CardMedia } from "@material-ui/core";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import AlertSuccess from "../SnackBarAlert/AlertSuccess";

interface IAnnounceDetail {
  ann: any;
  baseImage: any;
  put_announce: any;
  delete_announce: any;
  no: number;
  setBaseImage: any;
  classes: any;
  success: boolean;
}

function AnnounceDetail({
  ann,
  baseImage,
  put_announce,
  delete_announce,
  classes,
  no,
  setBaseImage,
  success,
}: IAnnounceDetail) {
  const [toggleShow, setToggleShow] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [openAlertSuccess, setOpenAlertSuccess] = useState<boolean>(false);

  const deleteAnnounce = async () => {
    await delete_announce(Number(ann.announceId));
    if (success) {
      setOpenAlertSuccess(true);
    }
  };

  const displayConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  return (
    <>
      <TableRow hover key={ann.announceId}>
        <TableCell align="center">
          <>{no + 1}</>
        </TableCell>

        <TableCell align="center">
          <CardMedia
            className={classes.img}
            image={process.env.REACT_APP_API_URL + ann.img}
          />
          {/* <img src={ann.img} /> */}
        </TableCell>

        <TableCell align="center">
          <Grid wrap="nowrap" spacing={2}>
            <Typography>{ann.msg}</Typography>
          </Grid>
        </TableCell>

        <TableCell align="center">
          {ann.isTextmarquee === false ? (
            <div style={{ color: "gray" }}>INACTIVE</div>
          ) : (
            <div style={{ color: "red" }}>ACTIVE</div>
          )}
        </TableCell>

        <TableCell align="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setToggleShow((pre) => !pre)}
          >
            <EditIcon className={classes.updateButton} />
          </Button>
          {toggleShow && (
            <Modal
              open={toggleShow}
              onClose={() => {
                setToggleShow(false);
              }}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <EditAnnounce
                ann={ann}
                baseImage={baseImage}
                annId={Number(ann.announceId)}
                put_announce={put_announce}
                setBaseImage={setBaseImage}
                setToggleShow={setToggleShow}
                success={success}
              />
            </Modal>
          )}
        </TableCell>

        <TableCell align="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={displayConfirmDialog}
          >
            <DeleteForeverIcon />
          </Button>

          <ConFirmDialog
            title="ConFirm Delete Announce"
            description="Do you want to delete announce?"
            open={openConfirmDialog}
            setOpen={setOpenConfirmDialog}
            actionDialog={deleteAnnounce}
          />
          {openAlertSuccess && (
            <AlertSuccess
              msg="delete announce success"
              open={openAlertSuccess}
              setOpen={setOpenAlertSuccess}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
}
export default AnnounceDetail;
