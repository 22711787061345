import { Button, TableCell, TableRow } from "@material-ui/core";
import { useState } from "react";
import EmployeeIsActive from "../../models/EmployeeIsActive";
import EditIcon from "@material-ui/icons/Edit";
import EditAttendId from "./EditAttendId";
import AddCircleIcon from '@material-ui/icons/AddCircle';

interface EmployeeAttendanceRowProps {
    index: number
    emp: EmployeeIsActive
    editEmployeeAttendId: any
    employees: EmployeeIsActive[]
    bgColor: any
}

function EmployeeAttendanceRow({ index, emp, editEmployeeAttendId, employees, bgColor }: EmployeeAttendanceRowProps) {

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <>
            <TableRow className={bgColor}>
                <TableCell component="th" scope="row" align="center">{index}</TableCell>
                <TableCell align="center">{emp.firstName} {emp.lastName}</TableCell>
                <TableCell align="center">{emp.nickName}</TableCell>
                <TableCell align="center">
                    <Button variant="outlined" color="primary" onClick={handleOpen}>
                        {emp.attendId !== null ? <EditIcon /> : <AddCircleIcon />}
                    </Button>
                </TableCell>
            </TableRow>

            <EditAttendId open={open} setOpen={setOpen} emp={emp} editEmployeeAttendId={editEmployeeAttendId} employees={employees} />

        </>
    )
}

export default EmployeeAttendanceRow