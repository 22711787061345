import { Stack } from "@devexpress/dx-react-chart";
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  Legend,
  Title,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Grid, Paper } from "@material-ui/core";
import BarChart from "../../../models/Barchart";

interface IBarChartCustom {
  datas: BarChart[];
  title?: string;
}

function BarChartCustom({ datas, title }: IBarChartCustom) {
  return (
    <Grid justify="center" xs={12} md={12} lg={9}>
      <Paper>
        <Chart data={datas} height={350}>
          <ArgumentAxis />
          <ValueAxis />
          <BarSeries
            name="timein"
            valueField="empTimeInCount"
            argumentField="period"
          />
          <BarSeries
            name="timeout"
            valueField="empTimeOutCount"
            argumentField="period"
          />
          <Legend />
          <Title text={title} />
          <Stack />
        </Chart>
      </Paper>
    </Grid>
  );
}

export default BarChartCustom;
