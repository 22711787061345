import moment from "moment";
import {
    TableRow,
    TableCell,
} from "@material-ui/core";
import LeaveReport from "../../../models/LeaveReport";


interface ILeaveRows {
    index: number
    leave: LeaveReport
}

function LeaveRows({ index, leave }: ILeaveRows) {
    return (
        <TableRow >
            <TableCell component="th" scope="row" align="center">{index}</TableCell>
            <TableCell align="center">
                {leave.projectList.map((l, key) => {
                    if (leave.projectList.length > 1) {
                        return l.projectName + " , ";
                    }
                    return l.projectName;
                })}
            </TableCell>
            <TableCell align="center">{leave.nickName} {leave.name}</TableCell>
            <TableCell align="center">{moment(leave.dateLeave).format('DD/MM/yyy')}({leave.dateLeavePeriod})</TableCell>
            <TableCell align="center">{moment(leave.dateLeaveto).format('DD/MM/yyy')}({leave.dateLeaveToPeriod})</TableCell>
            <TableCell align="center">{leave.leaveType}</TableCell>
            <TableCell align="center">{leave.description}</TableCell>
            <TableCell align="center">{leave.note}</TableCell>
            <TableCell align="center">{leave.totalHoursLeave} hr.</TableCell>
        </TableRow>

    )
}

export default LeaveRows