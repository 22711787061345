import { Container, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Snackbar, Button, TextField } from "@material-ui/core";
import { makeStyles, Theme, createStyles, withStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import useAttendanceAPI from "../../hook/useAttendanceAPI";
import EmployeeAttendanceRow from "./EmployeeAttendanceRow";
import PersonIcon from '@material-ui/icons/Person';
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";
import EditAttendId from "./EditAttendId";
import EmployeeIsActive from "../../models/EmployeeIsActive";
import AlertWarning from "../SnackBarAlert/AlertWarning";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: theme.spacing(3)
        },
        table_container: {
            width: "100%"
        },
        title: {
            marginTop: "3%",
            marginLeft: "2%",
            marginBottom: "2%",
        },
        thead: {
            background: "#25272E",
        },
        tbody: {
            background: "white",
        },
        form: {
            paddingTop: "1%",
            paddingBottom: "1%"
        },
        rowAttNotNull: {
            backgroundColor: "white"
        },
        rowAttNull: {
            backgroundColor: "#E3E3E3"
        }
    })

);

const StyledTableCell = withStyles((theme) => ({
    head: {
        color: "white",
        textAlign: "center",
    },
}))(TableCell);

function ManageEmployee() {

    const [,
        ,
        ,
        ,
        loading,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        editEmployeeAttendID,
        employeeIsActive,
        successed,
        setSuccessed,
        ,
        ,
        ,
        ,
        ,
        getEmployeeIsActiveAdmin,
        employeesIsActiveAdmin] = useAttendanceAPI();

    const classes = useStyles()
    const [open, setOpen] = useState<boolean>(false)
    const [empId, setEmpId] = useState<number | undefined>()
    const [emp, setEmp] = useState<EmployeeIsActive>()
    const [alert, setAlert] = useState<boolean>(false)

    useEffect(() => {
        getEmployeeIsActiveAdmin()
        setOpen(false)
    }, [getEmployeeIsActiveAdmin, employeeIsActive, editEmployeeAttendID])


    useEffect(() => {
        setEmp(employeesIsActiveAdmin.find(e => e.employeeId === empId))
    }, [empId, employeesIsActiveAdmin])


    const checkNull = () => {
        if (empId !== undefined && emp !== undefined) {
            setOpen(true);
        } else {
            setAlert(true)
        }
    }

    return (
        <>
            <div className={classes.root}>
                <Grid className={classes.title} >
                    <Grid container spacing={1}>
                        <Grid item>
                            <PersonIcon fontSize="large" />
                        </Grid>
                        <Grid item xs zeroMinWidth style={{ margin: "auto" }}>
                            <Typography variant="h4" noWrap>
                                EMPLOYEES
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Container>
                    <form className={classes.form}>

                        <Autocomplete
                            id="combo-box-demo"
                            options={employeesIsActiveAdmin}
                            getOptionLabel={(option) => option.nickName}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Name"
                                    variant="outlined"
                                    required
                                    value={empId}
                                    style={{ background: "white" }}
                                />
                            )}
                            renderOption={(option) => (
                                <>
                                    {option.nickName} {option.firstName}
                                </>
                            )}

                            onChange={(e, val) => {
                                if (val !== null) {
                                    setEmpId(val.employeeId)
                                } else {
                                    setEmpId(undefined)
                                }
                            }}

                        />

                        <Button
                            fullWidth={true}
                            variant="contained"
                            type="button"
                            style={{ background: "#FAC330" }}
                            onClick={checkNull}
                        >
                            Edit Attend ID
                        </Button>

                    </form>

                    <TableContainer className={classes.table_container}>
                        <Table>
                            <TableHead>
                                <TableRow className={classes.thead}>
                                    <StyledTableCell>#</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell>Nickname</StyledTableCell>
                                    <StyledTableCell>Edit AttendID</StyledTableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody className={classes.tbody}>

                                {loading ?
                                    <>
                                        Loading ...
                                    </>
                                    :
                                    <>
                                        {employeesIsActiveAdmin.map((e, key) => {
                                            if (e.attendId !== null) {
                                                return <EmployeeAttendanceRow index={key + 1} emp={e} editEmployeeAttendId={editEmployeeAttendID} employees={employeesIsActiveAdmin} bgColor={classes.rowAttNotNull} />
                                            } else {
                                                return <EmployeeAttendanceRow index={key + 1} emp={e} editEmployeeAttendId={editEmployeeAttendID} employees={employeesIsActiveAdmin} bgColor={classes.rowAttNull} />
                                            }

                                        })}
                                    </>
                                }

                            </TableBody>
                        </Table>
                    </TableContainer>

                </Container>
            </div>

            <EditAttendId open={open} setOpen={setOpen} emp={emp} editEmployeeAttendId={editEmployeeAttendID} employees={employeesIsActiveAdmin} />
            <AlertWarning msg="Please select an employee." open={alert} setOpen={setAlert} />
            <Snackbar
                open={successed}
                autoHideDuration={5000}
                onClose={() => { setSuccessed(false) }}
            >

                <Alert onClose={() => { setSuccessed(false) }} severity="success">
                    Update Attend ID successed.
                </Alert>

            </Snackbar>
        </>
    )
}

export default ManageEmployee;