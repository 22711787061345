import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, Grid, TextField, Typography } from "@material-ui/core";
import  Autocomplete  from "@material-ui/lab/Autocomplete";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useEffect, useRef, useState } from "react";
import useAttendanceAPI from "../../hook/useAttendanceAPI";
import TeamInfo from "../../models/TeamInfo";
import moment from "moment";
import EmployeeIsActiveTeam from "../../models/EmployeeIsActiveTeam";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface IFilterBar {
  setStartDate: any;
  startDate: Date;
  setEndDate: any;
  endDate: Date;
  setTeam: any;
  team: number;
  setEmp: any;
  emp: number;
  action: any;
  getTeams: any;
  teams: TeamInfo[];
  title: String;
  show: boolean;
  setPage: any;
  icon: any;
  searchIcon: any;
  checkDate: boolean
}

function FilterBar({
  setStartDate,
  startDate,
  setEndDate,
  endDate,
  setTeam,
  team,
  setEmp,
  emp,
  action,
  getTeams,
  teams,
  title,
  show,
  setPage,
  icon,
  searchIcon,
  checkDate
}: IFilterBar) {
  const selectStartDateTime = (date: any) => {
    setStartDate(new Date(date));
  };
  const selectEndDateTime = (date: any) => {
    setEndDate(new Date(date));
  };

  const a = useRef<number>(0);

  var empIsActiveTeam: EmployeeIsActiveTeam[] = [];

  const [empTeam, setempTeam] = useState<TeamInfo[]>([]);

  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const CloseErrorDialog = () => {
    setOpenDialog(false);
  };

  const [
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    ,
    getEmployeeIsActiveTeam,
    employeesIsActiveTeam,
  ] = useAttendanceAPI();

  useEffect(() => {
    // getWorkHours(moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DDT23:59:59"), 0, 0)
    getTeams();
    getEmployeeIsActiveTeam();
  }, [getTeams, getEmployeeIsActiveTeam]);

  const onClick = () => {
    if (a.current === 0 && show === false) {
      setOpenDialog(true);
    } else {
      action();
      setPage(0);
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        xs={12}
        direction="row"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item xs={show === true ? 3 : 5}>
          <Typography variant="h4">
            {icon}
            {title}
          </Typography>
          <Box>
            {moment(startDate).format("LL")} -{moment(endDate).format("LL")}
          </Box>
        </Grid>
        <Grid item xs={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="Start Date"
                format="dd/MM/yyyy"
                value={startDate ? startDate : null}
                onChange={selectStartDateTime}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                required
                maxDate={checkDate === true ? new Date() : undefined}
                //maxDate={new Date()}
                maxDateMessage="Date should not be after maximal date"
                fullWidth={true}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Grid container justify="space-around">
              <KeyboardDatePicker
                margin="normal"
                id="date-picker-dialog"
                label="End Date"
                format="dd/MM/yyyy"
                value={endDate ? endDate : null}
                onChange={selectEndDateTime}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                required
                minDate={startDate ? startDate : null}
                maxDate={checkDate === true ? new Date() : undefined}
                //maxDate={new Date()}
                maxDateMessage="Date should not be after maximal date"
                fullWidth={true}
              />
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item xs={2}>
          <Autocomplete
            id="combo-box-demo"
            options={employeesIsActiveTeam}
            getOptionLabel={(option) => option.nickName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Employee Name"
                variant="outlined"
                value={emp}
                style={{ background: "white" }}
              />
            )}
            renderOption={(option) => (
              <>
                {option.nickName} {option.firstName}
              </>
            )}
            onChange={(e, val) => {
              let team: TeamInfo[] = [];
              team.push();
              setempTeam(team);
              if (val !== null) {
                setEmp(val.employeeId);
                a.current = val.employeeId;
                empIsActiveTeam = employeesIsActiveTeam.filter(
                  (e) => e.employeeId === val.employeeId
                );
                empIsActiveTeam.forEach((val, index: number) => {
                  val.projectList.forEach((element) => {
                    team.push({
                      projectId: element.projectId,
                      projectName: element.projectName,
                    });
                    setempTeam(team);
                  });
                });
              } else {
                setEmp(0);
                a.current = 0;
              }
            }}
          />
        </Grid>
        {show === true && (
          <Grid item xs={2}>
            <Autocomplete
              id="combo-box-demo"
              options={emp === 0 ? teams : empTeam}
              getOptionLabel={(option) => option.projectName}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Project Name"
                  variant="outlined"
                  value={team}
                  style={{ background: "white" }}
                />
              )}
              renderOption={(option) => <>{option.projectName}</>}
              onChange={(e, val) => {
                if (val !== null) {
                  setTeam(val.projectId);
                } else {
                  setTeam(0);
                }
              }}
            />
          </Grid>
        )}

        <Grid item xs={1}>
          <Button fullWidth={true} variant="contained" onClick={onClick}>
            {searchIcon}
          </Button>
        </Grid>
      </Grid>

      <Dialog
        open={openDialog}
        onClose={CloseErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Not Found</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please, Enter Employee Name !
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={CloseErrorDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default FilterBar;
