import React, { useState } from "react";

import { Button, Grid, TextField, Typography } from "@material-ui/core";

import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import AlertSuccess from "../SnackBarAlert/AlertSuccess";
import AlertWarning from "../SnackBarAlert/AlertWarning";
import EmailConfig from "../../models/EmailConfig";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


interface IFormEmail {
  config: EmailConfig;
  putEmailConfig: any;
  classes: any;
}


function FormEmail({
  config,
  putEmailConfig,
  classes,
}: IFormEmail) {
  const [senderName, setsenderName] = useState<string>(config.senderName);
  const [hrEmail, setHrEmail] = useState<string>(config.hrEmail);
  const [emailBody, setEmailBody] = useState<string>(config.emailBody);
  const [emailSubject, setemailSubject] = useState<string>(config.emailSubject);
  const [wfhLink, setwfhLink] = useState<string>(config.wfhLink);


  const [alertSuccess, setAlertSuccess] = useState<boolean>(false);
  const [alertWarning, setAlertWarning] = useState<boolean>(false);

  const submit = () => {
    if (
      senderName !== undefined &&
      hrEmail !== undefined &&
      emailBody !== undefined &&
      emailSubject !== undefined &&
      wfhLink !== undefined
    ) {
      if (
        senderName !== "" &&
        hrEmail !== "" &&
        emailBody !== "" &&
        emailSubject !== "" &&
        wfhLink !== ""
      ) {
        let edit = {
          senderName: senderName,
          hrEmail: hrEmail,
          emailSubject: emailSubject,
          wfhLink: wfhLink,
          emailBody: emailBody.replaceAll('"', "'"),
        };
        putEmailConfig(edit);
        setAlertSuccess(true);
      } else {
        setAlertWarning(true);
      }
    }
  };

  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const displayConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };


  return (
    <>
      <AlertSuccess
        msg="Success."
        open={alertSuccess}
        setOpen={setAlertSuccess}
      />
      <AlertWarning
        msg="Please enter all field."
        open={alertWarning}
        setOpen={setAlertWarning}
      />
      <div className={classes.root}>
        <Grid
          container
          spacing={2}
          xs={12}
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={10}>
            <TextField
              id="standard-full-width"
              label="Sender Name"
              style={{ margin: 8 }}
              value={senderName}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setsenderName(e.currentTarget.value);
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="standard-full-width"
              label="Hr Email"
              style={{ margin: 8 }}
              value={hrEmail}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setHrEmail(e.currentTarget.value);
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="standard-full-width"
              style={{ margin: 8 }}
              label="Email Subject"
              value={emailSubject}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setemailSubject(e.currentTarget.value);
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <ReactQuill
              theme="snow"
              value={emailBody}
              onChange={setEmailBody}
            />
            <Typography variant="caption" display="block" gutterBottom className={classes.FormHelperTextProps}>
              * Do not edit or delete [EmployeeName], [AbsenceName], [Administrator], [Work From Home Form]
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="standard-full-width"
              label="Work From Home Link"
              style={{ margin: 8 }}
              value={wfhLink}
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                setwfhLink(e.currentTarget.value);
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <Button
              fullWidth={true}
              variant="contained"
              type="button"
              style={{ background: "#FAC330" }}
              onClick={displayConfirmDialog}
            >
              submit
            </Button>
          </Grid>
        </Grid>
        <ConFirmDialog
          title="Save Email Config"
          description="Do you want to save email config ?"
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          actionDialog={submit}
        />
      </div>
    </>
  );
}
export default FormEmail;
