import { TableContainer, Table, TableHead, TableRow, TableBody, makeStyles, createStyles, withStyles, TableCell, Container, Button, CircularProgress, Theme, TextField } from "@material-ui/core";
import moment from "moment";
import { Grid } from "@material-ui/core";
import { useEffect, useState } from "react";
import EditTimeAttendance from "./EditTimeAttendance";
import Typography from "@material-ui/core/Typography";
import EditIcon from '@material-ui/icons/Edit';
import useAttendanceAPI from "../../hook/useAttendanceAPI";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import RefreshIcon from '@material-ui/icons/Refresh';
import EmployeeAbsencesTable from "./EmployeeAbsencesTable";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AddTimeAttendanceTotal from "./AddTimeAttendanceTotal";
import Attendance from "../../models/Attendance";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3)
    },
    table_contianer: {
      width: "100%",
      paddingBottom: "1%"
    },
    thead: {
      background: "#25272E",
    },
    tbody: {
      background: "white",
    },
    title: {
      marginTop: "3%",
      marginLeft: "2%",
      marginBottom: "2%",
    },
    padding: {
      paddingBottom: "10pt"
    },
    checkOutNull: {
      backgroundColor: "#E3E3E3",
    },
    checkOutNotNull: {
      backgroundColor: "white"
    }

  })
);

const StyledTableCell = withStyles((theme) => ({
  head: {
    color: "white",
    textAlign: "center",
  },

}))(TableCell);

function ManageTimeAttendance() {
  const classes = useStyles();
  const [, , getAttendances, attendances, loading, , addAttendance, , , editAttendanceAdmin, getEmployeeIsActive, employeesIsActive, , , , , getEmployeeAbsences, employeeAbsences, setAttendances] = useAttendanceAPI();

  const DEFAULT_STARTDATE = new Date()
  const DEFAULT_ENDDATE = new Date()

  const [startDate, setStartDate] = useState<Date>(DEFAULT_STARTDATE);
  const [endDate, setEndDate] = useState<Date>(DEFAULT_ENDDATE);
  const [empId, setEmpId] = useState<number>()

  const [att, setAtt] = useState<Attendance[]>(attendances)

  const selectStartDateTime = (date: any) => {
    setStartDate(new Date(date));
  };
  const selectEndDateTime = (date: any) => {
    setEndDate(new Date(date))
  };

  const searching = () => {
    getAttendances(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format("YYYY-MM-DDT23:59:59"));
    getEmployeeAbsences(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format("YYYY-MM-DD"));
  };

  const refreshData = () => {
    getAttendances(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format("YYYY-MM-DDT23:59:59"));
    getEmployeeAbsences(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format("YYYY-MM-DD"));
  }

  useEffect(() => {
    getEmployeeIsActive();
    getAttendances(moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format("YYYY-MM-DDT23:59:59"));
    getEmployeeAbsences(moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format("YYYY-MM-DD"));
  }, [getEmployeeIsActive, getAttendances, getEmployeeAbsences])

  useEffect(() => {
    const timer = setTimeout(() => {
      getAttendances(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format("YYYY-MM-DDT23:59:59"));
      getEmployeeAbsences(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format("YYYY-MM-DD"));
    }, 180000);

    return () => {
      clearInterval(timer)
    }

  }, [getAttendances, startDate, endDate, getEmployeeAbsences])

  useEffect(() => {
    if (empId !== undefined) {
      setAtt(attendances.filter(e => e.employeeId === empId))
    } else {
      setAtt(attendances)
    }
  }, [empId, setAttendances, attendances])

  return (
    <>
      <div className={classes.root} id="att-table" >
        <Grid className={classes.title}>
          <Grid container spacing={1}>
            <Grid item>
              <EditIcon fontSize="large" />
            </Grid>
            <Grid item xs zeroMinWidth style={{ margin: "auto" }}>
              <Typography variant="h4" noWrap>
                MANAGE TIME ATTENDANCE
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Container maxWidth="lg" >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid container justify="space-around" >
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="dd/MM/yyyy"
                    value={startDate ? startDate : null}
                    onChange={selectStartDateTime}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    required
                    maxDate={new Date()}
                    maxDateMessage="Date should not be after maximal date"
                    fullWidth={true}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <Grid container justify="space-around" >
                  <KeyboardDatePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="End Date"
                    format="dd/MM/yyyy"
                    value={endDate ? endDate : null}
                    onChange={selectEndDateTime}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    required
                    minDate={startDate ? startDate : null}
                    maxDate={new Date()}
                    maxDateMessage="Date should not be after maximal date"
                    fullWidth={true}
                  />
                </Grid>
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Button
                fullWidth={true}
                variant="contained"
                type="button"
                style={{ background: "#FAC330" }}
                onClick={searching}>
                Search
              </Button>
            </Grid>
            <Grid item xs={12} sm={12}>
              <AddTimeAttendanceTotal checkIn={addAttendance} refreshData={refreshData} employeesIsActive={employeesIsActive} attendances={attendances} />
            </Grid>
          </Grid>
        </Container>
        <Container maxWidth="lg">
          <Grid container justify="flex-end" spacing={2} alignItems="center" className={classes.padding}>
            <Grid item>
              <Typography>last update: {moment(new Date()).calendar()}</Typography>
            </Grid>
            <Grid item>
              {loading ? <>
                <CircularProgress />
              </> : <>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={refreshData} >
                  <RefreshIcon />
                  Refresh
                </Button>
              </>}
            </Grid>
          </Grid>
          <Grid container justify="flex-end">
            <a href="#abs-table" style={{ textDecorationLine: "none" }}><Button><ArrowDownwardIcon style={{ marginRight: "5px" }} /> Go Down</Button> </a>
          </Grid>
          <Autocomplete
            id="combo-box-demo"
            options={employeesIsActive}
            getOptionLabel={(option) => option.nickName}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Name"
                variant="outlined"
                value={empId}
                style={{ background: "white" }}
              />
            )}
            renderOption={(option) => (
              <>
                {option.nickName} {option.firstName}
              </>
            )}
            onChange={(e, val) => {
              // if (val !== null) {
              //   setEmpId(val.employeeId)

              //   if (attendances.find(e => e.employeeId === val.employeeId)) {
              //     setAttendances(attendances.filter(e => e.employeeId === val.employeeId))
              //   }

              // } else {
              //   setEmpId(undefined)
              //   getAttendances(moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'))
              // }
              if (val !== null) {
                setEmpId(val.employeeId)
              } else {
                setEmpId(undefined)
              }
            }}
          />
          <TableContainer className={classes.table_contianer}>
            <Table>
              <TableHead>
                <TableRow className={classes.thead}>
                  <StyledTableCell>#</StyledTableCell>
                  <StyledTableCell>DATE</StyledTableCell>
                  <StyledTableCell>Name</StyledTableCell>
                  <StyledTableCell>Check-in Time</StyledTableCell>
                  <StyledTableCell>Check-out Time</StyledTableCell>
                  <StyledTableCell>Note</StyledTableCell>
                  <StyledTableCell>Include Break</StyledTableCell>
                  <StyledTableCell>Summary</StyledTableCell>
                  <StyledTableCell>Edit</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tbody}>
                {loading ?
                  <>
                    Loading ...
                  </>
                  :
                  <>
                    {att.map((e, key) => {
                      if (e?.checkOut === null) {
                        return (
                          <EditTimeAttendance index={key + 1} attend={e} editAttendanceAdmin={editAttendanceAdmin} refreshData={refreshData} color={classes.checkOutNull} />
                        )
                      } else {
                        return (
                          <EditTimeAttendance index={key + 1} attend={e} editAttendanceAdmin={editAttendanceAdmin} refreshData={refreshData} color={classes.checkOutNotNull} />
                        )
                      }
                    })}
                  </>
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Grid id="abs-table" style={{ marginBottom: "10%" }}></Grid>
          <EmployeeAbsencesTable abs={employeeAbsences} loading={loading} addAtt={addAttendance} refreshData={refreshData} employees={employeesIsActive} />
        </Container>
      </div>
    </>
  );
}
export default ManageTimeAttendance;
