import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useState } from "react";
import Announce from "../../models/Announce";
import { CardMedia } from "@material-ui/core";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";

interface IEditAnnounce {
  ann: any;
  baseImage: any;
  annId: number;
  put_announce: any;
  setBaseImage: any;
  setToggleShow: any;
  success: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(4),
      textAlign: "center",
    },
    textArea: {
      padding: theme.spacing(3),
    },
    btnSelectFile: {
      display: "none",
    },
    imgAnn: {
      objectFit: "cover",
      width: "100%",
      height: "250px",
    },
  })
);

function EditAnnounce({
  ann,
  baseImage,
  put_announce,
  annId,
  setBaseImage,
  setToggleShow,
  success,
}: IEditAnnounce) {
  const classes = useStyles();
  const DEFAULT_NAME = ann.img === null ? "select image" : ann.img.split("@")[1];

  const [imgName, setImgName] = useState<string>(DEFAULT_NAME);
  const [msg, setMsg] = useState<string>(ann.msg === null ? "" : ann.msg);
  const [base64, setBase64] = useState<any>(
    process.env.REACT_APP_API_URL + ann.img
  );
  const [checked, setChecked] = useState<boolean>(ann.isTextmarquee);
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);

  const editAnnounce = async () => {
    // edit data

    let nAnn: Announce;
    nAnn = {
      announceId: annId,
      img: ann.img,
      msg: msg,
      isTextmarquee: checked,
      fileImg: baseImage.target === undefined ? null : baseImage.target.files[0]
    };

    await put_announce(nAnn);

    if (success) {
      setImgName(DEFAULT_NAME);
      setBaseImage("");
      setToggleShow(false);
      // setPreImg()
    }
  };

  const displayConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  return (
    <>
      <Container maxWidth="md">
        <Paper elevation={3} className={classes.paper}>
          <form>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <Grid container justify="flex-start" alignItems="stretch">
                  <input
                    type="file"
                    accept="image/*"
                    id="contained-button-file"
                    key={imgName}
                    onChange={(e: any) => {
                      setBaseImage(e);
                      setImgName(e.currentTarget.files[0].name);
                      //setPreImg(URL.createObjectURL(e.target.files[0]));
                      setBase64(URL.createObjectURL(e.target.files[0]));
                      //uploadImage(e);
                    }}
                    className={classes.btnSelectFile}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                    >
                      Upload
                    </Button>{" "}
                    {imgName}
                    {/* {imgName} */}
                  </label>

                  <CardMedia image={base64} className={classes.imgAnn} />
                </Grid>
              </Grid>

              <Grid item xs={6}>
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="stretch"
                >
                  <TextField
                    variant="outlined"
                    placeholder="Message"
                    multiline
                    fullWidth
                    className={classes.textArea}
                    size="medium"
                    rows="5"
                    rowsMax="5"
                    value={msg}
                    inputProps={{ maxLength: 100 }}
                    onChange={(e) => {
                      setMsg(e.target.value);
                    }}
                  ></TextField>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                        }}
                        color="primary"
                      />
                    }
                    label="Show Textmarquee"
                  />
                </Grid>

                <Box>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    onClick={displayConfirmDialog}
                  >
                    UPDATE
                  </Button>
                </Box>
                <ConFirmDialog
                  title="Confirm Edit Announce"
                  description="Do you want to edit announce"
                  open={openConfirmDialog}
                  setOpen={setOpenConfirmDialog}
                  actionDialog={editAnnounce}
                />
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </>
  );
}

export default EditAnnounce;
