import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import TeamInfo from "../../../models/TeamInfo";
import TimeAttendanceReport from "../../../models/TimeAttendanceReport";
import FilterBar from "../FilterBar";
import TimeAttendancesTable from "./TimeAttendancesTable";
import SearchIcon from '@material-ui/icons/Search';

interface ITimeAttendance {
  loading: boolean;
  getTimeAttendances: any;
  timeAtts: TimeAttendanceReport[];
  getTeams: any;
  teams: TeamInfo[];
  icon: any
}

function TimeAttendance({ loading, getTimeAttendances, timeAtts, getTeams, teams, icon }: ITimeAttendance) {

  const DEFAULT_STARTDATE_TIMEATTS = new Date();
  const DEFAULT_ENDDATE_TIMEATTS = new Date();
  const [startDateTA, setStartDateTA] = useState<Date>(
    DEFAULT_STARTDATE_TIMEATTS
  );
  const [endDateTA, setEndDateTA] = useState<Date>(DEFAULT_ENDDATE_TIMEATTS);

  const [teamTA, setTeamTA] = useState<number>(0);
  const [empTA, setEmpTA] = useState<number>(0);

  const [page, setPage] = React.useState(0);

  useEffect(() => {
    getTimeAttendances(
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DDT23:59:59"),
      0,
      0
    );
  }, [getTimeAttendances]);

  const getTimeAttendancesAction = () => {
    // function
    getTimeAttendances(
      moment(startDateTA).format("YYYY-MM-DD"),
      moment(endDateTA).format("YYYY-MM-DDT23:59:59"),
      empTA,
      teamTA
    );
  };

  return (
    <>
      <FilterBar
        getTeams={getTeams}
        teams={teams}
        setStartDate={setStartDateTA}
        startDate={startDateTA}
        setEndDate={setEndDateTA}
        endDate={endDateTA}
        setTeam={setTeamTA}
        team={teamTA}
        setEmp={setEmpTA}
        emp={empTA}
        action={getTimeAttendancesAction}
        title='TIME ATTENDANCE'
        show={true}
        setPage={setPage}
        icon={icon}
        searchIcon={<SearchIcon />}
        checkDate={true}
      />

      <TimeAttendancesTable loading={loading} timeAtts={timeAtts} teamTA={teamTA} page={page} setPage={setPage} />


    </>
  );
}

export default TimeAttendance;
