import {
  CircularProgress,
  Paper,
  TablePagination,
} from "@material-ui/core";
import moment from "moment";
import { useEffect, useState } from "react";
import LeaveReport from "../../../models/LeaveReport";
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LeaveRows from "./LeaveRows";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CardLeave from "./CardLeave";
import FilterBar from "../FilterBar";
import TeamInfo from "../../../models/TeamInfo";
import PieChartCustom from "../../Chart/PieChartCustom";
import SearchIcon from '@material-ui/icons/Search';

interface ILeave {
  loading: any;
  getLeaves: any;
  leaves: LeaveReport[];
  getTeams: any;
  teams: TeamInfo[];
  icon: any;
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    textAlign: "center",
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
    textAlign: "center",
    alignItems: "center",
  },
  total: {
    backgroundColor: "#2D2E33",
    color: "white",
  },
  private: {
    backgroundColor: "#42A5F5",
    color: "white",
  },
  sick: {
    backgroundColor: "#EC407A",
    color: "white",
  },
  maternity: {
    backgroundColor: "#26A69A",
    color: "white",
  },
  vacation: {
    backgroundColor: "#FFCA28",
    color: "white",
  },
  ordinate: {
    backgroundColor: "#9CCC65",
    color: "white",
  },
  other: {
    backgroundColor: "#FF7043",
    color: "white",
  },
});

function Leave({ loading, getLeaves, leaves, getTeams, teams, icon }: ILeave) {
  const classes = useStyles();

  const data: { types: string; value: number | undefined }[] = [];

  const [page, setPage] = React.useState(0);

  useEffect(() => {
    getLeaves(
      moment(new Date()).format("YYYY-MM-DD"),
      moment(new Date()).format("YYYY-MM-DD"),
      0,
      0
    );
  }, [getLeaves]);

  const DEFAULT_STARTDATE_WORKHOURS = new Date();
  const DEFAULT_ENDDATE_WORKHOURS = new Date();
  const [startDate, setStartDate] = useState<Date>(DEFAULT_STARTDATE_WORKHOURS);
  const [endDate, setEndDate] = useState<Date>(DEFAULT_ENDDATE_WORKHOURS);

  const [team, setTeam] = useState<number>(0);
  const [emp, setEmp] = useState<number>(0);

  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const getWorkHours = () => {
    getLeaves(
      moment(startDate).format("YYYY-MM-DD"),
      moment(endDate).format("YYYY-MM-DD"),
      emp,
      team
    );
  };

  const datePie = (dataPie?: LeaveReport[]) => {
    const leaveTypeId_private = dataPie?.filter((m) => m.leaveTypeId === 1);
    data.push({
      types: "Private",
      value: leaveTypeId_private?.reduce((e, key) => e + key.totalHoursLeave, 0),
    });
    const leaveTypeId_sick = dataPie?.filter((m) => m.leaveTypeId === 2);
    data.push({
      types: "Sick",
      value: leaveTypeId_sick?.reduce((e, key) => e + key.totalHoursLeave, 0),
    });
    const leaveTypeId_maternity = dataPie?.filter((m) => m.leaveTypeId === 3);
    data.push({
      types: "Maternity",
      value: leaveTypeId_maternity?.reduce((e, key) => e + key.totalHoursLeave, 0),
    });
    const leaveTypeId_vacation = dataPie?.filter((m) => m.leaveTypeId === 4);
    data.push({
      types: "Vacation",
      value: leaveTypeId_vacation?.reduce((e, key) => e + key.totalHoursLeave, 0),
    });
    const leaveTypeId_ordinate = dataPie?.filter((m) => m.leaveTypeId === 5);
    data.push({
      types: "Ordinate",
      value: leaveTypeId_ordinate?.reduce((e, key) => e + key.totalHoursLeave, 0),
    });
    const leaveTypeId_other = dataPie?.filter((m) => m.leaveTypeId === 6);
    data.push({
      types: "Other",
      value: leaveTypeId_other?.reduce((e, key) => e + key.totalHoursLeave, 0),
    });
  };

  return (
    <>
      <FilterBar
        {...datePie(leaves)}
        getTeams={getTeams}
        teams={teams}
        setStartDate={setStartDate}
        startDate={startDate}
        setEndDate={setEndDate}
        endDate={endDate}
        setTeam={setTeam}
        team={team}
        setEmp={setEmp}
        emp={emp}
        action={getWorkHours}
        title="LEAVE"
        show={true}
        setPage={setPage}
        icon={icon}
        searchIcon={<SearchIcon />}
        checkDate={false}
      />

      {loading ? (
        <>
          <Grid container
            justify="center"
            alignItems="center">
            <CircularProgress></CircularProgress>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            container
            spacing={2}
            direction="row"
            justify="center"
            alignItems="center"
            style={{ marginBottom: "0.5%" }}
          >
            <Grid item xs={12} md={12} lg={4}>
              <Grid container spacing={1} item direction="row">
                <Grid item xs>
                  <Paper>
                    <Grid container justify="center">
                      <PieChartCustom
                        title="Leave"
                        datas={data}
                        value="value"
                        argumentField="types"
                        height={350}
                      />
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={2}>
              <Grid item xs>
                <Grid container spacing={1} item direction="row">
                  <Grid item xs>
                    <CardLeave
                      height={350}
                      className={classes.total}
                      title="Total"
                      leave={leaves?.filter((m) => m.leaveTypeId)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container item xs={6}>
              <Grid container item direction="column">
                <Grid item xs>
                  <Grid container spacing={1} item direction="row">
                    <Grid item xs>
                      <CardLeave
                        height={175}
                        className={classes.private}
                        title="Private"
                        leave={leaves?.filter((m) => m.leaveTypeId === 1)}
                      />
                    </Grid>
                    <Grid item xs>
                      <CardLeave
                        height={175}
                        className={classes.sick}
                        title="Sick"
                        leave={leaves?.filter((m) => m.leaveTypeId === 2)}
                      />
                    </Grid>
                    <Grid item xs>
                      <CardLeave
                        height={175}
                        className={classes.maternity}
                        title="Maternity"
                        leave={leaves?.filter((m) => m.leaveTypeId === 3)}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1} item direction="row">
                    <Grid item xs>
                      <CardLeave
                        height={175}
                        className={classes.vacation}
                        title="Vacation"
                        leave={leaves?.filter((m) => m.leaveTypeId === 4)}
                      />
                    </Grid>
                    <Grid item xs>
                      <CardLeave
                        height={175}
                        className={classes.ordinate}
                        title="Ordinate"
                        leave={leaves?.filter((m) => m.leaveTypeId === 5)}
                      />
                    </Grid>
                    <Grid item xs>
                      <CardLeave
                        height={175}
                        className={classes.other}
                        title="Other"
                        leave={leaves?.filter((m) => m.leaveTypeId === 6)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          <Paper>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>#</StyledTableCell>
                    <StyledTableCell>Team</StyledTableCell>
                    <StyledTableCell>Name</StyledTableCell>
                    <StyledTableCell>DateLeave</StyledTableCell>
                    <StyledTableCell>DateLeaveto</StyledTableCell>
                    <StyledTableCell>LeaveType</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell>Note</StyledTableCell>
                    <StyledTableCell>TotalHoursLeave</StyledTableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {leaves
                    ?.map((e, key) => {
                      return <LeaveRows index={key + 1} leave={e} />;
                    })
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={leaves.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Paper>
        </>
      )}
    </>
  );
}

export default Leave;
