import React, { useEffect } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import moment from "moment";
import { TablePagination } from "@material-ui/core";
import ExportWorkHours from "../../../models/ExportWorkHours";
import workHistoryDetails from "../../../models/WorkHistoryDetails";
import StatusWorkHoursBar from "./StatusWorkHoursBar";
import WorkHistoryTeamBadge from "./WorkHistoryTeamBadge";
import DescriptionIcon from '@material-ui/icons/Description';
import WorkHistoryDetailsDialog from "./WorkHistoryDetailsDialog";

import ProjectList from "../../../models/ProjectList";

interface IWorkHoursTable {
  setPage: any;
  page: number;
  workHoursDetail: ExportWorkHours[];
  calHours: any;
  calOverTime: any;
}

interface IRow {
  wh: ExportWorkHours;
  no: number;
  sumStandardH: number;
  sumWorkH: number;
  sumLeaveH: number;
  sumTotalH: number;
  calHours: any;
  calOverTime: any;
  changePage: boolean;
  setChangePage: any;
  wfh_wfo: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3),
    },
    root1: {
      width: "93%",
      margin: "auto",
    },
    table: {
      minWidth: 650,
    },
    thead: {
      background: "#25272E",
    },
    head: {
      backgroundColor: "#25272E",
    },
    row: {
      color: "white",
    },
  })
);

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    underTime: {
      color: "red",
    },
  },
});

function Row({
  wh,
  no,
  sumStandardH,
  sumWorkH,
  sumLeaveH,
  sumTotalH,
  calHours,
  calOverTime,
  changePage,
  setChangePage,
  wfh_wfo
}: IRow) {

  const [open, setOpen] = React.useState(false);
  const [isDetailDialogOpen, setIsDetailDialogOpen] = React.useState(false);
  const [projects, setProjects] = React.useState<ProjectList[]>([]);
  const [selectedWork, setSelectedWork] = React.useState<workHistoryDetails>();
  const classes = useRowStyles();

  useEffect(() => {
    setChangePage(false)
    setOpen(false)
  }, [setChangePage, setOpen, changePage]);

  const chooseOtherProjectColor = (wh: ExportWorkHours, currentProjectId: number): any => {
    //style other project
    if (wh) {
      if (wh.projectList.some(c => c.projectId === currentProjectId && !c.isMainProject)) {
        let color = wh.projectList.find(c => c.projectId === currentProjectId)?.projectColor;
        if (color !== undefined && color !== null && color?.length > 0) {
          return color;
        }
      }
      return null;
    }
  };

  const handleHistoryItemClick = (workDetail: workHistoryDetails, wh: ExportWorkHours) => {
    setProjects(wh.projectList);
    setSelectedWork(workDetail);
    setIsDetailDialogOpen(true);
  };

  const handleCloseWorkHistoryDetailsDialog = () => {
    setIsDetailDialogOpen(false);
  }

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {no + 1}
        </TableCell>
        <TableCell align="center">
          {wh.projectList.map((pro, key) => {
            if (pro.isSelectedProject) {
              if (wh.projectList.length > 1 && wh.projectList.every(c => c.isSelectedProject) && key > 0) {
                return " , " + pro.projectName;
              }
              return pro.projectName;
            } else {
              return "";
            }
          })}
        </TableCell>
        <TableCell align="center">
          {wh.firstName} {wh.lastName}
        </TableCell>
        <TableCell align="center">{wh.nickName}</TableCell>
        <TableCell align="center">{sumStandardH.toFixed(2)}</TableCell>
        <TableCell align="center">{calHours(sumWorkH)}</TableCell>
        <TableCell align="center">{sumLeaveH.toFixed(2)}</TableCell>
        <TableCell align="center">{calHours(sumTotalH)}</TableCell>
        <TableCell align="center">{wfh_wfo}</TableCell>
        <TableCell align="center">
          <StatusWorkHoursBar
            standardWH={sumStandardH}
            leavesWH={sumLeaveH}
            totalworkWH={sumTotalH}
          />
        </TableCell>
        {calOverTime(sumStandardH, Number(calHours(sumTotalH))) < 0 ? (
          <TableCell align="center" style={{ color: "red" }}>
            {calOverTime(sumStandardH, Number(calHours(sumTotalH)))}
          </TableCell>
        ) : (
          <TableCell align="center">
            {calOverTime(sumStandardH, Number(calHours(sumTotalH)))}
          </TableCell>
        )}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <WorkHistoryTeamBadge projectList={wh.projectList} />
              {wh.workHistory.length !== 0 ? (
                <Table size="small" aria-label="purchases">
                  <TableHead style={{ backgroundColor: "#E3E3E3" }}>
                    <TableRow>
                      <TableCell align="center">Date</TableCell>
                      <TableCell align="center">CheckIn</TableCell>
                      <TableCell align="center">CheckOut</TableCell>
                      <TableCell align="center">Note</TableCell>
                      <TableCell align="center">Standard Hours</TableCell>
                      <TableCell align="center">Work Hours</TableCell>
                      <TableCell align="center">Leave Hours</TableCell>
                      <TableCell align="center">Leave Detail</TableCell>
                      <TableCell align="center">Total Work Hours</TableCell>
                      <TableCell align="center">OverTime Hours</TableCell>
                      <TableCell align="center"></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ backgroundColor: "#F5F5F5" }}>
                    {wh.workHistory.map((h) => {
                      var color = chooseOtherProjectColor(wh, h.projectId);

                      return (
                        <TableRow key={h.date} style={{ backgroundColor: (color ? `#${color}` : "unset") }}>
                          <TableCell component="th" scope="row" align="center">
                            {moment(h.date).format("DD/MM/YYYY")}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {h.checkIn !== null
                              ? moment(h.checkIn).format("HH:mm")
                              : "--:--"}
                          </TableCell>
                          <TableCell align="center">
                            {h.checkOut !== null
                              ? moment(h.checkOut).format("HH:mm")
                              : "--:--"}
                          </TableCell>
                          <TableCell align="center">{h.note}</TableCell>
                          <TableCell align="center">
                            {Number(h.standardHours).toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            {Number(h.workHours).toFixed(2)}
                          </TableCell>
                          <TableCell align="center">
                            {Number(h.leaveHours).toFixed(2)}
                          </TableCell>
                          <TableCell align="center">{h.leaveDetail}</TableCell>
                          <TableCell align="center">
                            {Number(h.totalHours).toFixed(2)}
                          </TableCell>
                          {Number(h.statusHours.toFixed(2)) < 0 ? (
                            <TableCell align="center" style={{ color: "red" }}>
                              {Number(h.statusHours).toFixed(2)}
                            </TableCell>
                          ) : (
                            <TableCell align="center">
                              {Number(h.statusHours).toFixed(2)}
                            </TableCell>
                          )}
                          <TableCell align="center">
                            <IconButton aria-label="task details" onClick={() => handleHistoryItemClick(h, wh)}>
                              <DescriptionIcon/>
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              ) : (
                <Typography style={{ backgroundColor: "#F5F5F5" }}>
                  NO DATA
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <WorkHistoryDetailsDialog 
          open={isDetailDialogOpen} 
          projects={projects}
          selectedWork={selectedWork}
          handleCloseWorkHistoryDetailsDialog={handleCloseWorkHistoryDetailsDialog}/>
    </React.Fragment>
  );
}

function WorkHoursTable({
  setPage,
  page,
  workHoursDetail,
  calHours,
  calOverTime,
}: IWorkHoursTable) {
  const classes = useStyles();
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [changePage, setChangePage] = React.useState(false);
  const [totalWorkDay] = React.useState(workHoursDetail !== null && workHoursDetail !== undefined && workHoursDetail.length > 0 && workHoursDetail[0].totalWorkday !== undefined ? "(" + workHoursDetail[0].totalWorkday.toString() + ")" : "(1)");
  
  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    setChangePage(true)
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      <Paper>
        <TableContainer>
          <Table aria-label="collapsible table">
            <colgroup>
              <col style={{ width: "1%" }} />
              <col style={{ width: "1%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "8%" }} />
              <col style={{ width: "8%" }} />
              <col style={{ width: "8%" }} />
              <col style={{ width: "8%" }} />
              <col style={{ width: "5%" }} />
              <col style={{ width: "28%" }} />
              <col style={{ width: "8%" }} />
            </colgroup>
            <TableHead>
              <TableRow className={classes.head}>
                <TableCell />
                <TableCell className={classes.row} align="center">
                  #
                </TableCell>
                <TableCell className={classes.row} align="center">
                  Team
                </TableCell>
                <TableCell className={classes.row} align="center">
                  Name
                </TableCell>
                <TableCell className={classes.row} align="center">
                  NickName
                </TableCell>
                <TableCell className={classes.row} align="center">
                  Standard Hours
                </TableCell>
                <TableCell className={classes.row} align="center">
                  Work Hours
                </TableCell>
                <TableCell className={classes.row} align="center">
                  Leave Hours
                </TableCell>
                <TableCell className={classes.row} align="center">
                  Total Work Hours
                </TableCell>
                <TableCell className={classes.row} align="center">
                  WFH/WFO {totalWorkDay}
                </TableCell>
                <TableCell className={classes.row} align="center">
                  Status
                </TableCell>
                <TableCell className={classes.row} align="center">
                  OverTime Hours
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {workHoursDetail
                .map((wh, key) => {
                  var sumStandardH = wh.workHistory.reduce(
                    (w, k) => w + k.standardHours,
                    0
                  );
                  // var sumWorkH = wh.workHistory.reduce(
                  //   (w, k) => w + k.workHours,
                  //   0
                  // );
                  var sumWorkH = wh.workHours
                  // var sumLeaveH = wh.workHistory.reduce(
                  //   (w, k) => w + k.leaveHours,
                  //   0
                  // );
                  var sumLeaveH = wh.leaveHours
                  // var sumTotalH = wh.workHistory.reduce(
                  //   (w, k) => w + k.totalHours,
                  //   0
                  // );
                  var sumTotalH = wh.totalWorks

                  return (
                    <Row
                      wh={wh}
                      no={key}
                      sumStandardH={sumStandardH}
                      sumWorkH={sumWorkH}
                      sumLeaveH={sumLeaveH}
                      sumTotalH={sumTotalH}
                      calHours={calHours}
                      calOverTime={calOverTime}
                      changePage={changePage}
                      setChangePage={setChangePage}
                      wfh_wfo={wh.wfH_WFO}
                    />
                  );
                })
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
            </TableBody>
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={workHoursDetail.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </>
  );
}
export default WorkHoursTable;
