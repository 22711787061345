import { Button } from "@material-ui/core";

interface IChooseButton {
  disableButton: boolean;
}

function ChooseButton({ disableButton }: IChooseButton) {
  return disableButton ? (
    <Button variant="contained" color="inherit" component="span" disabled>
      Choose
    </Button>
  ) : (
    <Button variant="contained" color="inherit" component="span" >
      Choose
    </Button>
  );
}
export default ChooseButton;
