import { Backdrop, Container, createStyles, makeStyles, Modal, Theme } from "@material-ui/core"
import { Button, Typography } from "@material-ui/core"
import { useState } from "react"
import Attendance from "../../models/Attendance";
import EmployeeIsActive from "../../models/EmployeeIsActive"
import EmployeeLeave from "../../models/EmployeeLeave";
import StatDetailTable from "./StatDetailTable";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: '2px solid #000',
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
            width: '50vw',
            height: '80vh',
            overflow: 'auto'
        },
    }),
);

interface StatButtonProps {
    title: string
    count?: number
    className: any
    employees?: EmployeeIsActive[]
    attendances: Attendance[]
    leaves?: EmployeeLeave[]
}

function StatButton({ title, count = 0, className, employees = [], attendances, leaves = [] }: StatButtonProps) {

    const classes = useStyles()
    const [showTable, setShowTable] = useState<boolean>(false)

    const handleClose = () => {
        setShowTable(false);
    };

    return (
        <>
            <Button fullWidth={true} variant="contained" className={className} onClick={() => { setShowTable(true) }}>
                <Typography align="center" >
                    <h2>{title}</h2>
                    <h1>{count}</h1>
                </Typography>
            </Button>
            <Modal
                open={showTable}
                onClose={handleClose}
                className={classes.modal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Container className={classes.paper}>
                    <Typography align="left" >
                        <h1>{title}</h1>
                    </Typography>
                    <StatDetailTable employees={employees} attendances={attendances} leaves={leaves} />
                </Container>
            </Modal>
        </>
    )
}

export default StatButton