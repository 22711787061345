import { TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import TimeAttendanceReport from "../../../models/TimeAttendanceReport";

interface ITimeAttendancesRow {
  timeAtt: TimeAttendanceReport;
  no: number;
}

function TimeAttendancesRow({ timeAtt, no }: ITimeAttendancesRow) {
  return (
    <>
      <TableRow hover key={timeAtt.employeeId}>
        <TableCell align="center">
          <>{no + 1}</>
        </TableCell>

        <TableCell align="center">
          {moment(timeAtt.checkIn).format("DD/MM/YYYY")}
        </TableCell>

        <TableCell align="center">
          {timeAtt.projectList.map((pro, key) => {
            if (timeAtt.projectList.length > 1) {
              return pro.projectName + " , ";
            }
            return pro.projectName;
          })}
        </TableCell>

        <TableCell align="center">
          {timeAtt.nickName} {timeAtt.firstName}
        </TableCell>

        <TableCell align="center">
          {moment(timeAtt.checkIn).format("HH:mm")}
        </TableCell>

        <TableCell align="center">
          {timeAtt.checkOut === null && <>--</>}
          {timeAtt.checkOut !== null &&
            moment(timeAtt.checkOut).format("HH:mm")}
        </TableCell>

        <TableCell align="center">{timeAtt.note}</TableCell>

        <TableCell align="center">{Number(timeAtt.hours).toFixed(2)}</TableCell>
      </TableRow>
    </>
  );
}
export default TimeAttendancesRow;
