import { createStyles, makeStyles, TableCell, TableRow, Typography } from "@material-ui/core"
import EmployeeIsActive from "../../models/EmployeeIsActive"

const useStyles = makeStyles(() =>
    createStyles({
        attended: {
            color: '#e8a800',
        },
        leave: {
            color: '#d16256',
        }
    })
);

interface StatDetailRowProps {
    index: number
    employee: EmployeeIsActive
    className: string
    status: string
}

function StatDetailRow({ index, employee, className, status }: StatDetailRowProps) {
    const classes = useStyles()
    return (
        <TableRow className={className}>
            <TableCell component="th" scope="row" align="center">{index}</TableCell>
            <TableCell align="center">{employee.firstName} {employee.lastName}</TableCell>
            <TableCell align="center">{employee.nickName}</TableCell>
            <TableCell align="center">
                {status === "ATTENDED" &&
                    <Typography className={classes.attended} variant="subtitle1">{status}</Typography >
                }
                {status === "LEAVE" &&
                    <Typography className={classes.leave} variant="subtitle1">{status}</Typography >
                }
                {status === "NOT ATTEND" &&
                    <Typography variant="subtitle1">{status}</Typography >
                }
            </TableCell>
        </TableRow>
    )
}

export default StatDetailRow