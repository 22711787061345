import { Grid } from "@material-ui/core";
import { createStyles, makeStyles } from "@material-ui/styles";
import moment from "moment";
import Attendance from "../../models/Attendance";
import EmployeeIsActive from "../../models/EmployeeIsActive";
import EmployeeLeave from "../../models/EmployeeLeave";
import WeeklyEmployee from "../../models/WeeklyEmployee";
import StatButton from "../Stat/StatButton";

const useStyles = makeStyles(() =>
    createStyles({
        all: {
            padding: '30px'
        },
        total: {
            backgroundColor: '#2D2E33',
            color: 'white'
        },
        clock_in: {
            backgroundColor: '#FAC330',
            color: 'white'
        },
        clock_out: {
            backgroundColor: '#6EC1B2',
            color: 'white'
        },
        leave: {
            backgroundColor: '#F77C6F',
            color: 'white'
        }
    })
)

interface DashBoardDailyProps {
    weeklyEmployees: WeeklyEmployee[]
    employees?: EmployeeIsActive[]
    attendances: Attendance[]
    leaves?: EmployeeLeave[]
}

function DashBoardDaily({ weeklyEmployees, employees = [], attendances, leaves = [] }: DashBoardDailyProps) {

    const classes = useStyles();

    return (

        <Grid container direction="row" justify="space-around" alignItems="center" className={classes.all}>
            <Grid item xs={2} >
                <StatButton title="Total"
                    count={weeklyEmployees.find(e => e.date === moment(new Date()).format('YYYY-MM-DDT00:00:00'))?.countEmployee}
                    className={classes.total}
                    employees={employees}
                    attendances={attendances}
                    leaves={leaves} />
            </Grid>
            <Grid item xs={2} >
                <StatButton title="Clock In"
                    count={weeklyEmployees.find(e => e.date === moment(new Date()).format('YYYY-MM-DDT00:00:00'))?.countClockIn}
                    className={classes.clock_in}
                    employees={employees.filter(e => attendances.find(m => m.employeeId === e.employeeId))}
                    attendances={attendances} />
            </Grid>
            <Grid item xs={2} >
                <StatButton title="Clock Out"
                    count={weeklyEmployees.find(e => e.date === moment(new Date()).format('YYYY-MM-DDT00:00:00'))?.countClockOut}
                    className={classes.clock_out}
                    employees={employees.filter(e => attendances.find(m => m.employeeId === e.employeeId && m.checkOut !== null))}
                    attendances={attendances} />
            </Grid>
            <Grid item xs={2}>
                <StatButton title="Leave"
                    count={weeklyEmployees.find(e => e.date === moment(new Date()).format('YYYY-MM-DDT00:00:00'))?.countLeave}
                    className={classes.leave}
                    employees={employees.filter(e => leaves.find(m => m.nickName === e.nickName))}
                    attendances={attendances}
                    leaves={leaves} />
            </Grid>
        </Grid>
    )
}

export default DashBoardDaily