import { createStyles, makeStyles, Theme } from "@material-ui/core";

interface IStatusWorkHoursBar {
  standardWH: number;
  leavesWH: number;
  totalworkWH: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    standard: {
      width: "100%",
      height: "30px",
      backgroundColor: "#e2e2e2",
      color: "white",
      textAlign: "center",
      position: "relative",
    },
    totalwork: {
      width: "0%",
      height: "100%",
      backgroundColor: "#6EC1B2",
      float: "left",
    },
    leaves: {
      width: "0%",
      height: "100%",
      backgroundColor: "#FFAB00",
      float: "left",
      position: "absolute",
    },
  })
);

function StatusWorkHoursBar({
  standardWH,
  leavesWH,
  totalworkWH,
}: IStatusWorkHoursBar) {
  const classes = useStyles();

  var calLeaves = (leavesWH * 100) / standardWH;
  var calTotalwork = (totalworkWH * 100) / standardWH;


  if (calTotalwork >= 100) {
    calTotalwork = 100;
  }


  return (
    <>
      <div className={classes.standard} title={"Missing " + (totalworkWH - standardWH).toFixed(2) + "Hr."}>
        <div
          title={"WorkTime " + (totalworkWH - leavesWH).toFixed(2) + "Hr."}
          className={classes.totalwork}
          style={{ width: calTotalwork + "%" }}
        >

        </div>
        <div title={"Leave " + leavesWH + "Hr."} className={classes.leaves} style={{ width: calLeaves + "%" }}>

        </div>
      </div>
    </>
  );
}
export default StatusWorkHoursBar;
