import React, { useEffect, useRef, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Button, Grid, TextField } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AlertWarning from "../SnackBarAlert/AlertWarning";
import ConFirmDialog from "../ConFirmDialog/ConFirmDialog";
import useAttendanceAPI from "../../hook/useAttendanceAPI";
import useReportAPI from "../../hook/useReportAPI";
import moment from "moment";
import AlertSuccessAddWFH from "./AlertSuccessAddWFH";
import AttendanceWFH from "../../models/AttendanceWFH";
import TeamInfo from "../../models/TeamInfo";
import EmployeeIsActiveTeam from "../../models/EmployeeIsActiveTeam";
import AlertWarningAddWFH from "./AlertWarningAttendanceWFH";
import Autocomplete from "@material-ui/lab/Autocomplete";

interface IAddTimeAttendanceWFH {
    setWfhSuccess: any;
    wfhSuccess: boolean;
    insertEmpWFH: any;
    refreshData: any;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modal: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        },
        paper: {
            backgroundColor: theme.palette.background.paper,
            border: "2px solid #000",
            boxShadow: theme.shadows[5],
            padding: theme.spacing(2, 4, 3),
        },
        container: {
            display: "flex",
            flexWrap: "wrap",
        },
        textField: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            width: 300,
        },
    })
);

function AddTimeAttendanceWFH({
    setWfhSuccess,
    wfhSuccess,
    insertEmpWFH,
    refreshData
}: IAddTimeAttendanceWFH) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const [
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        getEmployeeIsActive,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        ,
        getEmployeeIsActiveTeam,
        employeesIsActiveTeam,
        ,
        ,
    ] = useAttendanceAPI();

    const [, , , , getTeams, teams, , , , , , , , , , , ,] = useReportAPI();

    const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
    const [warningTime, setWarningTime] = useState<boolean>(false);
    const [warningFillTimeIn, setWarningFillTimeIn] = useState<boolean>(false);
    const [duplicate, setDuplicate] = useState<boolean>(false);

    let dateDefault = moment(new Date()).format("YYYY-MM-DDTHH:mm:ss");
    const [timeIn, setTimeIn] = useState<any>(dateDefault);
    const [timeOut, setTimeOut] = useState<any>(dateDefault);
    const [empEmail, setEmpEmail] = useState<string>("");
    const [jobTask, setJobTask] = useState<string>("");
    const [project, setProject] = useState<string>("");
    const [hours, setHours] = useState<any>(null);
    const [hoursText, setHoursText] = useState<string>("");

    const [empTeam, setempTeam] = useState<TeamInfo[]>([]);
    const a = useRef<string>("");
    var empIsActiveTeam: EmployeeIsActiveTeam[] = [];

    const [alertW, setAlertW] = useState<boolean>(false);

    const displayConfirmDialog = () => {
        setOpenConfirmDialog(true);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setTimeIn(dateDefault);
        setTimeOut(dateDefault);
        setEmpEmail("");
        setJobTask("");
        setProject("");
        setHours(null);
        setHoursText("");
        setDuplicate(false);
    };

    const addAttendanceWFH = async () => {
        let formObj: AttendanceWFH = {
            projectName: project,
            checkIn: timeIn,
            checkOut: timeOut,
            email: empEmail,
            hours: parseFloat(hours),
            hoursText: hoursText,
            inOutDetail: jobTask,
        };

        if (empEmail !== "" && project !== "") {
            var response = await insertEmpWFH(formObj);
            if (response && response.status === 200) {
                setAlertW(true);
                refreshData();
            }
            else if (response && response.status === 409) {
                setDuplicate(true);
            }
        }
        else {
            setAlertW(true);
        }
    };

    useEffect(() => {
        getEmployeeIsActive();
        getTeams();
        getEmployeeIsActiveTeam();
    }, [getEmployeeIsActive, getTeams, getEmployeeIsActiveTeam]);

    return (
        <>
            <Grid item xs={12} sm={12}>
                <Button
                    fullWidth={true}
                    variant="contained"
                    type="button"
                    onClick={handleOpen}
                >
                    NEW WORK FROM HOME
                </Button>
            </Grid>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Add Time Attendance WFH</h2>
                        <Grid item xs={12} sm={12}>
                            <Grid>
                                <Autocomplete
                                    id="combo-box-demo"
                                    options={employeesIsActiveTeam}
                                    getOptionLabel={(option) => option.nickName}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Name"
                                            variant="outlined"
                                            value={empEmail}
                                            style={{ background: "white" }}
                                        />
                                    )}
                                    renderOption={(option) => (
                                        <>
                                            {option.nickName} {option.firstName}
                                        </>
                                    )}
                                    onChange={(e, val) => {
                                        let team: TeamInfo[] = [];
                                        team.push();
                                        setempTeam(team);
                                        if (val !== null) {
                                            setEmpEmail(val.email);
                                            a.current = val.email;
                                            empIsActiveTeam = employeesIsActiveTeam.filter(
                                                (e) => e.employeeId === val.employeeId
                                            );
                                            empIsActiveTeam.forEach((val, index: number) => {
                                                val.projectList.forEach((element) => {
                                                    team.push({
                                                        projectId: element.projectId,
                                                        projectName: element.projectName,
                                                    });
                                                    setempTeam(team);
                                                });
                                            });
                                        } else {
                                            setEmpEmail("");
                                            a.current = "";
                                        }
                                    }}
                                // onChange={(e, val) => {
                                //   if (val !== null) {
                                //     setEmpEmail(val.email);
                                //   }
                                // }}
                                />
                            </Grid>
                            <Grid style={{ marginTop: "7px" }}>
                                <Autocomplete
                                    id="combo-box-demo"
                                    // options={teams}
                                    options={empEmail === "" ? teams : empTeam}
                                    getOptionLabel={(option) => option.projectName}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Project Name"
                                            variant="outlined"
                                            value={empEmail}
                                            style={{ background: "white" }}
                                        />
                                    )}
                                    renderOption={(option) => <>{option.projectName}</>}
                                    onChange={(e, val) => {
                                        if (val !== null) {
                                            setProject(val.projectName);
                                        } else {
                                            setEmpEmail("");
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <form className={classes.container} noValidate>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <Grid
                                    container
                                    justify="space-around"
                                    style={{ background: "white" }}
                                >
                                    <TextField
                                        margin="normal"
                                        id="datetime-local"
                                        label="Time In"
                                        type="datetime-local"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        defaultValue={dateDefault}
                                        value={timeIn}
                                        onChange={(e) => {
                                            setTimeIn(e.target.value);
                                        }}
                                    />

                                    <TextField
                                        margin="normal"
                                        id="datetime-local"
                                        label="Time Out"
                                        type="datetime-local"
                                        className={classes.textField}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={timeOut}
                                        onChange={(e) => {
                                            setTimeOut(e.target.value);
                                        }}
                                    />

                                    <TextField
                                        margin="normal"
                                        id="standard-number"
                                        label="Total hours"
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={hours}
                                        onChange={(e) => {
                                            setHours(e.target.value);
                                        }}
                                    />
                                </Grid>
                            </MuiPickersUtilsProvider>

                            <Grid item xs={12}>
                                <TextField
                                    fullWidth={true}
                                    label="Hours text"
                                    variant="filled"
                                    style={{ background: "white" }}
                                    type="text"
                                    value={hoursText}
                                    onChange={(e) => {
                                        setHoursText(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                style={{ marginTop: "7px", marginBottom: "7px" }}
                            >
                                <TextField
                                    fullWidth={true}
                                    label="Job task / description"
                                    variant="filled"
                                    style={{ background: "white" }}
                                    multiline
                                    rows={10}
                                    type="text"
                                    value={jobTask}
                                    inputProps={{ maxLength: 800 }}
                                    onChange={(e) => {
                                        setJobTask(e.target.value);
                                    }}
                                />
                            </Grid>

                            <Button
                                fullWidth={true}
                                variant="contained"
                                type="button"
                                style={{ background: "#FAC330" }}
                                onClick={displayConfirmDialog}
                            >
                                Submit
                            </Button>

                            <AlertWarning
                                msg="check in time should be more than check out time !"
                                open={warningTime}
                                setOpen={setWarningTime}
                            />

                            <AlertWarning
                                msg="fill check in time!"
                                open={warningFillTimeIn}
                                setOpen={setWarningFillTimeIn}
                            />

                            <ConFirmDialog
                                title="Save TimeAttendance"
                                description="Do you want to save WFH timeattendance ?"
                                open={openConfirmDialog}
                                setOpen={setOpenConfirmDialog}
                                actionDialog={addAttendanceWFH}
                            />
                        </form>

                        {wfhSuccess && (
                            <>
                                <AlertSuccessAddWFH
                                    msg="Import WFH file completed."
                                    open={wfhSuccess}
                                    dateDefault={dateDefault}
                                    setOpen={setWfhSuccess}
                                    setTimeIn={setTimeIn}
                                    setTimeOut={setTimeOut}
                                    setEmpEmail={setEmpEmail}
                                    setJobTask={setJobTask}
                                    setProject={setProject}
                                    setHours={setHours}
                                    setHoursText={setHoursText}
                                    handleClose={handleClose}
                                />
                            </>
                        )}

                        {alertW && (
                            <AlertWarningAddWFH
                                msg="Please select employee name and project name."
                                open={alertW}
                                setOpen={setAlertW}
                            />
                        )}

                        <AlertWarning
                            msg="Duplicate!"
                            open={duplicate}
                            setOpen={setDuplicate}
                        />
                    </div>
                </Fade>
            </Modal>
        </>
    );
}
export default AddTimeAttendanceWFH;
